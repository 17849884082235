import React from "react";
import ProfileMenu from "./profile-menu";
import "./style.css";
import { Icon } from "@iconify/react";
import { loggedInData } from "../../data/fakeLoginData";
function Header({ active, setActive }) {
  const authenticatedUser = JSON?.parse(
    localStorage.getItem("realestateauthenticatedUser")
  );
  return (
    <header>
      <div className="div_header" style={{margin:"auto"}}>
        {/* <div className="list-toggle">
          <img src="/OROUBA_Logo_2016-removebg-preview 1.png" alt="orouba logo"/>
        </div> */}
      </div>


      <ProfileMenu active={active} setActive={setActive} userData={authenticatedUser} />
    </header>
  );
}

export default Header;
