import ContentNav from "../../../datanavcontent";
import DefaultLayout from "../../../layout/defaultlayout";
import AddModal from '../../../components/AddModal/AddModal';
import EditModal from '../../../components/EditModal/EditModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Table from "../../../components/table";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../data";
import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";

export default function CookingProps() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const {cookId} = useParams();
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [img , setImg] = useState(null);
    const [isModalDeleteOpen , setIsModalDeleteOpen] = useState(false);
    const [delItem , setDelItem] = useState(null);
    const [allCookingsProps , setAllCookingsProps] = useState([]);
    const [cookingPropsData , setCookingPropsData] = useState({
        title_en :"",
        title_ar:"",
        text_ar:"",
        text_en:"",
    });
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"Icon",
            dataIndex:"icon",
            type:"children",
            children : ({row}) => {
                return (
                    <img src={row.icon} alt={row.title_en}/>
                )
            }
        },
        {
            label:"English Title",
            dataIndex:"title_en",
        },
        {
            label:"English Text",
            dataIndex:"text_en",
        },
        {
            label: "",
            type: "children",
            children: ({ row, headers }) => {
              return (
                <div className="icons-container">
                  <button className="edit_btn"  onClick={() => {
                      setIsModalEditOpen(true);
                      setRowData(row);
                    }}>Edit</button>

                 <button className="delete_btn" onClick={() => {
                    setIsModalDeleteOpen(true);
                    setDelItem(row.id)
                 }}>Delete</button>
                </div>
              );
            },
          },
    ]

    function handleGetAllCookingsProps() {
        setIsLoading(true);
        axios.get(BASE_URL + `/cooks/get_cook_props/${cookId}?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
             setAllCookingsProps(res.data.result);
            }else if(res.data.status == "faild") {
              toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما")
            }
        }).finally(() => { 
          setIsLoading(false);
        }).catch(e => console.log(e))
    } 

    useEffect(() => {
        handleGetAllCookingsProps();
    },[])

    function handleSubmit(e) {
        e.preventDefault();
    
        if(img == null) {
            toast.warn("ادخل الصوره أولا");
            return ;
        }
        const formData = new FormData()
        formData.append("image",img);
        setIsLoading(true);
      
        axios.post(BASE_URL + `/file_upload`,formData)
        .then(res => {
            if(res.data.status == "success") {
               const data_send = {
                props : `${cookingPropsData.title_ar}**oroba**${cookingPropsData.title_en}**oroba**${cookingPropsData.text_ar}**oroba**${cookingPropsData.text_en}**oroba**${res.data.result.image}`
               }
               setIsLoading(true);
               axios.post(BASE_URL + `/cooks/add_cook_props/${cookId}?token=${token}`,data_send)
               .then(res => {
                if(res.data.status == "success") {
                    toast.success(res.data.message);
                    handleGetAllCookingsProps();
                    setImg(null);
                    setCookingPropsData({
                        title_ar:"",
                        title_en:"",
                        text_ar:"",
                        text_en:"",
                    })
                }else if(res.data.status == "faild") {
                    toast.error(res.data.message);
                }else {
                    toast.error("حدث خطأ ما");
                }
               })
               .finally(() => {
                setIsLoading(false);
                setIsModalAddOpen(false);
               }).catch(e => console.log(e))
               
            }else if(res.data.status == "faild") {
               toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        })
        .finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }
    
    function handleEdit(e) {
        e.preventDefault();
        if(img == null) {
            const data_send = {
                props : `${rowData.title_ar}**oroba**${rowData.title_en}**oroba**${rowData.text_ar}**oroba**${rowData.text_en}**oroba**${rowData.icon}`,
            }
            setIsLoading(true);
            axios.post(BASE_URL + `/cooks/update_cook_props/${rowData.id}?token=${token}` , data_send)
            .then(res => {
               if(res.data.status == "success") {
                toast.success(res.data.message);
                handleGetAllCookingsProps();
               }else if(res.data.status == "faild") {
                 toast.error(res.data.message);
               }else {
                toast.error("حدث خطأ ما");
               }
            }).finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
            }).catch(e => console.log(e))
        }else {
            const formData = new FormData();
            formData.append("image" , img);
            setIsLoading(true);
            axios.post(BASE_URL + '/file_upload' , formData)
            .then(res => {
                if(res.data.status == "success") {
                   const data_send = {
                    props :`${rowData.title_ar}**oroba**${rowData.title_en}**oroba**${rowData.text_ar}**oroba**${rowData.text_en}**oroba**${res.data.result.image}`,
                   }
                   setIsLoading(true);
                   axios.post(BASE_URL + `/cooks/update_cook_props/${rowData.id}?token=${token}`, data_send)
                   .then(res => {
                     if(res.data.status == "success") {
                        toast.success(res.data.message);
                        handleGetAllCookingsProps();
                     }else if(res.data.status == "faild") {
                        toast.error(res.data.message)
                     }else {
                        toast.error("حدث خطأ ما");
                     }
                   })
                   .finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                   }).catch(e => console.log(e))
                }else if(res.data.status == "faild") {
                   toast.error(res.data.message);
                }else {
                    toast.error("")
                }
            })
            .finally(() => {
                setIsLoading(false);
            }).catch(e => console.log(e))
        }
    }

    function handleDelete() {
        setIsLoading(true);
      axios.get(BASE_URL + `/cooks/del_cook_prop/${delItem}?token=${token}`)
      .then(res => {
          if(res.data.status == "success") {
             toast.success(res.data.message);
             handleGetAllCookingsProps();
          }else if(res.data.status =="faild") {
             toast.error(res.data.message);
          }else {
             toast.error("حدث خطأ ما")
          }
      }).finally(() => {
        setIsLoading(false);
        setIsModalDeleteOpen(false);
      }).catch(e => console.log(e))
      
    }

  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Cooking Props"/>
                <AddModal onOk={handleSubmit} title="Add Cooking Props" isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen} >
                    <form className="modal_form" onSubmit={handleSubmit}>
                       <div className="inputs_container">
                          <div className="input_group">
                            <label>English Title</label>
                            <input type="text" placeholder="English Title" value={cookingPropsData.title_en} onChange={(e) =>setCookingPropsData({...cookingPropsData , title_en : e.target.value})}/>
                          </div>

                          <div className="input_group">
                            <label>Arabic Title</label>
                            <input type="text" placeholder="Arabic Title" value={cookingPropsData.title_ar} onChange={(e) =>setCookingPropsData({...cookingPropsData , title_ar : e.target.value})}/>
                          </div>
                       </div>

                       <div className="inputs_container">
                          <div className="input_group">
                            <label>English Text</label>
                            <input type="text" placeholder="English Text" value={cookingPropsData.text_en} onChange={(e) =>setCookingPropsData({...cookingPropsData , text_en : e.target.value})}/>
                          </div>

                          <div className="input_group">
                            <label>Arabic Text</label>
                            <input type="text" placeholder="Arabic Text" value={cookingPropsData.text_ar} onChange={(e) =>setCookingPropsData({...cookingPropsData , text_ar : e.target.value})}/>
                          </div>
                       </div>

                       <div className="input_group">
                        <label>Image</label>
                        <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                       </div>
                    </form>
                </AddModal>
            </div>

            <EditModal onOk={handleEdit} title="Edit Cooking Props" isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
            <form className="modal_form" onSubmit={handleEdit}>
                       <div className="inputs_container">
                          <div className="input_group">
                            <label>English Title</label>
                            <input type="text" placeholder="English Title" value={rowData.title_en} onChange={(e) =>setRowData({...rowData , title_en : e.target.value})}/>
                          </div>

                          <div className="input_group">
                            <label>Arabic Title</label>
                            <input type="text" placeholder="Arabic Title" value={rowData.title_ar} onChange={(e) =>setRowData({...rowData , title_ar : e.target.value})}/>
                          </div>
                       </div>

                       <div className="inputs_container">
                          <div className="input_group">
                            <label>English Text</label>
                            <input type="text" placeholder="English Text" value={rowData.text_en} onChange={(e) =>setRowData({...rowData , text_en : e.target.value})}/>
                          </div>

                          <div className="input_group">
                            <label>Arabic Text</label>
                            <input type="text" placeholder="Arabic Text" value={rowData.text_ar} onChange={(e) =>setRowData({...rowData , text_ar : e.target.value})}/>
                          </div>
                       </div>

                       <div className="input_group">
                        <label>Image</label>
                        <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                       </div>
                    </form>
            </EditModal>
            <DeleteModal isModalOpen={isModalDeleteOpen} setIsModalOpen={setIsModalDeleteOpen} onOk={handleDelete}/>
            <Table headers={headers} body={allCookingsProps}/>
        </div>
    </DefaultLayout>
  )
}
