import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Continent() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const navigate = useNavigate();
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
    const [allContinent , setAllContinent] = useState([]);
    const [continentData , setContinentData] = useState({
        name_ar:"",
        name_en:"",
    })
    const [rowData , setRowData] = useState({})
    const headers =[
        {
            label:"#",
            dataIndex:"id",
        },
        {
          label:"Arabic Name",
          dataIndex:"name_ar",
          type:"children",
          children : ({row}) => {
            return (
              <p style={{cursor:"pointer"}}>{row.name_ar}</p>
            )
          }
      },
        {
            label:"English Name",
            dataIndex:"name_en",
            type:"children",
            children : ({row}) => {
              return (
                <p style={{cursor:"pointer"}}>{row.name_en}</p>
              )
            }
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button className="edit_btn" onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}>Edit</button>
            {row.hidden == 0 ? (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(false);
              setStatus(row.id);
            }}>Hide</button>
            ) : (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(true);
              setStatus(row.id);
            }}>Show</button>
            )}
            <button className="edit_btn" onClick={() => {
                  navigate(`/continent/${row.id}`)
                }}>Countries</button>
          </div> 
        );
      },
        }
    ]

    function handleGetAllContents() {
       setIsLoading(true);
       axios.get(BASE_URL + `/continents/get_for_admin?token=${token}`)
       .then(res => {
        if(res.data.status == "success") {
           setAllContinent(res.data.result);
        }else if(res.data.status == "faild") {
            toast.error(res.data.message);
        }else {
            toast.error("حدث خطأ ما")
        }
       }).finally(() => {  
          setIsLoading(false);
       }).catch(e => console.log(e))
    }
    
    useEffect(() => {
      handleGetAllContents();
    } ,[])

    function handleSubmit(e) {
        e.preventDefault();
        const data_send = {
          ...continentData,
        }
        setIsLoading(true);
        axios.post(BASE_URL+ `/continents/add_new?token=${token}`,  data_send)
        .then(res => {
          if(res.data.status == "success") {
             toast.success(res.data.message);
             handleGetAllContents();
             setContinentData({
              name_ar:"",
              name_en:"",
             })
          }else if(res.data.status == "faild") {
             toast.error(res.data.message);
          }else {
            toast.error("حدث خطأ ما")
          }
        }).finally(() => {
          setIsLoading(false);
          setIsModalAddOpen(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        const data_send = {
          ...rowData,
        }
        axios.post(BASE_URL + `/continents/update_one/${rowData.id}?token=${token}`,data_send)
        .then(res => {
          if(res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllContents();
          }else if(res.data.status =="faild") {
            toast.error(res.data.message);
          }else {
            toast.error("حدث خطأ ما")
          }
        }).finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        }).catch(e => console.log(e))
    }

    function handleUpdateStatus() {
      setIsLoading(true);
        axios
          .get(BASE_URL + `/continents/update_status/${status}?token=${token}`)
          .then((res) => {
            console.log(res);
            if (res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllContents();
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          }).finally(() => {
            setIsLoading(false);
            setIsShowStatus(false);
          })
          .catch((e) => console.log(e));
      }
  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Continent"/>
                <AddModal onOk={handleSubmit} title="Add Continent" isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen}>
                    <form onSubmit={handleSubmit} className="modal_form">
                           <div className="input_group">
                              <label>English Name</label>
                              <input type="text" placeholder="English Name" value={continentData.name_en} onChange={(e) => setContinentData({...continentData , name_en : e.target.value})}/>
                           </div>
                          
                           <div className="input_group">
                              <label>Arabic Name</label>
                              <input type="text" placeholder="Arabic Name" value={continentData?.name_ar} onChange={(e) => setContinentData({...continentData , name_ar : e.target.value})}/>
                           </div>
                           
                    </form>
                </AddModal>
            </div>
            
            <EditModal onOk={handleEdit} title="Edit Continent" isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
            <form onSubmit={handleEdit} className="modal_form">
                
                           <div className="input_group">
                              <label>English Name</label>
                              <input type="text" placeholder="English Name" value={rowData.name_en} onChange={(e) => setRowData({...rowData , name_en : e.target.value})}/>
                           </div>

                           <div className="input_group">
                              <label>Arabic Name</label>
                              <input type="text" placeholder="Arabic Name" value={rowData.name_ar} onChange={(e) => setRowData({...rowData , name_ar : e.target.value})}/>
                           </div>
                    </form>
            </EditModal>

            <ShowStatusModal isHidden={showStatus} isLoading={isLoading} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus} onOk={handleUpdateStatus}/>
            <Table headers={headers} body={allContinent} />
        </div>
    </DefaultLayout>
  )
}
