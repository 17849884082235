import { useState } from "react";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import Table from "../../components/table";
import { toast } from "react-toastify";
import { useEffect } from "react";


export default function Values() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [isLoading , setIsLoading] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
    const [allValues, setAllVAlues] = useState([]);
    const [img , setImg] = useState(null);
    const [valueData , setValueData]  = useState({
        image:"",
         description_ar:"",
         description_en:"",
         title_ar:"",
         title_en:"",
    });
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label:"#",
            dataIndex:"id"
        },
        {
            label:"Image",
            dataIndex:"image",
            type:"children",
            children : ({row}) => {
                return (
                    <img src={row.image} alt={row.title_en}/>
                )
            }
        },
        {
            label:"English Title",
            dataIndex:"title_en",
        },
        {
            label:"Arabic Title",
            dataIndex:"title_ar",
        },
        {
            label:"English Description",
            dataIndex:"description_en",
        },
        {
            label:"Arabic Description",
            dataIndex:"description_ar",
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
            <div className="icons-container">
                 <button className="edit_btn" onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}>Edit</button>
            {row.hidden == 0 ? (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(false);
              setStatus(row.id);
            }}>Hide</button>
            ) : (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(true);
              setStatus(row.id);
            }}>Show</button>
            )}

          </div>
        );
      },
        }
    ]

    function handleGetAllValues() {
       setIsLoading(true);
       axios.get(BASE_URL + `/values/get_for_admin?token=${token}`)
       .then(res => {
        if(res.data.status == "success") {
            console.log(res.data.result);
            setAllVAlues(res.data.result);
        }else if(res.data.status == "faild") {
            toast.error(res.data.message);
        }else{
            toast.error("حدث خطأ ما");
        }
       }).finally(() => {
        setIsLoading(false);
       }).catch(e => console.log(e))
}

useEffect(() => {
    handleGetAllValues();
} , [])

    function handleSubmit(e) {
        e.preventDefault();
        if(img == null) {
            toast.warn("Enter Image First!");
            return;
        }
        const formData = new FormData();
        formData.append("image" , img);
        setIsLoading(true);

        axios.post(BASE_URL + "/file_upload" , formData)
        .then(res => {
            if(res.data.status == "success") {
                const data_send = {
                    ...valueData,
                    image :res.data.result.image,
                    description_ar : valueData.description_ar,
                    title_ar:  valueData.title_ar
                }
               axios.post(BASE_URL + `/values/add_new?token=${token}` , data_send)
               .then(res => {
                if(res.data.status == "success") {
                   toast.success(res.data.message);
                   handleGetAllValues();
                   setImg(null);
                   setValueData({
                    image:"",
                    title_ar:"",
                    title_en:"",
                    description_ar:"",
                    description_en:"",
                   })
                }else if(res.data.status == "faild") {
                   toast.error(res.data.message);
                }else {
                    toast.error("An error occured!")
                }
               }).finally(() => {
                  setIsLoading(false);
                  setIsModalAddOpen(false);
               }).catch(e => console.log(e))
            }else if(res.data.status == "faild"){
                toast.error(res.data.message);
            }else {
                toast.error("An error occurred while uploading the image!")
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        if(img == null) {
            const data_send = {
                ...rowData ,
                image : rowData.image,
                description_ar : rowData.description_ar ,
                title_ar : rowData.title_ar
            }
            setIsLoading(true);
            axios.post(BASE_URL +  `/values/update_one/${rowData.id}?token=${token}` , data_send)
            .then(res => {
                if(res.data.status == "success") {
                    toast.success(res.data.message);
                    handleGetAllValues();
                }else if(res.data.status == "faild") {
                    toast.error(res.data.message);
                }else {
                    toast.error("حدث خطأ ما");
                }
            }).finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
            }).catch(e => console.log(e))
        }else {
             const formData = new FormData();
             formData.append("image", img);
             setIsLoading(true);
             axios.post(BASE_URL + '/file_upload' , formData)
             .then(res => {
                if(res.data.status == "success") {
                  const data_send = {
                    ...rowData ,
                    description_ar : rowData.description_ar ,
                    title_ar : rowData.title_ar,
                    image : res.data.result.image,
                  }
                  axios.post(BASE_URL + `/values/update_one/${rowData.id}?token=${token}`, data_send)
                  .then(res => {
                    if(res.data.status == "success") {
                       toast.success(res.data.message);
                       handleGetAllValues();
                    }else if(res.data.status == "faild") {
                        toast.error(res.data.message);
                    }else {
                        toast.error("حدث خطأ ما")
                    }
                  }).finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  }).catch(e => console.log(e))
                }else if(res.data.status == "faild") {
                   toast.error(res.data.message);
                }else {
                    toast.error("حدث خطا ما أثناء تعديل الصور")
                }
             }).finally(() => {
                setIsLoading(false);
             }).catch(e => console.log(e))
        }
    }

    function handleUpdateStatus(){
        setIsLoading(true);
        axios
        .get(BASE_URL + `/values/update_status/${status}?token=${token}`)
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllValues();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("An error occured");
          }
        }).finally(() => {
            setIsLoading(false);
            setIsShowStatus(false);
        })
        .catch((e) => console.log(e));
    }

  return (
    <DefaultLayout>
        <div style={{margin : "20px"}}>
            <div className="page_title_container">
                <ContentNav head="Values"/>
                <AddModal title="Add Value" onOk={handleSubmit} isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen}>
                    <form className="modal_form" onSubmit={handleSubmit}>

                            <div className="input_group">
                                <label>English Title</label>
                                <input type="text" placeholder="English Title" value={valueData.title_en} onChange={(e) => setValueData({...valueData , title_en : e.target.value})}/>
                            </div>

                            <div className="input_group">
                                <label>Arabic Title</label>
                                <input type="text" placeholder="Arabic Title" value={valueData.title_ar} onChange={(e) => setValueData({...valueData , title_ar : e.target.value})}/>
                            </div>

                            <div className="input_group">
                                <label>English Description</label>
                                <input type="text" placeholder="English Description" value={valueData.description_en} onChange={(e) => setValueData({...valueData , description_en : e.target.value})}/>
                            </div>

                            <div className="input_group">
                                <label>Arabic Description</label>
                                <input type="text" placeholder="English Description" value={valueData.description_ar} onChange={(e) => setValueData({...valueData , description_ar : e.target.value})}/>
                            </div>


                         <div className="input_group">
                            <label>Image</label>
                            <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                         </div>
                    </form>
                </AddModal>
            </div>
            <EditModal title="Edit Value" onOk={handleEdit} isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
                    <form className="modal_form" onSubmit={handleEdit}>
                            <div className="input_group">
                                <label>English Title</label>
                                <input type="text" placeholder="English Title" value={rowData.title_en} onChange={(e) => setRowData({...rowData , title_en : e.target.value})}/>
                            </div>
                            <div className="input_group">
                                <label>Arabic Title</label>
                                <input type="text" placeholder="Arabic Title" value={rowData.title_ar} onChange={(e) => setRowData({...rowData , title_ar : e.target.value})}/>
                            </div>


                            <div className="input_group">
                                <label>English Description</label>
                                <input type="text" placeholder="English Description" value={rowData.description_en} onChange={(e) => setRowData({...rowData , description_en : e.target.value})}/>
                            </div>

                            <div className="input_group">
                                <label>Arabic Description</label>
                                <input type="text" placeholder="Arabic Description" value={rowData.description_ar} onChange={(e) => setRowData({...rowData , description_ar : e.target.value})}/>
                            </div>


                         <div className="input_group">
                            <label>Image</label>
                            <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                         </div>
                    </form>
                </EditModal>
                <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
            <Table headers={headers} body={allValues}/>
        </div>
    </DefaultLayout>
  )
}
