import { useNavigate, useParams } from "react-router-dom";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Table from "../../components/table";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function Recipes() {
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const { recipe } = useParams();
  const navigate = useNavigate();
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
  const [allRecipes, setAllRecipes] = useState([]);
  const [imgUrl , setImgUrl] = useState("");
  const [img , setImg] = useState(null);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [recipeData, setRecipeData] = useState({
    image: "",
    name_ar: "",
    name_en:"",
    number:1,

  });
  const [rowData, setRowData] = useState({});
  console.log(recipe);

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "Recipe Image",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return (
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/recipes/${row.id}`);
            }}
            src={row.image}
            alt={row.title}
          />
        );
      },
    },
    {
      label: "Arabic Title",
      dataIndex: "name_ar",
    },
    {
       label:"English Title",
       dataIndex:"name_en",
    },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            {row.hidden == 0 ? (
              <FaRegEye
                style={{ color: "green" }}
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(false);
                  setStatus(row.id);
                }}
              />
            ) : (
              <FaRegEyeSlash
                style={{ color: "green" }}
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(true);
                  setStatus(row.id);
                }}
              />
            )}
            <FaRegEdit
              style={{ color: "blue" }}
              onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}
            />
            <button className="btn btn-primary" onClick={()=>navigate("/Recipes_Categories/"+row?.id)}>Sub Categories</button>

          </div>
        );
      },
    },
  ];

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send={
      number:video_number
    }
    await axios.post(BASE_URL+`/recipes/change_number/${video_id}`,data_send)
    .then((res)=>{
      console.log(res)
      if(res.data.status=='success'){
        toast.success(res.data.message)
        handleGetAllRecipes();
      }
      else if(res.data.status=='faild'){
        toast.error(res.data.message)
      }
      else {
        toast.error("something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setLoader(false);
    })
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };
  function handleGetAllRecipes() {
    setIsLoading(true);
    axios.get(BASE_URL + `/recipes/get_for_admin?token=${token}`)
    .then(res => {
      if(res.data.status == "success") {
        console.log(res.data.result);
        setAllRecipes(res.data.result);
      }else if(res.data.status == "faild") {
        toast.error(res.data.message);
      }else {
        toast.error("حدث خطأ ما");
      }
    }).finally(() => {
      setIsLoading(false);
    }).catch(e => {
      console.log(e);
    })
  }

   useEffect(() => {
    handleGetAllRecipes();
   } , [])

  function handleSubmit(e) {
    e.preventDefault();
    if(img == null) {
      toast.warn("ارفع الصوره أولا");
      return ;
    }
    const formData = new FormData();
    formData.append("image", img);
    setIsLoading(true);
    axios.post(BASE_URL + `/file_upload`, formData)
    .then(res => {
      if(res.data.status == "success") {
          const data_send = {
            ...recipeData ,
            image : res.data.result.image,
          }
          axios.post(BASE_URL + `/recipes/add_new?token=${token}` , data_send)
          .then(res => {
            if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllRecipes();
              setRecipeData({
                image:"",
                name_ar:"",
                number:1,

                name_en:"",
              })
            }else if(res.data.status == "faild") {
              toast.error(res.data.message);
            }else {
              toast.error("حدث خطأ ما");
            }
          }).finally(() => {
            setIsLoading(false);
            setIsModalAddOpen(false);
          }).catch(e =>  console.log(e))
      }else if(res.data.status =="faild") {
         toast.error(res.data.message);
      }else {
        toast.error("حدث خطأ ما أثناء رفع الصوره");
      }
    }).finally(() => {
      setIsLoading(false);
    }).catch(e => console.log(e))
  }

  function handleEdit(e) {
    e.preventDefault();
    if(img == null) {
      const data_send ={
        ...rowData ,
        image : rowData.image
      }
      setIsLoading(true);
      axios.post(BASE_URL+ `/recipes/update_one/${rowData.id}?token=${token}` , data_send)
      .then(res =>  {
        if(res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllRecipes();
        }else if(res.data.status == "faild") {
          toast.error(res.data.message);
        }else  {
          toast.error("حدث خطأ ما")
        }
      }).finally(() => {
        setIsLoading(false);
        setIsModalEditOpen(false);
      }).catch(e => console.log(e))
    }else {
      const formData = new FormData();
      formData.append("image",img);
      setIsLoading(true);
      axios.post(BASE_URL + `/file_upload` , formData)
      .then(res => {
        if(res.data.status == "success") {
            const data_send = {
              ...rowData ,
              image : res.data.result.image
            }
            axios.post(BASE_URL + `/recipes/update_one/${rowData.id}?token=${token}` , data_send)
            .then(res => {
              if(res.data.status == "success") {
                toast.success(res.data.message);
                handleGetAllRecipes();
              }else if(res.data.status == "faild") {
                toast.error(res.data.message);
              }else {
                toast.error("حدث خطأ ما")
              }
            }).finally(() => {
              setIsLoading(false);
              setIsModalEditOpen(false);
            }).catch(e => console.log(e))
        }else if(res.data.status == "faild") {
          toast.error(res.data.message);
        }else {
          toast.error("حدث خطأ ما أثناء تعديل الصوره")
        }
      }).finally(() => {
        setIsLoading(false);
      }).catch(e => console.log(e))
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/recipes/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllRecipes();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      }).finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Recipes" />
          <AddModal
            onOk={handleSubmit}
            title="Add Recipe"
            isLoading={isLoading}
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="inputs_container">
              <div className="input_group">
                <label>English Recipe Title</label>
                <input
                  type="text"
                  value={recipeData.name_en}
                  placeholder="english Recipe Title"
                  onChange={(e) =>
                    setRecipeData({ ...recipeData, name_en : e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Arabic Recipe Title</label>
                <input
                  type="text"
                  placeholder="Arabic Recipe Title"
                  value={recipeData.name_ar}
                  onChange={(e) =>
                    setRecipeData({ ...recipeData, name_ar: e.target.value })
                  }
                />
              </div>
              </div>
              <div className="input_group">
                <label>Number</label>
                <input
                  type="text"
                  placeholder="Number"
                  value={recipeData.number}
                  onChange={(e) =>
                    setRecipeData({ ...recipeData, number: e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Recipe Image</label>
                <input
                  type="file"
                  accept="images/*"
                  placeholder="Recipe Image"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>
            </form>
          </AddModal>
        </div>

        <EditModal
          onOk={handleEdit}
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          title="Recipe Edit"
        >
           <form onSubmit={handleEdit} className="modal_form">
              <div className="inputs_container">
              <div className="input_group">
                <label>English Recipe Title</label>
                <input
                  type="text"
                  placeholder="english Recipe Title"
                  value={rowData?.name_en}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_en : e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Arabic Recipe Title</label>
                <input
                  type="text"
                  placeholder="Arabic Recipe Title"
                  value={rowData?.name_ar}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_ar: e.target.value })
                  }
                />
              </div>
              </div>

              <div className="input_group">
                <label>Recipe Image</label>
                <input
                  type="file"
                  accept="images/*"
                  placeholder="Recipe Image"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>
            </form>
        </EditModal>
        <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
        <Table headers={headers} body={allRecipes} />
      </div>
    </DefaultLayout>
  );
}
