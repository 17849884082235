import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";


export default function SectionTexts() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen,setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
    const [img , setImg] = useState(null);
    const [allFeatures , setAllFeatures] = useState([]);
    const [featureData , setFeatureData] = useState({
        text_ar:"",
        text_en:"",
        title_ar:"",
        title_en:"",
        image:"",
        number:''
    });
    const [rowData , setRowData ] = useState({})
    const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
          label: "Number",
          dataIndex: "number",
          search: true,
          sort: true,
          type: "children",
          children: ({ headers, row }) => {
            const handleBlur = async (product_id, product_number) => {
              set_product_id(null);
              setArrangeNumber(product_id, product_number);
            };

            const handleFocus = () => {
              setCurrentNumber(row?.number);
              set_product_id(row?.id);
            };

            const handleClick = () => {
              set_product_id(row?.id);
            };

            return (
              <div
                style={{ width: "fit-content", cursor: "pointer" }}
                onClick={handleClick}
              >
                <input
                  style={{ width: "120px", cursor: "pointer" }}
                  type="text"
                  onChange={(e) =>
                    setProductData({ id: row?.id, number: e.target.value })
                  }
                  onFocus={() => handleFocus()}
                  defaultValue={
                    productData?.id == row?.id ? productData?.number : row?.number
                  }
                  value={
                    productData?.id == row?.id ? productData?.number : row?.number
                  }
                  disabled={loader}
                  className={
                    product_id === row?.id
                      ? "MR_input_form_element active"
                      : "MR_input_form_element lazy"
                  }
                  onBlur={async (e) => {
                    await handleBlur(row?.id, e.target.value);
                  }}
                />
              </div>
            );
          },
        },
        {
            label:"Arabic Title",
            dataIndex:"title_ar",
        },
        {
            label:"English Title",
            dataIndex:"title_en",

        },
        {
          label: "Arabic Text",
          dataIndex: "text_ar",
          type:'children',
          children:({row,headers})=>{
            return(
              <>
                <div dangerouslySetInnerHTML={{ __html:row?.text_ar }}></div>
              </>
            )
          }
        },
        {
          label: "English Text",
          dataIndex: "text_en",
          type:'children',
          children:({row,headers})=>{
            return(
              <>
                <div dangerouslySetInnerHTML={{ __html:row?.text_en }}></div>
              </>
            )
          }
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button className="edit_btn" onClick={() => {
              setIsModalEditOpen(true);
              setRowData(row);
            }}>Edit</button>
          {row.hidden == 0 ? (
           <button className="show_btn" onClick={() => {
            console.log(row.hidden);
            setIsShowStatus(true);
            setShowStatus(false);
            setStatus(row.id);
          }}>Hide</button>
          ) : (
           <button className="show_btn" onClick={() => {
            console.log(row.hidden);
            setIsShowStatus(true);
            setShowStatus(true);
            setStatus(row.id);
          }}>Show</button>
          )}
        </div>
        );
      },
        }
    ]

    const setArrangeNumber = async (video_id, video_number) => {
      console.log(video_number);
      console.log(video_id);
      // return
      setLoader(true);
      if (video_number == currentNumber) {
        return setLoader(false);
      }
      const data_send={
        number:video_number
      }
      await axios.post(BASE_URL+`/section_texts/change_number/${video_id}`,data_send)
      .then((res)=>{
        console.log(res)
        if(res.data.status=='success'){
          toast.success(res.data.message)
          handleGetAllFeatures();
        }
        else if(res.data.status=='faild'){
          toast.error(res.data.message)
        }
        else {
          toast.error("something Went Error")
        }
      })
      .catch(e=>console.log(e))
      .finally(()=>{
        setLoader(false);
      })
      // await Axios({
      //   method: "POST",
      //   url: "banners/change_number/" + video_id,
      //   data: { number: video_number },
      // })
      //   .then(async (response) => {
      //     console.log(response);
      //     toast.success(response?.message);
      //     handleGetAllBanners();
      //   })
      //   .catch((error) => console.log(error))
      //   .finally(() => {
      //     setLoader(false);
      //   });
    };

    function handleGetAllFeatures() {
        setIsLoading(true);
        axios.get(BASE_URL + `/section_texts/get_for_admin?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
              setAllFeatures(res.data.result);
            }else if(res.data.status =="faild") {
              toast.error(res.data.message);
            }else {
             toast.error("حدث خطأ ما")
            }
        }).finally(() => {
           setIsLoading(false)
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllFeatures();
    } , [])

    function handleSubmit(e) {
        e.preventDefault();

        const data_send = {
          ...featureData,
        }
        axios.post(BASE_URL + `/section_texts/add_new?token=${token}` , data_send)
        .then(res => {
          if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllFeatures();
              setFeatureData({
                  text_ar:"",
                  text_en:"",
                  title_ar:"",
                  title_en:"",
                  image:"",
              })
          }else if(res.data.status =="faild") {
              toast.error(res.data.message);
          }else {
              toast.error("حدث خطأ ما")
          }
        }).finally(() => {
          setIsLoading(false);
          setIsModalAddOpen(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        const data_send = {
          ...rowData ,
      }
      axios.post(BASE_URL + `/section_texts/update_one/${rowData?.id}?token=${token}` , data_send)
      .then(res => {
          if(res.data.status == "success") {
             toast.success(res.data.message);
             handleGetAllFeatures();
          }else if(res.data.status == "faild") {
             toast.error(res.data.message)
          }else {
             toast.error("حدث خطأ ما")
          }
      }).finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
      }).catch(e => console.log(e))
    }

    function handleUpdateStatus() {
      setIsLoading(true);
      axios
        .get(BASE_URL + `/section_texts/update_status/${status}?token=${token}`)
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllFeatures();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        }).finally(() => {
          setIsLoading(false);
          setIsShowStatus(false);
        })
        .catch((e) => console.log(e));
    }

  return (
    <DefaultLayout>
        <div style={{margin :"20px"}}>
            <div className="page_title_container">
                <ContentNav  head="Section Text"/>
                <AddModal onOk={handleSubmit} title="Add Section Text" isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen}>
                    <form onSubmit={handleSubmit} className="modal_form">
                        <div className="input_group">
                            <label>English Title</label>
                            <input type="text" placeholder="English Title" value={featureData.title_en} onChange={(e) => setFeatureData({...featureData , title_en : e.target.value})}/>
                        </div>
                        <div className="input_group">
                            <label>Arabic Title</label>
                            <input type="text" placeholder="Arabic Title" value={featureData.title_ar} onChange={(e) => setFeatureData({...featureData , title_ar : e.target.value})}/>
                        </div>

                        <div className="input_group">
                            <label>English Text</label>
                            <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={featureData?.text_en}
                  onChange={(e) => {
                   return setFeatureData({ ...featureData, text_en: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
                            {/* <input type="text" placeholder="English Text" value={featureData.text_en} onChange={(e) => setFeatureData({...featureData , text_en : e.target.value})}/> */}
                        </div>

                        <div className="input_group">
                            <label>Arabic Text</label>
                            <ReactQuill
                              theme="snow"
                              modules={{
                                toolbar: [
                                  ["bold", "italic", "underline", "strike"],
                                  ["blockquote", "code-block"],
                                  [{ header: 1 }, { header: 2 }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  [{ script: "sub" }, { script: "super" }],
                                  [{ indent: "-1" }, { indent: "+1" }],
                                  [{ direction: "rtl" }],
                                  [{ size: ["small", true, "large", "huge"] }],
                                  ["link", "image"],
                                  [{ color: [] }, { background: [] }],
                                  [{ font: [] }],
                                  [{ align: [] }],
                                ],
                              }}
                              value={featureData?.text_ar}
                              onChange={(e) => {
                                return setFeatureData({ ...featureData, text_ar: e });
                              }}
                              style={{
                                minHeight: "100px",
                                color: "black",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                            />
                            {/* <input type="text" placeholder="English Text" value={featureData.description_ar} onChange={(e) => setFeatureData({...featureData , text_ar : e.target.value})}/> */}
                        </div>

                        <div className="input_group">
                            <label>Number</label>
                            <input type="text" placeholder="Number" value={featureData.number} onChange={(e) => setFeatureData({...featureData , number : e.target.value})}/>
                        </div>
                    </form>
                </AddModal>
            </div>
            <EditModal onOk={handleEdit} title="Edit Section Text" isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
              <form style={{marginBottom:'10px'}} onSubmit={handleEdit} className="modal_form">
                         <div className="input_group">
                            <label>English Title</label>
                            <input type="text" placeholder="English Title" value={rowData.title_en} onChange={(e) => setRowData({...rowData , title_en : e.target.value})}/>
                        </div>
                         <div className="input_group">
                            <label>Arabic Title</label>

                            <input type="text" placeholder="Arabic Title" value={rowData.title_ar} onChange={(e) => setRowData({...rowData , title_ar : e.target.value})}/>
                        </div>

                         <div className="input_group">
                            <label>English Text</label>
                            <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.text_en}
                  onChange={(e) => {
                    return setRowData({ ...rowData, text_en: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
                            {/* <input type="text" placeholder="English Text" value={rowData.text_en} onChange={(e) => setRowData({...rowData , text_en : e.target.value})}/> */}
                        </div>
                         <div className="input_group">
                            <label>Arabic Text</label>
                            <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.text_ar}
                  onChange={(e) => {
                    return setRowData({ ...rowData, text_ar: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
                            {/* <input type="text" placeholder="English Text" value={rowData.text_ar} onChange={(e) => setRowData({...rowData , text_ar : e.target.value})}/> */}
                        </div>


                    </form>
            </EditModal>
            <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
            <Table headers={headers} body={allFeatures}/>
        </div>
    </DefaultLayout>
  )
}
