import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Table from "../../components/table";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function Buildings() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
  const [isShowStatus , setIsShowStatus] = useState(false);
  const [status , setStatus] = useState(null)
    const [buildings , setBuildings] = useState([]);
    const [img , setImg] = useState(null);
    const [buildingData , setBuildingData] = useState({
        description_ar:"",
        description_en:"",
        image:"",
        title_ar:"",
        title_en:""
    })
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"image",
            dataIndex:"image",
            type:"children",
            children:({row}) => {
             return (
                <img src={row.image} alt={row.title_en}/>
             )
            }
        },
        {
            label:"English Title",
            dataIndex:"title_en",
        },
        {
            label:"Arabic Title",
            dataIndex:"title_ar",
        },
        {
            label:"English Description",
            dataIndex:"description_en",
        },
        {
            label:"Arabic Description",
            dataIndex:"description_ar",
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
          {row.hidden == 0 ? (
           <button className="show_btn" onClick={() => {
            setIsShowStatus(true);
            setShowStatus(false);
            setStatus(row.id);
          }}>Hide</button>
          ) : (
           <button className="show_btn" onClick={() => {
            setIsShowStatus(true);
            setShowStatus(true);
            setStatus(row.id);
          }}>Show</button>
          )}
          <button className="edit_btn" onClick={() => {
              setIsModalEditOpen(true);
              setRowData(row);
            }}>Edit</button>
        </div>
        );
      },
        }
    ]

    function handleGetAllBuildings() {
      setIsLoading(true);
      axios.get(BASE_URL + `/buildings/get_for_admin?token=${token}`)
      .then(res => {
        if(res.data.status == "success") {
          setBuildings(res.data.result);
        }else if(res.data.status == "faild") {
         toast.error(res.data.message);
        }else {
        toast.error("حدث خطأ ما")
        }
      }).finally(() => {
        setIsLoading(false);
      }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllBuildings()
    } , [])

    function handleSubmit(e) {
        e.preventDefault();
        if(img == null) {
            toast.warn("ادخل الصوره أولا");
            return;
        }
        const formData = new FormData();
        formData.append("image",img)
        setIsLoading(true);

        axios.post(BASE_URL + `/file_upload`, formData)
        .then(res => {
            if(res.data.status == "success") {
               const data_send = {
                ...buildingData,
                image : res.data.result.image,
                description_ar : buildingData.description_ar,
                title_ar : buildingData.title_ar,
               }
               axios.post(BASE_URL + `/buildings/add_new?token=${token}` , data_send)
               .then(res => {
                if(res.data.status == " succeess") {
                   toast.success(res.data.message);
                   handleGetAllBuildings();
                   setImg(null);
                   setBuildingData({
                    description_ar:"",
                    description_en:"",
                    title_ar:"",
                    title_en:"",
                    image:"",
                   })
                }
                else if(res.data.status == "faild") {
                   toast.error(res.data.message);
                }else {
                  toast.error("حدث خطأ ما");
                }
               }).finally(() => {
                setIsLoading(false);
                setIsModalAddOpen(false);
               }).catch(e => console.log(e))
            }else if(res.data.status == "faild"){
               toast.error(res.data.message);
            }else {
              toast.error("حدث خطأ ما أثناء رفع الصوره")
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        if(img == null) {
            const data_send = {
                ...rowData ,
                image:  rowData.image,
                title_ar : rowData.title_ar,
                description_ar : rowData.description_ar
            }
            console.log(data_send);
            setIsLoading(true);
            axios.post(BASE_URL + `/buildings/update_one/${rowData.id}?token=${token}` , data_send)
            .then(res => {
                if(res.data.status == "success") {
                     toast.success(res.data.message);
                     handleGetAllBuildings();
                }else if(res.data.status == "faild") {
                     toast.error(res.data.message);
                }else {
                    toast.error("حدث خطأ ما")
                }
            }).finally(()=> {
                setIsLoading(false);
                setIsModalEditOpen(false);
            }).catch(e => console.log(e))
        }else {
            const formData = new FormData();
            formData.append("image", img)
            setIsLoading(true);
            axios.post(BASE_URL +"/file_upload" , formData)
            .then(res => {
                if(res.data.status == "success") {
                  const data_send = {
                    ...rowData ,
                    image : res.data.result.image,
                    title_ar : rowData.title_ar ,
                    description_ar : rowData.description_ar
                  }
                  axios.post(BASE_URL + `/buildings/update_one/${rowData.id}?token=${token}` , data_send)
                  .then(res => {
                    if(res.data.status == "success") {
                       toast.success(res.data.message);
                       handleGetAllBuildings();
                    }else if(res.data.status == "faild") {
                        toast.error(res.data.message);
                    }else {
                        toast.error("حدث خطأ ما")
                    }
                  }).finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  }).catch(e => console.log(e))
                }else if(res.data.status == "faild") {
                 toast.error(res.data.message);
                }else {
                 toast.error("حدث خطأ ما")
                }
            }).finally(() => {
                setIsLoading(false);
            }).catch(e => console.log(e))
        }
    }

    function handleUpdateStatus() {
      setIsLoading(true);
        axios
          .get(BASE_URL + `/buildings/update_status/${status}?token=${token}`)
          .then((res) => {
            console.log(res);
            if (res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllBuildings();
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          }).finally(() => {
            setIsLoading(false);
            setIsShowStatus(false);
          })
          .catch((e) => console.log(e));
      }
    return (
        <DefaultLayout>
            <div style={{margin:"20px"}}>
                <div className="page_title_container">
                    <ContentNav  head="Buildings"/>
                    <AddModal onOk={handleSubmit} title="Add Building" isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen} >
                        <form onSubmit={handleSubmit} className="modal_form">

                                <div className="input_group">
                                    <label>English Title</label>
                                    <input type="text" placeholder="English Title" value={buildingData.title_en} onChange={(e) => setBuildingData({...buildingData , title_en : e.target.value})}/>
                                </div>

                                <div className="input_group">
                                    <label>Arabic Title</label>
                                    <input type="text" placeholder="Arabic Title" value={buildingData.title_ar} onChange={(e) => setBuildingData({...buildingData , title_ar : e.target.value})}/>
                                </div>

                                <div className="input_group">
                                    <label>English Description</label>
                                    <textarea placeholder="English Description" value={buildingData.description_en} onChange={(e) => setBuildingData({...buildingData , description_en : e.target.value})}></textarea>
                                </div>

                                <div className="input_group">
                                    <label>Arabic Description</label>
                                    <textarea placeholder="Arabic Description" value={buildingData.description_ar} onChange={(e) => setBuildingData({...buildingData , description_ar : e.target.value})}></textarea>
                                </div>

                            <div className="input_group">
                                <label>Image</label>
                                <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                            </div>
                        </form>
                    </AddModal>
                </div>

                <EditModal onOk={handleEdit} title="Edit Building" isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
                <form onSubmit={handleEdit} className="modal_form">
                                <div className="input_group">
                                    <label>English Title</label>
                                    <input type="text" placeholder="English Title" value={rowData.title_en} onChange={(e) => setRowData({...rowData , title_en : e.target.value})}/>
                                </div>
                                <div className="input_group">
                                    <label>Arabic Title</label>
                                    <input type="text" placeholder="Arabic Title" value={rowData.title_ar} onChange={(e) => setRowData({...rowData , title_ar : e.target.value})}/>
                                </div>

                                <div className="input_group">
                                    <label>English Description</label>
                                    <textarea placeholder="English Description" value={rowData.description_en} onChange={(e) => setRowData({...rowData , description_en : e.target.value})}></textarea>
                                </div>

                                <div className="input_group">
                                    <label>Arabic Description</label>
                                    <textarea placeholder="Arabic Description" value={rowData.description_ar} onChange={(e) => setRowData({...rowData , description_ar : e.target.value})}></textarea>
                                </div>

                            <div className="input_group">
                                <label>Image</label>
                                <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                            </div>
                        </form>
                </EditModal>

                <ShowStatusModal isHidden={showStatus} isLoading={isLoading} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus} onOk={handleUpdateStatus}/>
                <Table headers={headers} body={buildings}/>
            </div>
        </DefaultLayout>
    )
}
