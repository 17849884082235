import { useEffect, useState } from "react";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from "../../components/ShowStatusModal/ShowStatusModal";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import Table from "../../components/table";
import { useNavigate } from "react-router-dom";
import { FormGroup, Label, uploadImage } from "../SiteInfo/utils";
import ImageInput from "../../components/imageInput/ImageInput";
import ReactQuill from "react-quill";

export default function Products() {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [video, setVideo] = useState(null);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newProduct, setNewProduct] = useState({
    name_en: "",
    name_ar: "",
    number: 1,
    description_en: "",
    description_ar: "",
  });
  const [rowData, setRowData] = useState({});
  const [types, setTypes] = useState([]);

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "Image",
      dataIndex: "images",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <img
            src={row?.images && row?.images?.length && row?.images[0]?.url}
            style={{ width: "40px" }}
          />
        );
      },
    },
    {
      label: "English Name",
      dataIndex: "name_en",
    },
    {
      label: "Arabic Name",
      dataIndex: "name_ar",
    },
    // {
    //   label: "English Description",
    //   dataIndex: "description_en",
    // },
    {
      label: "Color",
      dataIndex: "color",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            style={{ background: row?.color, width: "90px", height: "30px" }}
          ></p>
        );
      },
    },
    {
      label: "Type",
      dataIndex: "type_name_en",
      type: "children",
      children: ({ row }) => {
        return <b>{row?.type?.name_en}</b>;
      },
    },
    {
      label: "",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="icons-container">
            <button
              className="edit_btn"
              onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}
            >
              Edit
            </button>
            {row.hidden == 0 ? (
              <button
                className="show_btn"
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(false);
                  setStatus(row.id);
                }}
              >
                Hide
              </button>
            ) : (
              <button
                className="show_btn"
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(true);
                  setStatus(row.id);
                }}
              >
                Show
              </button>
            )}

            <button
              className="edit_btn"
              onClick={() => {
                navigate(`/productCatgeories/${row.id}`);
              }}
            >
              Categories
            </button>

            <button
              className="edit_btn"
              onClick={() => {
                navigate(`/recommendedRecipes/${row.id}`);
              }}
            >
              Recipes
            </button>
          </div>
        );
      },
    },
  ];

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send = {
      number: video_number,
    };
    await axios
      .post(BASE_URL + `/products/change_number/${video_id}`, data_send)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllProducts();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };
  function handleGetAllproduct_types() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/product_types/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setTypes(res.data.result?.filter((item) => item?.hidden == 0));
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }
  useEffect(() => {
    handleGetAllproduct_types();
  }, []);
  function handleGetAllProducts() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/products/get_for_admin?token=${token}`)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.status == "success") {
          setAllProducts(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllProducts();
  }, []);
  const [images, setImages] = useState({
    aboutImage: { id: null, imageURL: null, imageFile: null },
    catalogeImage: { id: null, imageURL: null, imageFile: null },
    mapImage: { id: null, imageURL: null, imageFile: null },
  });
  async function handleSubmit(e) {
    const uploadedImages = { ...images };
    e.preventDefault();
    setIsLoading(true);

    // Upload images if new images are selected
    for (const key in images) {
      if (images[key].imageFile) {
        const imageUrl = await uploadImage(images[key].imageFile);
        uploadedImages[key] = { ...uploadedImages[key], imageURL: imageUrl };
      }
    }

    // Update siteInfoData with uploaded image URLs
    const updatedSiteInfoData = {
      images: uploadedImages?.internal_image?.imageURL,
    };

    const data_send = {
      ...newProduct,
      ...updatedSiteInfoData,
      name_ar: newProduct.name_ar,
      description_ar: newProduct.description_ar,
    };
    axios
      .post(BASE_URL + `/products/add_new?token=${token}`, data_send)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllProducts();
          setImages({
            aboutImage: { id: null, imageURL: null, imageFile: null },
            catalogeImage: { id: null, imageURL: null, imageFile: null },
            mapImage: { id: null, imageURL: null, imageFile: null },
          });
          setNewProduct({
            name_en: "",
            name_ar: "",
            number: 1,
            description_en: "",
            description_ar: "",
          });
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalAddOpen(false);
      })
      .catch((e) => console.log(e));
  }

  async function handleEdit(e) {
    e.preventDefault();
    const uploadedImages = { ...images };

    // Upload images if new images are selected
    for (const key in images) {
      if (images[key].imageFile) {
        const imageUrl = await uploadImage(images[key].imageFile);
        uploadedImages[key] = { ...uploadedImages[key], imageURL: imageUrl };
      }
    }
    const data_send = {
      ...rowData,
      images:
        uploadedImages?.internal_image?.imageURL ||
        (rowData.images && rowData.images[0]?.url),
      name_ar: rowData.name_ar,
      description_ar: rowData.description_ar,
    };
    delete data_send?.type;
    setIsLoading(true);
    axios
      .post(
        BASE_URL + `/products/update_one/${rowData.id}?token=${token}`,
        data_send
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllProducts();
          setImages({
            aboutImage: { id: null, imageURL: null, imageFile: null },
            catalogeImage: { id: null, imageURL: null, imageFile: null },
            mapImage: { id: null, imageURL: null, imageFile: null },
          });
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalEditOpen(false);
      })
      .catch((e) => console.log(e));
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/products/update_status/${status}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllProducts();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally((e) => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Products" />
          <AddModal
            isLoading={isLoading}
            title="Add Product"
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
            onOk={handleSubmit}
          >
            <form className="modal_form" onSubmit={handleSubmit}>
              <FormGroup>
                <Label>product Image</Label>
                <ImageInput
                  id="internal_image"
                  name="internal_image"
                  getImage={(imgFile) =>
                    setImages({
                      ...images,
                      internal_image: {
                        ...images.internal_image,
                        imageFile: imgFile,
                        imageUrl: null,
                      },
                    })
                  }
                />
              </FormGroup>
              <div className="input_group">
                <label>Number</label>
                <input
                  type="text"
                  placeholder="Number"
                  value={newProduct.number}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, number: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>English Name</label>
                <input
                  type="text"
                  placeholder="English Name"
                  value={newProduct.name_en}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, name_en: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>Arabic Name</label>
                <input
                  type="text"
                  placeholder="Arabic Name"
                  value={newProduct.name_ar}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, name_ar: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>English Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={newProduct?.description_en}
                  onChange={(e) => {
                    return setNewProduct({
                      ...newProduct,
                      description_en: e,
                    });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="input_group">
                <label>Arabic Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={newProduct?.description_ar}
                  onChange={(e) => {
                    return setNewProduct({
                      ...newProduct,
                      description_ar: e,
                    });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="input_group">
                <label>Color</label>
                <input
                  type="color"
                  placeholder="Color"
                  value={newProduct.color}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, color: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>Choose Type</label>
                <select
                  value={rowData?.type_id}
                  onChange={(e) =>
                    setNewProduct({ ...newProduct, type_id: e.target.value })
                  }
                >
                  {types?.map((cook) => (
                    <option value={cook.id}>{cook?.name_en}</option>
                  ))}
                </select>
              </div>
            </form>
          </AddModal>
        </div>
        <EditModal
          isLoading={isLoading}
          title="Edit Product"
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          onOk={handleEdit}
        >
          <form className="modal_form" onSubmit={handleEdit}>
            <FormGroup>
              <Label>internal_image</Label>
              <ImageInput
                id="internal_image"
                name="internal_image"
                getImage={(imgFile) =>
                  setImages({
                    ...images,
                    internal_image: {
                      ...images.internal_image,
                      imageFile: imgFile,
                      imageUrl: null,
                    },
                  })
                }
              />
            </FormGroup>
            <div className="input_group">
              <label>English Name</label>
              <input
                type="text"
                placeholder="English Name"
                value={rowData.name_en}
                onChange={(e) =>
                  setRowData({ ...rowData, name_en: e.target.value })
                }
              />
            </div>
            <div className="input_group">
              <label>Arabic Name</label>
              <input
                type="text"
                placeholder="Arabic Name"
                value={rowData.name_ar}
                onChange={(e) =>
                  setRowData({ ...rowData, name_ar: e.target.value })
                }
              />
            </div>

            <div className="input_group">
                <label>English Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.description_en}
                  onChange={(e) => {
                    return setRowData({
                      ...rowData,
                      description_en: e,
                    });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
            <div className="input_group">
                <label>Arabic Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.description_ar}
                  onChange={(e) => {
                    return setRowData({
                      ...rowData,
                      description_ar: e,
                    });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
            <div className="input_group">
              <label>Color</label>
              <input
                type="color"
                placeholder="Color"
                value={rowData?.color}
                onChange={(e) =>
                  setRowData({ ...rowData, color: e.target.value })
                }
              />
            </div>
            <div className="input_group">
              <label>Choose Type</label>
              <select
                value={rowData?.type_id}
                onChange={(e) =>
                  setRowData({ ...rowData, type_id: e.target.value })
                }
              >
                {types?.map((cook) => (
                  <option value={cook.id}>{cook?.name_en}</option>
                ))}
              </select>
            </div>
          </form>
        </EditModal>
        <ShowStatusModal
          isLoading={isLoading}
          isModalOpen={isShowStatus}
          setIsModalOpen={setIsShowStatus}
          isHidden={showStatus}
          onOk={handleUpdateStatus}
        />
        <Table headers={headers} body={allProducts} />
      </div>
    </DefaultLayout>
  );
}
