import React, { useState } from 'react';
import { Button, Modal } from 'antd';
const App = ({ isModalOpen , setIsModalOpen  , onOk , isLoading , isHidden}) => {

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
     <Modal title="Delete Item" open={isModalOpen} onCancel={handleCancel} footer={[
        <>
          <Button onClick={handleCancel}>الغاء</Button>
          <Button onClick={onOk}>{isLoading ? "loading...": "مسح العنصر"}</Button>
        </>
     ]}>
          <div>
              <h5 style={{fontSize:"18px"}}>هل تريد حقا مسح هذا العنصر؟</h5>
          </div>
     </Modal>
  );
};
export default App;