import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../data";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../components/table";

export default function RecommendedRecipes() {
    const {productId} = useParams();
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [isModalDeleteOpen , setIsModalDeleteOpen] = useState(false);
    const [delItem , setDelItem] = useState(null);
    const [allRecommendedRecipes , setAllRecommendedRecipes] = useState([]);
    const [allCookings , setAllCookings] = useState([]);
    const [recipesData , setRecipeData] = useState({
        cook_id :""
    });
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"English Name",
            dataIndex:"name_en",
            type:"children",
            children : ({row}) => {
               return (
                <p >{row.cook.name_en}</p>
               )
            }
        },
        {
            label:"English Description",
            dataIndex:"description_en",
            type:"children",
            children : ({row}) => {
               return (
                <p  dangerouslySetInnerHTML={{ __html: row.cook.description_en}}></p>
               )
            }
        },
        {
            label: "",
            type: "children",
            children: ({ row, headers }) => {
              return (
                <div className="icons-container">
                 <button className="edit_btn"  onClick={() => {
                      setIsModalEditOpen(true);
                      setRowData(row);
                    }}>Edit</button>

                 <button className="delete_btn" onClick={() => {
                    console.log(delItem);
                    setIsModalDeleteOpen(true);
                    setDelItem(row.id)
                    console.log(row.id)
                 }}>Delete</button>
                </div>
              );
            },
        }
    ]

    function handleGetAllRecommendedRecipes() {
        setIsLoading(true);
        axios.get(BASE_URL + `/products/get_product_recipes/${productId}?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
                setAllRecommendedRecipes(res.data.result)
            }else if(res.data.status == "faild") {
               toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        })
        .finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    function handleAllCooking() {
        setIsLoading(true);
        axios.get(BASE_URL + `/cooks/get_for_admin?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
                 console.log(res.data.result);
                setAllCookings(res.data.result);
            }else if(res.data.status == "faild") {
                toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllRecommendedRecipes();
        handleAllCooking();
    } , [])

   function handleSubmit(e) {
    e.preventDefault();
    const data_send = {
        cook_id : Number(recipesData.cook_id)
    }
    setIsLoading(true);
    axios.post(BASE_URL + `/products/add_product_reciep/${productId}?token=${token}`, data_send)
    .then(res => {
        console.log(res);
        if(res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllRecommendedRecipes();
            setRecipeData({
                cook_id :"",
            })
        }else if(res.data.status == "faild") {
            toast.error(res.data.message);
        }else {
            toast.error("حدث خطأ ما");
        }
    }).finally(() => {
        setIsLoading(false);
        setIsModalAddOpen(false);
    }).catch(e => console.log(e))
   }

   function handleEdit(e) {
    e.preventDefault();
    const data_send = {
        ...rowData,
        cook_id : Number(rowData.cook_id)
    }
    setIsLoading(true);
    axios.post(BASE_URL + `/products/update_product_reciep/${rowData.id}?token=${token}`, data_send)
    .then(res => {
        console.log(res);
        if(res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllRecommendedRecipes();

        }else if(res.data.status == "faild") {
            toast.error(res.data.message);
        }else {
            toast.error("حدث خطأ ما");
        }
    }).finally(() => {
        setIsLoading(false);
        setIsModalEditOpen(false);
    }).catch(e => console.log(e))
   }

   function handleDelete() {
     setIsLoading(true);
     axios.get(BASE_URL + `/products/delete_product_reciep/${delItem}?token=${token}`)
     .then(res => {
        console.log(res);
       if(res.data.status == "success") {
        toast.success(res.data.message);
        handleGetAllRecommendedRecipes();
       }else if(res.data.status == "faild") {
        toast.error(res.data.message);
       }else{
         toast.error("حدث خطأ ما");
       }
     })
     .finally(() => {
        setIsLoading(false);
        setIsModalDeleteOpen(false);
     }).catch(e => console.log(e))
   }

  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Rcommended Recipes"/>
                <AddModal onOk={handleSubmit} isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen} title="Add Recommended Recipe">
                    <form className="modal_form" onSubmit={handleSubmit}>
                        <div className="input_group">
                            <label>Choose Cooking</label>
                            <select onChange={(e) =>setRecipeData({...recipesData , cook_id : e.target.value}) }>
                                {allCookings.map(cook =>
                                    <option value={cook.id}>{cook.name_en}</option>
                                )}
                            </select>
                        </div>
                    </form>
                </AddModal>
            </div>

            <EditModal onOk={handleEdit} isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen} title="Edit Recommended Recipe">
            <form className="modal_form" onSubmit={handleEdit}>
                        <div className="input_group">
                            <label>Choose Cooking</label>
                            <select value={rowData.cook_id} onChange={(e) =>setRowData({...rowData , cook_id : e.target.value}) }>
                                {allCookings.map(cook =>
                                    <option value={cook.id}>{cook.name_en}</option>
                                )}
                            </select>
                        </div>
                    </form>
            </EditModal>
             <DeleteModal isLoading={isLoading} isModalOpen={isModalDeleteOpen} setIsModalOpen={setIsModalDeleteOpen} onOk={handleDelete}/>
            <Table headers={headers} body={allRecommendedRecipes}/>
        </div>
    </DefaultLayout>
  )
}
