import React, { useState } from 'react';
import { Button, Modal } from 'antd';
const App = ({children , isModalOpen , setIsModalOpen  , title , onOk , isLoading}) => {

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
         Add
      </Button>
      <Modal title={title} open={isModalOpen}  onCancel={handleCancel} footer={[
        <>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={onOk} disabled={isLoading}>Add</Button>
        </>
      ]} >
        {children}
        
      </Modal>
    </>
  );
};
export default App;