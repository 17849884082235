import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../components/table";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../data";
import { useEffect } from "react";

export default function Foods() {
  const { recipeId } = useParams();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [allFoods, setAllFoods] = useState([]);
  const [img, setImg] = useState(null);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [foodData, setFoodData] = useState({
    name_ar: "",
    name_en: "",
    image: "",
    number:1,

    recipe_id: recipeId,
  });
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "English Name",
      dataIndex: "name_en",
    },
    {
      label: "Arabic Name",
      dataIndex: "name_ar",
    },
    {
        label: "",
        type: "children",
        children: ({ row, headers }) => {
          return (
            <div className="icons-container">
              {row.hidden == 0 ? (
                <FaRegEye
                  style={{ color: "green" }}
                  onClick={() => {
                    setIsShowStatus(true);
                    setShowStatus(false);
                    setStatus(row.id);
                  }}
                />
              ) : (
                <FaRegEyeSlash
                  style={{ color: "green" }}
                  onClick={() => {
                    setIsShowStatus(true);
                    setShowStatus(true);
                    setStatus(row.id);
                  }}
                />
              )}
              <FaRegEdit
                style={{ color: "blue" }}
                onClick={() => {
                  setIsModalEditOpen(true);
                  setRowData(row);
                }}
              />
              {/* <button className="btn btn-primary" onClick={()=>navigate("/Recipes_Categories/"+row?.id)}>Sub Categories</button> */}

            </div>
          );
        },
      },
  ];
  function handleGetAllFoods() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/recipes/get_recipe_foods_for_admin/${recipeId}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllFoods(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }


  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send={
      number:video_number
    }
    await axios.post(BASE_URL+`/foods/change_number/${video_id}`,data_send)
    .then((res)=>{
      console.log(res)
      if(res.data.status=='success'){
        toast.success(res.data.message)
        handleGetAllFoods();
      }
      else if(res.data.status=='faild'){
        toast.error(res.data.message)
      }
      else {
        toast.error("something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setLoader(false);
    })
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };

  useEffect(() => {
    handleGetAllFoods();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", img);
    setIsLoading(true);
    const data_send = {
        ...foodData,
        recipe_id: recipeId,
      };
      axios
        .post(BASE_URL + `/foods/add_new?token=${token}`, data_send)
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllFoods();
            setImg(null);
            setFoodData({
              image: "",
              name_ar: "",
              number:1,

              name_en: "",
            });
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error('حدث خطأ ما');
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalAddOpen(false);
        })
        .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    if (img == null) {
      const data_send = {
        ...rowData,
      };
      axios
        .post(
          BASE_URL + `/foods/update_one/${rowData.id}?token=${token}`,
          data_send
        )
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllFoods();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        })
        .catch((e) => console.log(e));
    } else {
      const formData = new FormData();
      formData.append("image", img);
      setIsLoading(true);
      axios
        .post(BASE_URL + '/file_upload', formData)
        .then((res) => {
          if (res.data.status == "success") {
            const data_send = {
              ...rowData,
              image: res.data.result.image,
            };
            axios
              .post(BASE_URL + `/foods/update_one/${rowData?.id}?token=${token}`, data_send)
              .then((res) => {
                if (res.data.status == "success") {
                  toast.success(res.data.message);
                  handleGetAllFoods();
                } else if (res.data.status == "faild") {
                  toast.error(res.data.message);
                } else {
                  toast.error("حدث خطأ ما");
                }
              })
              .finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
              })
              .catch((e) => console.log(e));
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/foods/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllFoods();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Foods" />
          <AddModal
            onOk={handleSubmit}
            title="Add Food"
            isLoading={isLoading}
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="inputs_container">
                <div className="input_group">
                  <label>English Name</label>
                  <input
                    type="text"
                    placeholder="English Name"
                    value={foodData.name_en}
                    onChange={(e) =>
                      setFoodData({ ...foodData, name_en: e.target.value })
                    }
                  />
                </div>

                <div className="input_group">
                  <label>Arabic Name</label>
                  <input
                    type="text"
                    placeholder="Arabic Name"
                    value={foodData.name_ar}
                    onChange={(e) =>
                      setFoodData({ ...foodData, name_ar: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="input_group">
                  <label>Number</label>
                  <input
                    type="text"
                    placeholder="Number"
                    value={foodData.number}
                    onChange={(e) =>
                      setFoodData({ ...foodData, number: e.target.value })
                    }
                  />
                </div>

              <div className="input_group">
                <label>Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>
            </form>
          </AddModal>
        </div>

        <EditModal
          onOk={handleEdit}
          title="Edit Food"
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
        >
          <form onSubmit={handleEdit} className="modal_form">
            <div className="inputs_container">
              <div className="input_group">
                <label>English Name</label>
                <input
                  type="text"
                  placeholder="English Name"
                  value={rowData.name_en}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_en: e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Arabic Name</label>
                <input
                  type="text"
                  placeholder="Arabic Name"
                  value={rowData.name_ar}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_ar: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="input_group">
              <label>Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>
          </form>
        </EditModal>

        <ShowStatusModal
          onOk={handleUpdateStatus}
          isLoading={isLoading}
          isHidden={showStatus}
          isModalOpen={isShowStatus}
          setIsModalOpen={setIsShowStatus}
        />
        <Table headers={headers} body={allFoods} />
      </div>
    </DefaultLayout>
  );
}
