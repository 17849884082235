import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout/index";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import Table from "../../components/table";

export default function Standards() {
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
  const [allStandards, setAllStandards] = useState([]);
  const [img, setImg] = useState(null);
  const [standardsData, setStandardsData] = useState({
    image: "",
    description_ar: "",
    description_en: "",
  });
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Arabic Description",
      dataIndex: "description_ar",
    },
    {
      label: "English Description",
      dataIndex: "description_en",
    },
    {
      label: "Image",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.image} alt="standards image" />;
      },
    },
    // {
    //   label: "English Description",
    //   dataIndex: "description_en",
    // },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            {row.hidden == 0 ? (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(false);
              setStatus(row.id);
            }}>Hide</button>
            ) : (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(true);
              setStatus(row.id);
            }}>Show</button>
            )}
            <button className="edit_btn" onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}>Edit</button>
          </div>
        );
      },
    },
  ];

  function handleGetAllStandards() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/standers/get_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllStandards(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllStandards();
    console.log(allStandards);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (img == null) {
      toast.warn("ادخل الصورة أولا");
      return;
    }
    const formData = new FormData();
    formData.append("image", img);
    setIsLoading(true);

    axios
      .post(BASE_URL + "/file_upload", formData)
      .then((res) => {
        if (res.data.status == "success") {
          const data_send = {
            ...standardsData,
            description_ar : rowData.description_ar,
            image: res.data.result.image,
          };
          axios
            .post(BASE_URL + `/standers/add_new?token=${token}`, data_send)
            .then((res) => {
              console.log(res);
              if (res.data.status == "success") {
                toast.success(res.data.message);
                handleGetAllStandards();
                setStandardsData({
                  image: "",
                  description_ar: "",
                  description_en: "",
                });
              } else if (res.data.status == "faild") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsModalAddOpen(false);
            })
            .catch((e) => console.log(e));
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (img == null) {
      const data_send = {
        ...rowData,
        image: rowData.image,
        description_ar : rowData.description_ar,
      };

      axios
        .post(
          BASE_URL + `/standers/update_one/${rowData.id}?token=${token}`,
          data_send
        )
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllStandards();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        })
        .catch((e) => console.log(e));
    } else {
      const formData = new FormData();
      formData.append("image", img);
      axios
        .post(BASE_URL + "/file_upload", formData)
        .then((res) => {
          if (res.data.status == "success") {
            const data_send = {
              ...rowData,
              image: res.data.result.image,
            };
            axios
              .post(
                BASE_URL + `/standers/update_one/${token}?token=${token}`,
                data_send
              )
              .then((res) => {
                if (res.data.status == "success") {
                  toast.success(res.data.message);
                  handleGetAllStandards();
                } else if (res.data.status == "faild") {
                  toast.error(res.data.message);
                } else {
                  toast.error("حدث خطأ ما");
                }
              })
              .finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
              });
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/standers/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllStandards();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      }).finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Standards" />
          <AddModal
            isLoading={isLoading}
            setIsModalOpen={setIsModalAddOpen}
            isModalOpen={isModalAddOpen}
            title="Add Standards"
            onOk={handleSubmit}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="input_group">
                <label>Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>

              <div className="input_group">
                <label>English Description</label>
                <textarea
                  placeholder="English Description"
                  value={standardsData.description_en}
                  onChange={(e) =>
                    setStandardsData({
                      ...standardsData,
                      description_en: e.target.value,
                    })
                  }
                ></textarea>
              </div>

              <div className="input_group">
                <label>Arabic Description</label>
                <textarea
                  placeholder="Arabic Description"
                  value={standardsData.description_ar}
                  onChange={(e) =>
                    setStandardsData({
                      ...standardsData,
                      description_ar: e.target.value,
                    })
                  }
                ></textarea>
              </div>

            </form>
          </AddModal>
        </div>

        <EditModal
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          title="Edit Standards"
          onOk={handleEdit}
        >
          <form onSubmit={handleEdit} className="modal_form">
            <div className="input_group">
              <label>Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>

            <div className="input_group">
              <label>English Description</label>
              <textarea
                placeholder="English Description"
                value={rowData.description_en}
                onChange={(e) =>
                  setRowData({ ...rowData, description_en: e.target.value })
                }
              ></textarea>
            </div>

            <div className="input_group">
              <label>Arabic Description</label>
              <textarea
                placeholder="Arabic Description"
                value={rowData.description_ar}
                onChange={(e) =>
                  setRowData({ ...rowData, description_ar: e.target.value })
                }
              ></textarea>
            </div>
          </form>
        </EditModal>
        <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
        <Table headers={headers} body={allStandards} />
      </div>
    </DefaultLayout>
  );
}
