import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/home";
import Banners from "../pages/Banners/Banners";
import Recipes from "../pages/Recipes/Recipes";
import Brands from "../components/Brands/Brands";
import BrandsCategories from "../components/Brands/BrandsCategories/BrandsCategories";
import Certifications from "../pages/Certifications/Certifications";
import Standards from "../pages/Standards/Standards";
import Values from "../pages/Values/Values";
import Foods from "../pages/Foods/Foods";
import Cooking from "../pages/Cooking/Cooking";
import WhyChooseUs from "../pages/WhyChooseUs/WhyChooseUs";
import Buildings from "../pages/Buildings/Buildings";
import Features from "../pages/Features/Features";
import Content from "../pages/Continent/Continent";
import Continent from "../pages/Continent/Continent";
import Countries from "../pages/Countries/Countries";
import Collaborates from "../pages/Collaborates/Collaborates";
import Joins from "../pages/Joins/Joins";
import Contact from "../pages/Contact/Contact";
import SiteInfo from "../pages/SiteInfo/SiteInfo";
import CookingCategories from "../pages/Cooking/CookingCategories/CookingCategories";
import CookingProps from "../pages/Cooking/CookingProps/CookingProps";
import CookingSteps from "../pages/Cooking/CookingSteps/CookingSteps";
import Products from "../pages/products/Products";
import ProductsCategories from "../pages/products/ProductsCategories/ProductsCategories";
import RecommendedRecipes from "../pages/RecommendedRecipes/RecommendedRecipes";
import Types from "../pages/Types/Types";
import Socials from "../pages/Socials/Socials";
import ProductionSteps from "../pages/ProductionSteps/ProductionSteps";
import SectionTexts from "../pages/SectionTexts/SectionTexts";
import SocialParentSocials from "../pages/SocialParentSocials/SocialParentSocials";
import SocialParents from "../pages/SocialParents/SocialParents";

function RouteComponent() {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Banners />}/>
        <Route path="/certificates" element={<Certifications />}/>
        <Route path="/standards" element={<Standards />}/>
        <Route path="/values" element={<Values />}/>
        <Route path="/products" element={<Products />}/>
        <Route path="/productCatgeories/:productId" element={<ProductsCategories />}/>
        <Route path="/recommendedRecipes/:productId" element={<RecommendedRecipes />}/>
        <Route path="/recipes" element={<Recipes />}/>
        <Route path="/Recipes_Categories/:recipeId" element={<Foods />}/>
        <Route path="/cooking" element={<Cooking />}/>
        <Route path="/cookingCategories/:cookId" element={<CookingCategories />}/>
        <Route path="/cookingProps/:cookId" element={<CookingProps />}/>
        <Route  path="/cookingSteps/:cookId" element={<CookingSteps />}/>
        <Route path="/whyChooseUs" element={<WhyChooseUs />}/>
        <Route path="/Types" element={<Types />}/>
        <Route path="/buildings" element={<Buildings />}/>
        <Route path="/features" element={<Features />}/>
        <Route path="/brands" element={<Brands />}/>
        <Route path="/continent" element={<Continent  />}/>
        <Route path="/collaborates" element={<Collaborates />}/>
        <Route path="/joins" element={<Joins />}/>
        <Route path="/contacts" element={<Contact />}/>
        <Route path="/siteInfo" element={<SiteInfo />}/>
        <Route path="/Recipes_Categories" element={<Recipes />}/>
        <Route path="/continent/:continentId" element={<Countries />}/>
        <Route path="/brands/:categoryId" element={<BrandsCategories />}/>
        <Route path="/banners"  element={<Banners />}/>
        <Route path="/socials"  element={<Socials />}/>
        <Route path="/production_steps"  element={<ProductionSteps />}/>
        <Route path="/section_texts"  element={<SectionTexts />}/>
        <Route path="/social_parents"  element={<SocialParents />}/>
        <Route path="/social_parent_socials"  element={<SocialParentSocials/>}/>
      </Route>
      <Route path="*" element={<Products />} />
    </Routes>
  );
}

export default RouteComponent;
