import ContentNav from "../../datanavcontent";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import Table from "../../components/table";
import DefaultLayout from "../../layout/defaultlayout";

export default function WhyChooseUs() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
    const [chooseUS , setChooseUS] = useState([]);
    const [chooseUSData , setChooseUSData] = useState({
        text_ar:"",
        text_en:""
    })
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label : "#",
            dataIndex:"id",
        },
        {
            label:"English Description",
            dataIndex : "text_en"
        },
        {
            label:"Arabic Description",
            dataIndex : "text_ar"
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
          {row.hidden == 0 ? (
           <button className="show_btn" onClick={() => {
            setIsShowStatus(true);
            setShowStatus(false);
            setStatus(row.id);
          }}>Hide</button>
          ) : (
           <button className="show_btn" onClick={() => {
            setIsShowStatus(true);
            setShowStatus(true);
            setStatus(row.id);
          }}>Show</button>
          )}
          <button className="edit_btn" onClick={() => {
              setIsModalEditOpen(true);
              setRowData(row);
            }}>Edit</button>
        </div>
        );
      },
        }
    ]

    function handleGetAllChooseUs() {
        setIsLoading(true);
        axios.get(BASE_URL + `/why_choose/get_for_admin?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
                setChooseUS(res.data.result);
            }else if(res.data.status == "faild") {
                toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllChooseUs();
    } ,[])

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        const data_send = {
            ...chooseUSData,
            text_ar : chooseUSData.text_ar,
        }
        axios.post(BASE_URL + `/why_choose/add_new?token=${token}` , data_send)
        .then(res => {
            if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllChooseUs();
              setChooseUSData({
                text_ar:"",
                text_en:"",
              })
            }else if(res.data.status == "faild") {
            toast.error(res.data.message);
            }else {
              toast.error("حدث خطأ ما");
            }
        }).finally(() => {
            setIsLoading(false);
            setIsModalAddOpen(false);
        }).catch(e =>  console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        const data_send = {
            ...rowData,
            text_ar : rowData.text_ar,
        }
        axios.post(BASE_URL + `/why_choose/update_one/${rowData.id}?token=${token}`,data_send)
        .then(res => {
            if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllChooseUs();
            }else if(res.data.status == "faild") {
              toast.error(res.data.message);
            }else {
              toast.error("حدث خطأ ما");
            }
        }).finally(() => {
            setIsLoading(false);
            setIsModalEditOpen(false);
        }).catch(e => console.log(e))
    }

    function handleUpdateStatus() {
      setIsLoading(true);
        axios
          .get(BASE_URL + `/why_choose/update_status/${status}?token=${token}`)
          .then((res) => {
            console.log(res);
            if (res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllChooseUs();
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          }).finally(() => {
            setIsLoading(false);
            setIsShowStatus(false);
          })
          .catch((e) => console.log(e));
      }
  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Why Choose Us"/>
                <AddModal title="Add Description" isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen} onOk={handleSubmit}>
                    <form onSubmit={handleSubmit} className="modal_form">
                            <div className="input_group">
                                <label>English Description</label>
                                <textarea placeholder="English Description" value={chooseUSData.text_en} onChange={(e) => setChooseUSData({...chooseUSData , text_en : e.target.value})}></textarea>
                            </div>
                            <div className="input_group">
                                <label>Arabic Description</label>
                                <textarea placeholder="Arabic Description" value={chooseUSData.text_ar} onChange={(e) => setChooseUSData({...chooseUSData , text_ar : e.target.value})}></textarea>
                            </div>
                    </form>
                </AddModal>
            </div>

            <EditModal title="Edit Description" isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen} onOk={handleEdit}>
                <form onSubmit={handleEdit} className="modal_form">
                            <div className="input_group">
                                <label>English Description</label>
                                <textarea placeholder="English Description" value={rowData.text_en} onChange={(e) => setRowData({...rowData , text_en : e.target.value})}></textarea>
                            </div>
                            <div className="input_group">
                                <label>Arabic Description</label>
                                <textarea placeholder="Arabic Description" value={rowData.text_ar} onChange={(e) => setRowData({...rowData , text_ar : e.target.value})}></textarea>
                            </div>
                    </form>
                    </EditModal>
                    <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
                    <Table headers={headers} body={chooseUS}/>
        </div>
    </DefaultLayout>
  )
}
