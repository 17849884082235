import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from "../../components/ShowStatusModal/ShowStatusModal";
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Table from "../table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

export default function Brands() {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [img, setImg] = useState(null);
  const [mainImage, setMainImg] = useState(null);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const [smallImg,setSmallImg]=useState(null)
  const [smallMainImg,setSmallMainImg]=useState(null)
  const [brandsData, setBrandsData] = useState({
    name_ar: "",
    name_en: "",
    number: 1,
    description_ar: "",
    description_en: "",
    image: "",
    main_image: "",
  });
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "Brand Main Image",
      dataIndex: "main_image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.main_image} alt={row.title} />;
      },
    },
    {
      label: "Brand Small Main Image",
      dataIndex: "small_image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.small_main_img} alt={row.title} />;
      },
    },
    {
      label: "Brand Image",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.image} alt={row.title} />;
      },
    },
    {
      label: "Brand Small Image",
      dataIndex: "small_img",
      type: "children",
      children: ({ row }) => {
        return <img src={row.small_img} alt={row.title} />;
      },
    },
    {
      label: "Brand Color",
      dataIndex: "color",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            style={{ background: row?.color, width: "100px", height: "40px" }}
          ></p>
        );
      },
    },
    {
      label: "Arabic Name",
      dataIndex: "name_ar",
    },
    {
      label: "English Name",
      dataIndex: "name_en",
    },
    {
      label: "English Description",
      dataIndex: "description_en",
    },
    {
      label: "Arabic Description",
      dataIndex: "description_ar",
    },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button
              className="edit_btn"
              onClick={() => {
                setIsModalEditOpen(true);
                setRowData({ ...row });
              }}
            >
              Edit
            </button>
            {row.hidden == 0 ? (
              <button
                className="show_btn"
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(false);
                  setStatus(row.id);
                }}
              >
                Hide
              </button>
            ) : (
              <button
                className="show_btn"
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(true);
                  setStatus(row.id);
                }}
              >
                Show
              </button>
            )}

            <button
              className="edit_btn"
              onClick={() => {
                navigate(`/brands/${row.id}`);
              }}
            >
              Categories
            </button>
          </div>
        );
      },
    },
  ];

  function hadleGetAllBrands() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/brands/get_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.result);
          setAllBrands(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send = {
      number: video_number,
    };
    await axios
      .post(BASE_URL + `/brands/change_number/${video_id}`, data_send)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          hadleGetAllBrands();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };

  useEffect(() => {
    hadleGetAllBrands();
    console.log(allBrands);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", img);
    setIsLoading(true);
    const mainFormData = new FormData();
    mainFormData.append("image", mainImage);
    const main_image = await axios.post(
      BASE_URL + "/file_upload",
      mainFormData
    );
    axios
      .post(BASE_URL + "/file_upload", formData)
      .then((res) => {
        if (res.data.status == "success") {
          const smallImgForm=new FormData()
          smallImgForm.append('image',smallImg)
          axios.post(BASE_URL+"/file_upload",smallImgForm)
          .then((res2)=>{
            if(res.data.status=='success'){
              const smallMainImgForm=new FormData()
              smallMainImgForm.append('image',smallMainImg)
              axios.post(BASE_URL+"/file_upload",smallMainImgForm)
              .then((smMaRes)=>{
                if(smMaRes.data.status=='success'){
                  const data_send = {
                    ...brandsData,
                    image: res.data.result.image,
                    main_image: main_image?.data?.result?.image,
                    small_img:res2?.data?.result?.image,
                    small_main_img:smMaRes?.data?.result?.image,
                  };
                  // console.log(data_send)
                  // setIsLoading(false)
                  // return
                  axios
                    .post(BASE_URL + `/brands/add_new?token=${token}`, data_send)
                    .then((res) => {
                      if (res.data.status == "success") {
                        toast.success(res.data.message);
                        hadleGetAllBrands();
                        setMainImg(null);
                        setImg(null);
                        setSmallImg(null)
                        setSmallMainImg(false)
                        setBrandsData({
                          name_ar: "",
                          name_en: "",
                          number: 1,
                          description_ar: "",
                          description_en: "",
                          image: "",
                        });
                      } else if (res.data.status == "faild") {
                        toast.error(res.data.message);
                      } else {
                        toast.error("حدث خطأ ما");
                      }
                    })
                    .finally(() => {
                      setIsLoading(false);
                      setIsModalAddOpen(false);
                    })
                    .catch((e) => console.log(e));
                }
              })
              .catch(e=>console.log(e))
            }
          })
          .catch(e=>console.log(e))
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  async function handleEdit(e) {
    e.preventDefault();
    const mainFormData = new FormData();
    mainFormData.append("image", mainImage);
    let main_image = rowData?.main_image;
    try {
      if (mainImage)
        main_image = await axios.post(BASE_URL + "/file_upload", mainFormData);
    } catch (Err) {
      console.log(Err);
    }
    if (img == null) {
      // alert(rowData?.color)
      if(smallImg!=null){
        const smalImgForm=new FormData()
        smalImgForm.append('image',smallImg)
        axios.post(BASE_URL+"/file_upload",smalImgForm)
        .then((resSmImg)=>{
          if(resSmImg.data.status=='success'){
            if(smallMainImg!=null){
              const formSmMaImgForm=new FormData()
              formSmMaImgForm.append('image',smallMainImg)
              axios.post(BASE_URL+"/file_upload",formSmMaImgForm)
              .then((resSmMaImgRes)=>{
                if(resSmMaImgRes.data.status=='success'){
                  const data_send = {
                    ...rowData,
                    color: rowData?.color,
                    image: rowData.image,
                    small_img:resSmImg?.data?.result?.image,
                    small_main_img:resSmMaImgRes?.data?.result?.image,
                    main_image: main_image?.data?.result?.image,
                  };
                  axios
                    .post(
                      BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                      data_send
                    )
                    .then((res) => {
                      if (res.data.status == "success") {
                        toast.success(res.data.message);
                        hadleGetAllBrands();
                        setMainImg(null);
                        setImg(null);
                        setSmallImg(null)
                        setSmallMainImg(null)
                      } else if (res.data.status == "faild") {
                        toast.error(res.data.message);
                      } else {
                        toast.error("حدث خطأ ما");
                      }
                    })
                    .finally(() => {
                      setIsLoading(false);
                      setIsModalEditOpen(false);
                    })
                    .catch((e) => console.log(e));
                }
              })
              .catch(e=>console.log(e))
            }else {
              const data_send = {
                ...rowData,
                color: rowData?.color,
                image: rowData.image,
                small_img:resSmImg?.data?.result?.image,
                main_image: main_image?.data?.result?.image,
              };
              axios
                .post(
                  BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                  data_send
                )
                .then((res) => {
                  if (res.data.status == "success") {
                    toast.success(res.data.message);
                    hadleGetAllBrands();
                    setMainImg(null);
                    setImg(null);
                    setSmallImg(null)
                    setSmallMainImg(null)
                  } else if (res.data.status == "faild") {
                    toast.error(res.data.message);
                  } else {
                    toast.error("حدث خطأ ما");
                  }
                })
                .finally(() => {
                  setIsLoading(false);
                  setIsModalEditOpen(false);
                })
                .catch((e) => console.log(e));
            }
          }
        })
        .catch(e=>console.log(e))
      }
      else {
        if(smallMainImg!=null){
          const formSmMaImgForm=new FormData()
          formSmMaImgForm.append('image',smallMainImg)
          axios.post(BASE_URL+"/file_upload",formSmMaImgForm)
          .then((resSmMaImgRes)=>{
            if(resSmMaImgRes.data.status=='success'){
              const data_send = {
                ...rowData,
                color: rowData?.color,
                image: rowData.image,
                small_main_img:resSmMaImgRes?.data?.result?.image,
                main_image: main_image?.data?.result?.image,
              };
              axios
                .post(
                  BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                  data_send
                )
                .then((res) => {
                  if (res.data.status == "success") {
                    toast.success(res.data.message);
                    hadleGetAllBrands();
                    setMainImg(null);
                    setImg(null);
                    setSmallImg(null)
                    setSmallMainImg(null)
                  } else if (res.data.status == "faild") {
                    toast.error(res.data.message);
                  } else {
                    toast.error("حدث خطأ ما");
                  }
                })
                .finally(() => {
                  setIsLoading(false);
                  setIsModalEditOpen(false);
                })
                .catch((e) => console.log(e));
            }
          })
          .catch(e=>console.log(e))
        }
        else {
          const data_send = {
            ...rowData,
            color: rowData?.color,
            image: rowData.image,
            main_image: main_image?.data?.result?.image,
          };
          axios
            .post(
              BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
              data_send
            )
            .then((res) => {
              if (res.data.status == "success") {
                toast.success(res.data.message);
                hadleGetAllBrands();
                setMainImg(null);
                setImg(null);
                setSmallImg(null)
                setSmallMainImg(null)
              } else if (res.data.status == "faild") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsModalEditOpen(false);
            })
            .catch((e) => console.log(e));
        }
      }
    }











    else {
      const formData = new FormData();
      formData.append("image", img);
      axios
        .post(BASE_URL + "/file_upload", formData)
        .then((res) => {
          if (res.data.status == "success") {
            if(smallMainImg!=null){
              const smallMainImgForm=new FormData()
              smallMainImgForm.append('image',smallMainImg)
              axios.post(BASE_URL+"/file_upload",smallMainImgForm)
              .then((resSmMa)=>{
                if(resSmMa.data.status=='success'){
                  if(smallImg!=null){
                    const smallImgForm=new FormData()
                    smallImgForm.append('image',smallImg)
                    axios.post(BASE_URL+"/file_upload",smallImgForm)
                    .then((resSmImg)=>{
                      if(resSmImg.data.status=='success'){
                        const data_send = {
                          ...rowData,
                          image: res.data.result.image,
                          small_main_img: resSmMa.data.result.image,
                          small_img: resSmImg.data.result.image,
                          main_image: main_image?.data?.result?.image,
                        };

                        axios
                          .post(
                            BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                            data_send
                          )
                          .then((res) => {
                            if (res.data.status == "success") {
                              toast.success(res.data.message);
                              hadleGetAllBrands();
                              setMainImg(null);
                              setImg(null);
                              setSmallImg(null)
                              setSmallMainImg(null)
                            } else if (res.data.status == "faild") {
                              toast.error(res.data.message);
                            } else {
                              toast.error("حدث خطأ ما");
                            }
                          })
                          .finally(() => {
                            setIsLoading(false);
                            setIsModalEditOpen(false);
                          });
                      }
                    })
                    .catch(e=>console.log(e))
                  }
                  else {
                    const data_send = {
                      ...rowData,
                      image: res.data.result.image,
                      small_main_img: resSmMa.data.result.image,
                      main_image: main_image?.data?.result?.image,
                    };

                    axios
                      .post(
                        BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                        data_send
                      )
                      .then((res) => {
                        if (res.data.status == "success") {
                          toast.success(res.data.message);
                          hadleGetAllBrands();
                          setMainImg(null);
                          setImg(null);
                          setSmallImg(null)
                          setSmallMainImg(null)
                        } else if (res.data.status == "faild") {
                          toast.error(res.data.message);
                        } else {
                          toast.error("حدث خطأ ما");
                        }
                      })
                      .finally(() => {
                        setIsLoading(false);
                        setIsModalEditOpen(false);
                      });
                  }
                }
              })
              .catch(e=>console.log(e))
            }


            //here
            else {
              if(smallImg!=null){
                const smallImgForm=new FormData()
                smallImgForm.append('image',smallImg)
                axios.post(BASE_URL+"/file_upload",smallImgForm)
                .then((resSmImg)=>{
                  if(resSmImg.data.status=='success'){
                    const data_send = {
                      ...rowData,
                      image: res.data.result.image,
                      small_img: resSmImg.data.result.image,
                      main_image: main_image?.data?.result?.image,
                    };

                    axios
                      .post(
                        BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                        data_send
                      )
                      .then((res) => {
                        if (res.data.status == "success") {
                          toast.success(res.data.message);
                          hadleGetAllBrands();
                          setMainImg(null);
                          setImg(null);
                          setSmallImg(null)
                          setSmallMainImg(null)
                        } else if (res.data.status == "faild") {
                          toast.error(res.data.message);
                        } else {
                          toast.error("حدث خطأ ما");
                        }
                      })
                      .finally(() => {
                        setIsLoading(false);
                        setIsModalEditOpen(false);
                      });
                  }
                })
                .catch(e=>console.log(e))
              }
              else {
                const data_send = {
                  ...rowData,
                  image: res.data.result.image,
                  main_image: main_image?.data?.result?.image,
                };

                axios
                  .post(
                    BASE_URL + `/brands/update_one/${rowData.id}?token=${token}`,
                    data_send
                  )
                  .then((res) => {
                    if (res.data.status == "success") {
                      toast.success(res.data.message);
                      hadleGetAllBrands();
                      setMainImg(null);
                      setImg(null);
                      setSmallImg(null)
                      setSmallMainImg(null)
                    } else if (res.data.status == "faild") {
                      toast.error(res.data.message);
                    } else {
                      toast.error("حدث خطأ ما");
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  });
              }
            }
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/brands/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          hadleGetAllBrands();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Brands" />
          <AddModal
            isLoading={isLoading}
            onOk={handleSubmit}
            title="Add Brands"
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="inputs_container">
                <div className="input_group">
                  <label>English Brand Name</label>
                  <input
                    type="text"
                    placeholder="English Brand Name"
                    onChange={(e) =>
                      setBrandsData({ ...brandsData, name_en: e.target.value })
                    }
                  />
                </div>

                <div className="input_group">
                  <label>Arabic Brand Name</label>
                  <input
                    type="text"
                    placeholder="Arabic Brand Name"
                    onChange={(e) =>
                      setBrandsData({ ...brandsData, name_ar: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="input_group">
                <label>Brand Number</label>
                <input
                  type="text"
                  placeholder="Brand Number"
                  onChange={(e) =>
                    setBrandsData({ ...brandsData, number: e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Brand Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>

              <div className="input_group">
                <label>Brand Small Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => setSmallImg(e.target.files[0])}
                />
              </div>

              <div className="input_group">
                <label>Brand Main Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => setMainImg(e.target.files[0])}
                />
              </div>

            <div className="input_group">
              <label>Brand Small Main Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setSmallMainImg(e.target.files[0])}
              />
            </div>
              <div className="input_group">
                <label>Brand Color</label>
                <input
                  type="color"
                  onChange={(e) =>
                    setBrandsData({ ...brandsData, color: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>Brand Hover Color</label>
                <input
                  type="color"
                  onChange={(e) =>
                    setBrandsData({
                      ...brandsData,
                      hover_color: e.target.value,
                    })
                  }
                />
              </div>
              <div className="inputs_container">
                <div className="input_group">
                  <label>English Description</label>
                  <textarea
                    placeholder="English Description"
                    onChange={(e) =>
                      setBrandsData({
                        ...brandsData,
                        description_en: e.target.value,
                      })
                    }
                  ></textarea>
                </div>

                <div className="input_group">
                  <label>Arabic Description</label>
                  <textarea
                    placeholder="Arabic Description"
                    onChange={(e) =>
                      setBrandsData({
                        ...brandsData,
                        description_ar: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>
            </form>
          </AddModal>
        </div>

        <EditModal
          isLoading={isLoading}
          onOk={handleEdit}
          title="Edit Brand"
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
        >
          <form onSubmit={handleEdit} className="modal_form">
            <div className="inputs_container">
              <div className="input_group">
                <label>English Brand Name</label>
                <input
                  type="text"
                  value={rowData.name_en}
                  placeholder="English Brand Name"
                  onChange={(e) =>
                    setRowData({ ...rowData, name_en: e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Arabic Brand Name</label>
                <input
                  type="text"
                  placeholder="Arabic Brand Name"
                  value={rowData.name_ar}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_ar: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="input_group">
              <label>Brand Color</label>
              <input
                type="color"
                value={rowData.color}
                onChange={(e) => {
                  setRowData({ ...rowData, color: e.target.value });
                }}
              />
            </div>
            <div className="input_group">
              <label>Brand Hover Color</label>
              <input
                type="color"
                value={rowData.hover_color}
                onChange={(e) =>
                  setRowData({ ...rowData, hover_color: e.target.value })
                }
              />
            </div>
            <div className="input_group">
              <label>Brand Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>
            <div className="input_group">
              <label>Brand Small Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setSmallImg(e.target.files[0])}
              />
            </div>

            <div className="input_group">
              <label>Brand Main Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setMainImg(e.target.files[0])}
              />
            </div>

            <div className="input_group">
              <label>Brand Small Main Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setSmallMainImg(e.target.files[0])}
              />
            </div>
            <div className="inputs_container">
              <div className="input_group">
                <label>English Description</label>
                <textarea
                  placeholder="English Description"
                  value={rowData.description_en}
                  onChange={(e) =>
                    setRowData({ ...rowData, description_en: e.target.value })
                  }
                ></textarea>
              </div>

              <div className="input_group">
                <label>Arabic Description</label>
                <textarea
                  placeholder="Arabic Description"
                  value={rowData.description_ar}
                  onChange={(e) =>
                    setRowData({ ...rowData, description_ar: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
            <div className="input_group">
              <label>brand text english</label>

              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={rowData?.brand_text_en}
                onChange={(e) => {
                  return setRowData({
                    ...rowData,
                    brand_text_en: e,
                  });
                }}
                style={{
                  minHeight: "100px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div className="input_group">
              <label>brand text arabic</label>

              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={rowData?.brand_text_ar}
                onChange={(e) => {
                  return setRowData({
                    ...rowData,
                    brand_text_ar: e,
                  });
                }}
                style={{
                  minHeight: "100px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </div>
          </form>
        </EditModal>

        <ShowStatusModal
          isHidden={showStatus}
          isLoading={isLoading}
          isModalOpen={isShowStatus}
          setIsModalOpen={setIsShowStatus}
          onOk={handleUpdateStatus}
        />
        <Table headers={headers} body={allBrands} />
      </div>
    </DefaultLayout>
  );
}
