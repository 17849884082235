import { useParams } from "react-router-dom";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";

export default function Countries() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const {continentId} = useParams();
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
    const [allCountries , setAllCountries] = useState([]);
    const [countriesData , setCountriesData] = useState({
        name_ar:"",
        name_en:"",
        continent_id: continentId
    })
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"English Name",
            dataIndex:"name_en",
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button className="edit_btn" onClick={() => {
              setIsModalEditOpen(true);
              setRowData(row);
            }}>Edit</button>
          {row.hidden == 0 ? (
           <button className="show_btn" onClick={() => {
            console.log(row.hidden);
            setIsShowStatus(true);
            setShowStatus(false);
            setStatus(row.id);
          }}>Hide</button>
          ) : (
           <button className="show_btn" onClick={() => {
            console.log(row.hidden);
            setIsShowStatus(true);
            setShowStatus(true);
            setStatus(row.id);
          }}>Show</button>
          )}
          
        </div>
        );
      },
        }
    ]

    function handleGetAllCountries() {
       setIsLoading(true);
       axios.get(BASE_URL + `/contries/get_for_admin?token=${token}`)
       .then(res => {
        if(res.data.status == "success") {
           setAllCountries(res.data.result)
        }else if(res.data.status == "faild") {
           toast.error(res.data.message);
        }else {
           toast.error("حدث خطأ ما")
        }
       }).finally(() => {
        setIsLoading(false);
       }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllCountries();
    } , [])

    function handleSubmit(e)  {
        e.preventDefault();
        const  data_send = {
            ...countriesData ,
            name_ar : countriesData.name_en,
        }
        setIsLoading(true);
        axios.post(BASE_URL + `/contries/add_new?token=${token}`, data_send)
        .then(res => {
            if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllCountries();
              setCountriesData({
                name_ar:"",
                name_en:""
              })
            }else if(res.data.status == "faild") {
              toast.error(res.data.message);
            }else {
              toast.error("حدث خطأ ما")
            }
        }).finally(() => {
            setIsLoading(false);
           setIsModalAddOpen(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        const data_send = {
            ...rowData , 
            name_ar : rowData.name_en,
            continent_id: continentId
        }
        setIsLoading(true);
        axios.post(BASE_URL + `/contries/update_one/${rowData.id}?token=${token}`,data_send)
        .then(res => {
            if(res.data.status == "success") {
               toast.success(res.data.message);
               handleGetAllCountries();
            }else if(res.data.status == "faild") {
               toast.error(res.data.message);
            }else {
               toast.error("حدث خطأ ما");
            }
        }).finally(() => {
            setIsLoading(false);
            setIsModalEditOpen(false);
        }).catch(e => console.log(e))
    }

    function handleUpdateStatus() {
      setIsLoading(true);
        axios
          .get(BASE_URL + `/contries/update_status/${status}?token=${token}`)
          .then((res) => {
            console.log(res);
            if (res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllCountries();
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          }).finally(() => {
            setIsLoading(false);
            setIsShowStatus(false);
          })
          .catch((e) => console.log(e));
      }
  return (
    <DefaultLayout>
        <div style={{margin :"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Countries"/>
                <AddModal onOk={handleSubmit} isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen} title="Add Countries">
                    <form onSubmit={handleSubmit} className="modal_form">
                            <div className="input_group">
                                <label>English Name</label>
                                <input type="text" placeholder="English Name" value={countriesData.name_en} onChange={(e) => setCountriesData({...countriesData , name_en : e.target.value})}/>
                            </div>

                    </form>
                </AddModal>
            </div>
            <EditModal onOk={handleEdit} isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen} title="Edit Countries">
                <form onSubmit={handleEdit} className="modal_form">
                            <div className="input_group">
                                <label>English Name</label>
                                <input type="text" placeholder="English Name" value={rowData.name_en} onChange={(e) => setRowData({...rowData , name_en : e.target.value})}/>
                            </div>
                </form>
            </EditModal>

            <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
            <Table headers={headers} body={allCountries}/>
        </div>
    </DefaultLayout>
  )
}
