import React, { Fragment } from "react";
import "./style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  LoggedIn,
  fakeCredintials,
  loggedInData,
} from "../../data/fakeLoginData";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../data";

function Login() {
  const [isLoading , setIsLoading] = useState(false);
  const [userData , setUserData] = useState({
    email:"",
    password:"",
  })
  
  function handleSubmit(e) {
    e.preventDefault();
    const data_send = {
      ...userData
    }
    setIsLoading(true);
    axios.post(BASE_URL + `/user/admin_login` , data_send)
    .then(res => {
      if(res.data.status == "success"){
         toast.success("تم التسجيل بنجاح");
         localStorage.setItem("oroubaUsers" , JSON.stringify(res.data.result));
         window.location.reload();
      }else if(res.data.status == "faild") { 
         toast.error(res.data.message);
      }else { 
         toast.error("حدث خطأ ما");
      }
    }).finally(() => {
      setIsLoading(false);
    }).catch(e => console.log(e))
  } 

  return (
    <Fragment>
      <div className="authContainer">
        <div className="authFunction">
          <form className="modal_form"  onSubmit={handleSubmit}>
            <div className="input_group">
              <label htmlFor="email">Email</label>
              <input type="text" id="email" name="email" onChange={(e) => setUserData({...userData , email : e.target.value})}/>
            </div>
            <div className="input_group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" onChange={(e) => setUserData({...userData , password : e.target.value})} />
            </div>

            <button className="btn btn-success" disabled={isLoading}>{isLoading ?  "Loading..." : "Login"}</button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
