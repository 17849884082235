import { useEffect, useState } from "react";
import ContentNav from "../../../datanavcontent";
import DefaultLayout from "../../../layout/defaultlayout";
import AddModal from '../../AddModal/AddModal';
import EditModal from '../../EditModal/EditModal';
import ShowStatusModal from '../../ShowStatusModal/ShowStatusModal';
import Table from "../../table";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../data";
import { toast } from "react-toastify";


export default function BrandsCategories() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const {categoryId} = useParams();
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [status , setStatus] = useState(null);
    const [video , setVideo] = useState(null);
    const [product_id, set_product_id] = useState({});
    const [loader, setLoader] = useState(false);
    const [currentNumber, setCurrentNumber] = useState(null);
    const [productData, setProductData] = useState({});
    const [img , setImg] = useState(null);
    const [allBrandsCategories , setAllBrandsCategories] = useState([])
    const [brandsCategoriesData , setBrandsCategoriesData] = useState({
        name_ar:"",
        name_en:"",
        image:"",
        number:1,
    })
    const [rowData , setRowData] = useState({});

    const  headers =
    [
        {
            label:"#",
            dataIndex:"id",
        },
        {
          label: "Number",
          dataIndex: "number",
          search: true,
          sort: true,
          type: "children",
          children: ({ headers, row }) => {
            const handleBlur = async (product_id, product_number) => {
              set_product_id(null);
              setArrangeNumber(product_id, product_number);
            };

            const handleFocus = () => {
              setCurrentNumber(row?.number);
              set_product_id(row?.id);
            };

            const handleClick = () => {
              set_product_id(row?.id);
            };

            return (
              <div
                style={{ width: "fit-content", cursor: "pointer" }}
                onClick={handleClick}
              >
                <input
                  style={{ width: "120px", cursor: "pointer" }}
                  type="text"
                  onChange={(e) =>
                    setProductData({ id: row?.id, number: e.target.value })
                  }
                  onFocus={() => handleFocus()}
                  defaultValue={
                    productData?.id == row?.id ? productData?.number : row?.number
                  }
                  value={
                    productData?.id == row?.id ? productData?.number : row?.number
                  }
                  disabled={loader}
                  className={
                    product_id === row?.id
                      ? "MR_input_form_element active"
                      : "MR_input_form_element lazy"
                  }
                  onBlur={async (e) => {
                    await handleBlur(row?.id, e.target.value);
                  }}
                />
              </div>
            );
          },
        },
        {
            label:"Image",
            dataIndex:"image",
            type:"children",
            children : ({row}) => {
                return (
                    <img src={row.image} alt={row.name_ar}/>
                )
            }
        },
        {
            label:"ُEnglish Name",
            dataIndex:"name_en",
        },
        {
            label:"Arabic Name",
            dataIndex:"name_ar",
        },
        {
            label:"",
            type:"children",
            children: ({ row }) => {
                return (
                    <div className="icons-container">
                    <button className="edit_btn" onClick={() => {
                     setIsModalEditOpen(true);
                     setRowData(row);
                    }}>Edit</button>
                    {row.hidden == 0 ? (
                              <button className="show_btn"
                              onClick={() => {
                                setIsShowStatus(true);
                                setShowStatus(false);
                                setStatus(row.id);
                              }}>Hide</button>
                             ) : (
                              <button className="show_btn" onClick={() => {
                               setIsShowStatus(true);
                               setShowStatus(true);
                               setStatus(row.id);
                             }}>Show</button>
                             )}
                 </div>
                );
              },
        }
    ];


  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send={
      number:video_number
    }
    await axios.post(BASE_URL+`/categories/change_number/${video_id}`,data_send)
    .then((res)=>{
      console.log(res)
      if(res.data.status=='success'){
        toast.success(res.data.message)
        handleGetAllBrandsCategories();
      }
      else if(res.data.status=='faild'){
        toast.error(res.data.message)
      }
      else {
        toast.error("something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setLoader(false);
    })
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };

    function handleGetAllBrandsCategories() {
        setIsLoading(true);
        axios.get(BASE_URL + `/categories/get_by_brand_admin_id/${categoryId}?token=${token}` )
        .then(res => {
            if(res.data.status == "success") {
                console.log(res.data.result);
               setAllBrandsCategories(res.data.result);
            }else if(res.data.status == "faild") {
                toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllBrandsCategories();
    } , [])


    function handleSubmit(e) {
        e.preventDefault();
        if(img == null) {
           toast.warn("ادخل الصورة أولا");
           return;
        }
        const formData = new FormData();
        formData.append("image" , img) ;
        setIsLoading(true);

        axios.post(BASE_URL + "/file_upload", formData)
        .then((res) => {
            if(res.data.status == "success") {
                const data_send = {
                    ...brandsCategoriesData ,
                    image : res.data.result.image,
                    brand_id : categoryId,
                    name_ar : brandsCategoriesData.name_ar,
                }
                axios.post(BASE_URL + `/categories/add_new?token=${token}` , data_send)
                .then(res => {
                    if(res.data.status == "success") {
                       toast.success(res.data.message);
                       handleGetAllBrandsCategories();
                       setImg(null);
                       setBrandsCategoriesData({
                        name_ar:"",
                        name_en:"",
                        image:"",
                        number:1,
                       })
                    }else if(res.data.status == "faild") {
                        toast.error(res.data.message);
                    }else {
                        toast.error("حدث خطأ ما")
                    }
                }).finally(() => {
                    setIsLoading(false);
                    setIsModalAddOpen(false);
                }).catch(e =>  console.log(e))
            }else if(res.data.status == "faild") {
                   toast.error(res.data.message)
            }else {
                toast.error("حدث خطأ ما")
            }
        }).finally(() =>  {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        if(img == null) {
            const data_send = {
                ...rowData ,
                name_ar : rowData.name_ar,
                image : rowData.image,
                brand_id:categoryId,
            }
            axios.post(BASE_URL + `/categories/update_one/${rowData.id}?token=${token}` , data_send)
            .then(res => {
                if(res.data.status == "success") {
                    toast.success(res.data.message);
                    handleGetAllBrandsCategories();
                }else if(res.data.status == "faild") {
                    toast.error(res.data.message)
                }else {
                    toast.error("حدث خطأ ما")
                }
            }).finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
            }).catch(e => console.log(e))
        }else {
            const formData = new FormData();
            formData.append("image", img);
            axios.post(BASE_URL + "/file_upload" ,formData)
            .then(res => {
                if(res.data.status == "success") {
                      const data_send = {
                        ...rowData ,
                        image : res.data.result.image,
                        brand_id:categoryId,
                      }

                      axios.post(BASE_URL + `/categories/update_one/${rowData.id}?token=${token}` , data_send)
                      .then(res => {
                        if(res.data.status=="success") {
                            toast.success(res.data.message);
                            handleGetAllBrandsCategories();
                        }else if(res.data.status =="faild") {
                            toast.error(res.data.message)
                        }else {
                            toast.error("حدث خطأ ما")
                        }
                      }).finally(() => {
                        setIsLoading(false);
                        setIsModalEditOpen(false);
                      }).catch(e => console.log(e));
                }else if(res.data.status == "faild") {
                    toast.error(res.data.message)
                }else {
                    toast.error("حدث خطأ ما")
                }
            }).finally(() => {
                setIsLoading(false);
            }).catch(e => console.log(e))
        }
    }

    function handleUpdateStatus() {
        setIsLoading(true);
        axios
      .get(BASE_URL + `/categories/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllBrandsCategories();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      }).finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
    }

    return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Brands Categories"/>
                <AddModal  onOk={handleSubmit} title="Add Category" isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen}>
                    <form onSubmit={handleSubmit} className="modal_form">
                        <div className="input_group">
                            <label>Number</label>
                            <input type="text" placeholder="Number" onChange={(e) => setBrandsCategoriesData({...brandsCategoriesData , number:e.target.value})}/>
                        </div>
                        <div className="input_group">
                            <label>English Category Name</label>
                            <input type="text" placeholder="English Category Name" onChange={(e) => setBrandsCategoriesData({...brandsCategoriesData , name_en:e.target.value})}/>
                        </div>
                        <div className="input_group">
                            <label>Arabic Category Name</label>
                            <input type="text" placeholder="Arabic Category Name" onChange={(e) => setBrandsCategoriesData({...brandsCategoriesData , name_ar:e.target.value})}/>
                        </div>

                       <div className="input_group">
                        <label>Image</label>
                        <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                       </div>
                    </form>
                </AddModal>
            </div>

            <EditModal onOk={handleEdit} title="Edit Category" isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
            <form onSubmit={handleEdit} className="modal_form">
                        <div className="input_group">
                            <label>English Category Name</label>
                            <input type="text" value={rowData.name_en} placeholder="English Category Name" onChange={(e) => setRowData({...rowData , name_en:e.target.value})}/>
                        </div>
                        <div className="input_group">
                            <label>Arabic Category Name</label>
                            <input type="text" value={rowData.name_ar} placeholder="Arabic Category Name" onChange={(e) => setRowData({...rowData , name_ar:e.target.value})}/>
                        </div>

                       <div className="input_group">
                        <label>Image</label>
                        <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                       </div>
                    </form>
            </EditModal>
            <ShowStatusModal isHidden={showStatus} isLoading={isLoading} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus} onOk={handleUpdateStatus}/>
            <Table headers={headers} body={allBrandsCategories}/>
        </div>
    </DefaultLayout>
  )
}
