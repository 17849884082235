import React, { useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import Table from "../../components/table";
import "./style.css";

function Home() {
  const [isModalAddOpen , setIsModalAddOpen] = useState(false);
  const [isModalEditOpen , setIsModalEditOpen] = useState(false);
  const headers = [
    {
      label:"#",
      dataIndex:"id",
    },
    {
      label:"عنوان"
    }
  ]
  return (
    <DefaultLayout>
      <div style={{margin:"20px"}}>
        <div className="page_title_container">
        <ContentNav head="Home"/>
          <AddModal title="Add" isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen}>
             <form className="modal_form">
                 <div className="input_group">

                 </div>
             </form>
          </AddModal>
        </div>

        <Table />
      </div>
    </DefaultLayout>
  );
}

export default Home;
