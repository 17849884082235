import { useParams } from "react-router-dom";
import ContentNav from "../../../datanavcontent";
import DefaultLayout from "../../../layout/defaultlayout";
import AddModal from '../../../components/AddModal/AddModal';
import EditModal from '../../../components/EditModal/EditModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Table from "../../../components/table";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../data";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

export default function CookingSteps() {
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const { cookId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState(null);
  const [allSteps, setAllSteps] = useState([]);
  const [stepsData, setStepsData] = useState({
    step_ar: "",
    step_en: "",
  });
  const [rowData, setRowData] = useState({});
  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "step_en",
      dataIndex: "step_en",
    },
    {
      label: "step_ar",
      dataIndex: "step_ar",
    },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button
              className="edit_btn"
              onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}
            >
              Edit
            </button>

            <button
              className="delete_btn"
              onClick={() => {
                setIsModalDeleteOpen(true);
                setDelItem(row.id);
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  function handleGetAllSteps() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/cooks/get_cook_steps/${cookId}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllSteps(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllSteps();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const data_send = {
      steps: `${stepsData.step_ar}**oroba**${stepsData.step_en}`,
    };
    setIsLoading(true);
    axios
      .post(
        BASE_URL + `/cooks/add_cook_steps/${cookId}?token=${token}`,
        data_send
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllSteps();
          setStepsData({
            step_ar: "",
            step_en: "",
          });
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalAddOpen(false);
      })
      .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    const data_send = {
      steps: `${rowData.step_ar}**oroba**${rowData.step_en}`,
    };
    setIsLoading(true);
    axios
      .post(
        BASE_URL + `/cooks/update_cook_steps/${rowData.id}?token=${token}`,
        data_send
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllSteps();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalEditOpen(false);
      })
      .catch((e) => console.log(e));
  }

  function handleDelete() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/cooks/del_cook_step/${delItem}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllSteps();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalDeleteOpen(false);
      })
      .catch((e) => console.log(e));
  }
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Cooking Steps" />
          <AddModal
            onOk={handleSubmit}
            isLoading={isLoading}
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
            title="Add Cooking Steps"
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="input_group">
                <label>English Step</label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={stepsData?.step_en}
                  onChange={(e) =>
                    setStepsData({ ...stepsData, step_en: e })
                  }
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>

              <div className="input_group">
                <label>Arabic Step</label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={stepsData?.step_ar}
                  onChange={(e) =>
                    setStepsData({ ...stepsData, step_ar: e})
                  }
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </form>
          </AddModal>
        </div>
        <EditModal
          onOk={handleEdit}
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          title="Edit Cooking Steps"
        >
          <form onSubmit={handleEdit} className="modal_form">

          <div className="input_group">
                <label>English Step</label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.step_en}
                  onChange={(e) =>
                    setRowData({ ...rowData, step_en: e })
                  }
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>

              <div className="input_group">
                <label>Arabic Step</label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.step_ar}
                  onChange={(e) =>
                    setRowData({ ...rowData, step_ar: e })
                  }
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
           
          </form>
        </EditModal>
        <DeleteModal
          isModalOpen={isModalDeleteOpen}
          setIsModalOpen={setIsModalDeleteOpen}
          onOk={handleDelete}
        />
        <Table headers={headers} body={allSteps} />
      </div>
    </DefaultLayout>
  );
}
