import React, { Fragment, useEffect, useState } from "react";
import { SideNavData } from "../sidenav/sidenavdataenglish";
import { NavLink, Navigate } from "react-router-dom";
import "./style.css";
import { Icon } from "@iconify/react";
import axios from "axios";
function SideNavbar({ active, setActive }) {
  return (
    <Fragment>
      <aside className={active ? "active" : ""}>
        <div className="list-close" onClick={() => setActive(false)}>
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1693603266/close_bphmao.png"
            alt=""
            width={"20px"}

          />
        </div>
        {/* <div className="logo-ic">   
          <img
            src=""
            alt=""
          />

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div> */}
        <div className="div_header" style={{margin:"auto"}}>
        <div className="list-toggle">
          <img src="/OROUBA_Logo_2016-removebg-preview 1.png" alt="orouba logo"/>
        </div>
      </div>

        <div className="links">
          {SideNavData.map((item, index) => {
            return (
              <NavLink
                to={item.path}
                onClick={() => setActive(false)}
                key={index}
              >
              {item.icon}
                <em>{item.label}</em>
              </NavLink>
            );
          })}
        </div>
      </aside>
    </Fragment>
  );
}

export default SideNavbar;
