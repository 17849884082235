import React, { useState } from "react";
import { Button } from "antd";
import Modal from "../modal";
const App = ({
  children,
  isModalOpen,
  setIsModalOpen,
  title,
  onOk,
  isLoading,
  setRowData,
}) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        toggle={handleCancel}
        onClose={handleCancel}
        onCancel={handleCancel}
        footer={[]}
      >
        {children}
        <>
          <Button style={{width:'100px'}} onClick={() => handleCancel()}>Cancel</Button>
          <Button style={{width:'100px'}} onClick={onOk} disabled={isLoading}>
            Edit
          </Button>
        </>
      </Modal>
    </>
  );
};
export default App;
