import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Table from "../../components/table";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export default function SocialParentSocials() {

  const {state}=useLocation();
  console.log(state?.parent)

  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatus , setShowStatus] = useState(false);
  const [isShowStatus , setIsShowStatus] = useState(false);
  const [status , setStatus] = useState(null)
  const [allCertificates, setAllCertificates] = useState([]);
  const [img, setImg] = useState(null);
  const [editImg,setEditImg]=useState(null)
  const [editImgUrl,setEditImgUrl]=useState('')
  const [imgUrl,setImgUrl]=useState('')
  const [link,setLink]=useState('')
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "image",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.image} alt="Social image" />;
      },
    },
    {
      label: "Link",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img onClick={()=>{
          window.open(row.link,'_blank')
        }} style={{width:'50px',cursor:'pointer'}} src={require("../../assets/images/link.png")} alt="Social image" />;
      },
    },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            {row.hidden == 0 ? (
              <button className="show_btn" onClick={() => {
                setIsShowStatus(true);
                setShowStatus(false);
                setStatus(row.id);
              }}>Hide</button>
            ) : (
              <button className="show_btn"  onClick={() => {
                setIsShowStatus(true);
                setShowStatus(true);
                setStatus(row.id);
              }}>Show</button>
            )}
            <button className="edit_btn"  onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
                setEditImgUrl(row.image)
              }}>Edit</button>
          </div>
        );
      },
    },
  ];

  function handleGetAllCertificates() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/socials_parent/get_parent_socials/${state?.parent?.id}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.result);
          setAllCertificates(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllCertificates();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (img == null) {
      toast.warn("ادخل الصوره أولا");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", img);
    axios
      .post(BASE_URL + "/file_upload", formData)
      .then((res) => {
        if (res.data.status == "success") {
          const data_send = {
            link,
            image: res.data.result.image,
            social_parent_id:state?.parent?.id
          };
          axios
            .post(
              BASE_URL + `/socials/add_new?token=${token}`,
              data_send
            )
            .then((res) => {
              if (res.data.status == "success") {
                toast.success(res.data.message);
                handleGetAllCertificates();
                setImg(null);
                setImgUrl('')
                setLink("");
              } else if (res.data.status == "faild") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsModalAddOpen(false);
            })
            .catch((e) => console.log(e));
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (editImg == null) {
      const data_send = {
        ...rowData,
        image: rowData.image,
      };

      axios
        .post(
          BASE_URL + `/socials/update_one/${rowData.id}?token=${token}`,
          data_send
        )
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllCertificates();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما ");
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        })
        .catch((e) => console.log(e));
    } else {
      const formData = new FormData();
      formData.append("image", editImg);
      axios
        .post(BASE_URL + "/file_upload", formData)
        .then((res) => {
          if (res.data.status == "success") {
            const data_send = {
              ...rowData,
              image: res.data.result.image,
            };
            axios
              .post(
                BASE_URL +
                  `/socials/update_one/${rowData.id}?token=${token}`,
                data_send
              )
              .then((res) => {
                if (res.data.status == "success") {
                  toast.success(res.data.message);
                  handleGetAllCertificates();
                  setEditImg(null)
                  setEditImgUrl('')
                } else if (res.data.status == "faild") {
                  toast.error(res.data.message);
                } else {
                  toast.error("حدث خطأ ما");
                }
              })
              .finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
              });
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/socials/update_status/${status}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setEditImg(null)
          setEditImgUrl('')
          handleGetAllCertificates();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      }).finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Socials" />
          <AddModal
            onOk={handleSubmit}
            isLoading={isLoading}
            setIsModalOpen={setIsModalAddOpen}
            isModalOpen={isModalAddOpen}
            title="Add Socials In This Product"
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="input_group">
                <label>Social Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => {
                    setImg(e.target.files[0])
                    setImgUrl(URL.createObjectURL(e.target.files[0]))
                  }}
                />
              </div>
              {
                imgUrl!=''&&
                <img src={imgUrl} style={{width:'30px'}} alt="" />
              }
              <div className="input_group">
                <label>Social Link</label>
                <input
                  value={link}
                  type="text"
                  onChange={(e) => {
                    setLink(e.target.value)
                  }}
                />
              </div>
            </form>
          </AddModal>
        </div>
        <EditModal
          onOk={handleEdit}
          isLoading={isLoading}
          setIsModalOpen={setIsModalEditOpen}
          isModalOpen={isModalEditOpen}
          title="Edit Socials"
        >
          <form style={{marginBottom:'10px'}} onSubmit={handleEdit} className="modal_form">
            <div className="input_group">
              <label>Social Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => {
                  setEditImg(e.target.files[0])
                  setEditImgUrl(URL.createObjectURL(e.target.files[0]))
                }}
              />
            </div>
            <div>
              {
                editImgUrl&&<img src={editImgUrl} alt="" />
              }
            </div>
            <div className="input_group">
                <label>Social Link</label>
                <input
                  value={rowData.link}
                  type="text"
                  onChange={(e) => {
                    setRowData({...rowData,link:e.target.value})
                  }}
                />
              </div>

          </form>
        </EditModal>

        <ShowStatusModal onOk={handleUpdateStatus} isHidden={showStatus} isLoading={isLoading} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
        <Table headers={headers} body={allCertificates} />
      </div>
    </DefaultLayout>
  );
}
