import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";


export default function Features() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen,setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [showStatus , setShowStatus] = useState(false);
    const [isShowStatus , setIsShowStatus] = useState(false);
    const [status , setStatus] = useState(null)
    const [img , setImg] = useState(null);
    const [allFeatures , setAllFeatures] = useState([]);
    const [featureData , setFeatureData] = useState({
        description_ar:"",
        description_en:"",
        title_ar:"",
        title_en:"",
        image:"",
    });
    const [rowData , setRowData ] = useState({})

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"Image",
            dataIndex:"image",
            type:"children",
            children : ({row}) => {
               return (
                <img src={row.image} alt={row.title_en}/>
               )
            }
        },
        {
            label:"English Title",
            dataIndex:"title_en",
        },
        {
            label:"Arabic Title",
            dataIndex:"title_ar",
        },
        {
            label:"English Description",
            dataIndex:"description_en",
        },
        {
            label:"Arabic Description",
            dataIndex:"description_ar",
        },
        {
            label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button className="edit_btn" onClick={() => {
              setIsModalEditOpen(true);
              setRowData(row);
            }}>Edit</button>
          {row.hidden == 0 ? (
           <button className="show_btn" onClick={() => {
            console.log(row.hidden);
            setIsShowStatus(true);
            setShowStatus(false);
            setStatus(row.id);
          }}>Hide</button>
          ) : (
           <button className="show_btn" onClick={() => {
            console.log(row.hidden);
            setIsShowStatus(true);
            setShowStatus(true);
            setStatus(row.id);
          }}>Show</button>
          )}
        </div>
        );
      },
        }
    ]

    function handleGetAllFeatures() {
        setIsLoading(true);
        axios.get(BASE_URL + `/features/get_for_admin?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
              setAllFeatures(res.data.result);
            }else if(res.data.status =="faild") {
              toast.error(res.data.message);
            }else {
             toast.error("حدث خطأ ما")
            }
        }).finally(() => {
           setIsLoading(false)
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllFeatures();
    } , [])

    function handleSubmit(e) {
        e.preventDefault();
        if(img == null) {
            toast.warn("ادخل الصورة أولا!");
            return;
        }
        const formData = new FormData();
        formData.append("image", img);
        setIsLoading(true);
        axios.post(BASE_URL + `/file_upload` , formData)
        .then(res => {
            if(res.data.status == "success") {
              const data_send = {
                ...featureData,
                image : res.data.result.image,
                description_ar : featureData.description_ar,
                title_ar : featureData.title_ar,
              }
              axios.post(BASE_URL + `/features/add_new?token=${token}` , data_send)
              .then(res => {
                if(res.data.status == "success") {
                    toast.success(res.data.message);
                    handleGetAllFeatures();
                    setFeatureData({
                        description_ar:"",
                        description_en:"",
                        title_ar:"",
                        title_en:"",
                        image:"",
                    })
                }else if(res.data.status =="faild") {
                    toast.error(res.data.message);
                }else {
                    toast.error("حدث خطأ ما")
                }
              }).finally(() => {
                setIsLoading(false);
                setIsModalAddOpen(false);
              }).catch(e => console.log(e))
            }else if(res.data.status == "faild") {
               toast.error(res.data.message);
            }else {
               toast.error("حدث خطأ ما أثناء رفع الصورة !")
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    function handleEdit(e) {
        e.preventDefault();
        if(img == null) {
            const data_send = {
                ...rowData ,
                image : rowData.image,
                description_ar : rowData.description_ar,
                title_ar:rowData.title_ar
            }
            axios.post(BASE_URL + `/features/update_one/${rowData?.id}?token=${token}` , data_send)
            .then(res => {
                if(res.data.status == "success") {
                   toast.success(res.data.message);
                   handleGetAllFeatures();
                }else if(res.data.status == "faild") {
                   toast.error(res.data.message)
                }else {
                   toast.error("حدث خطأ ما")
                }
            }).finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
            }).catch(e => console.log(e))
        }else {
            const formData = new FormData();
            formData.append("image" , img);
            setIsLoading(true);
            axios.post(BASE_URL + '/file_upload', formData)
            .then(res => {
                if(res.data.status == "success") {
                  const data_send = {
                    ...rowData ,
                    image : res.data.result.image,
                    description_ar : rowData.description_ar,
                    title_ar : rowData.title_ar
                  }
                  axios.post(BASE_URL + `/features/update_one/${rowData.id}?token=${token}` , data_send)
                  .then(res => {
                    if(res.data.status == "success") {
                      toast.success(res.data.message);
                      handleGetAllFeatures();
                    }else if(res.data.status == "faild") {
                      toast.error(res.data.message);
                    }else {
                      toast.error("حدث خطأ ما");
                    }
                  }).finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  }).catch(e => console.log(e))

                }else if(res.data.status == "faild") {
                   toast.error(res.data.message);
                }else {
                   toast.error("حدث خطأ ما")
                }
            }).finally(() => {
               setIsLoading(false);
            }).catch(e => console.log(e))
        }
    }

    function handleUpdateStatus() {
      setIsLoading(true);
      axios
        .get(BASE_URL + `/features/update_status/${status}?token=${token}`)
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllFeatures();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        }).finally(() => {
          setIsLoading(false);
          setIsShowStatus(false);
        })
        .catch((e) => console.log(e));
    }

  return (
    <DefaultLayout>
        <div style={{margin :"20px"}}>
            <div className="page_title_container">
                <ContentNav  head="Features"/>
                <AddModal onOk={handleSubmit} title="Add Feature" isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen}>
                    <form onSubmit={handleSubmit} className="modal_form">
                         <div className="input_group">
                            <label>English Title</label>
                            <input type="text" placeholder="English Title" value={featureData.title_en} onChange={(e) => setFeatureData({...featureData , title_en : e.target.value})}/>
                        </div>
                         <div className="input_group">
                            <label>Arabic Title</label>
                            <input type="text" placeholder="Arabic Title" value={featureData.title_ar} onChange={(e) => setFeatureData({...featureData , title_ar : e.target.value})}/>
                        </div>

                         <div className="input_group">
                            <label>English Description</label>
                            <input type="text" placeholder="English Description" value={featureData.description_en} onChange={(e) => setFeatureData({...featureData , description_en : e.target.value})}/>
                        </div>

                         <div className="input_group">
                            <label>Arabic Description</label>
                            <input type="text" placeholder="Arabic Description" value={featureData.description_ar} onChange={(e) => setFeatureData({...featureData , description_ar : e.target.value})}/>
                        </div>


                         <div className="input_group">
                            <label>Image</label>
                            <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                         </div>
                    </form>
                </AddModal>
            </div>
            <EditModal onOk={handleEdit} title="Edit Feature" isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}>
              <form onSubmit={handleEdit} className="modal_form">
                         <div className="input_group">
                            <label>English Title</label>
                            <input type="text" placeholder="English Title" value={rowData.title_en} onChange={(e) => setRowData({...rowData , title_en : e.target.value})}/>
                        </div>
                         <div className="input_group">
                            <label>Arabic Title</label>
                            <input type="text" placeholder="Arabic Title" value={rowData.title_ar} onChange={(e) => setRowData({...rowData , title_ar : e.target.value})}/>
                        </div>

                         <div className="input_group">
                            <label>English Description</label>
                            <input type="text" placeholder="English Description" value={rowData.description_en} onChange={(e) => setRowData({...rowData , description_en : e.target.value})}/>
                        </div>

                         <div className="input_group">
                            <label>Arabic Description</label>
                            <input type="text" placeholder="Arabic Description" value={rowData.description_ar} onChange={(e) => setRowData({...rowData , description_ar : e.target.value})}/>
                        </div>

                         <div className="input_group">
                            <label>Image</label>
                            <input type="file" accept="images/*" onChange={(e) => setImg(e.target.files[0])}/>
                         </div>
                    </form>
            </EditModal>
            <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
            <Table headers={headers} body={allFeatures}/>
        </div>
    </DefaultLayout>
  )
}
