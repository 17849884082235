import { useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import Table from "../../components/table";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import styles from "./Banners.module.css";
import ContentNav from "../../datanavcontent";
import { colors } from "@mui/material";
import axios, { Axios } from "axios";
import { BASE_URL } from "../../data";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "antd";

export default function Banners() {
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [status, setStatus] = useState(null)
  const [allBannars, setAllBannars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoAr, setVideoAr] = useState(null);
  const [editImg, setEditImg] = useState(null)
  const [videoSmallEdit, setVideoSmallEdit] = useState(null)
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [smallBannerImg, setSmallBannerImg] = useState(null);
  const [smallBannerVideo, setSmallBannerVideo] = useState(null);
  const [smallBannerVideoAr, setSmallBannerVideoAr] = useState(null);
  const [bannerData, setBannerData] = useState({
    title_ar: "",
    title_en: "",
    video_link: "",
    number: 1,
    image: "",
    type: "image",
  });
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "English Title",
      dataIndex: "title_en",
    },
    {
      label: "Arabic Title",
      dataIndex: "title_ar",
    },
    {
      label: "Video Link ( English )",
      dataIndex: "video_link",
      type: "children",
      children: ({ row }) => {
        return (
          <a href={row.video_link} target="_blank">
            {row.video_link}
          </a>
        );
      },
    },
    {
      label: "Video Link ( Arabic )",
      dataIndex: "video_link",
      type: "children",
      children: ({ row }) => {
        return (
          <a href={row?.video_link_en} target="_blank">
            {row?.video_link_en}
          </a>
        );
      },
    },
    {
      label: "Small Video Link ( English )",
      dataIndex: "video_link",
      type: "children",
      children: ({ row }) => {
        return (
          <a href={row.small_video} target="_blank">
            {row.small_video}
          </a>
        );
      },
    },
    {
      label: "Small Video Link ( Arabic )",
      dataIndex: "video_link",
      type: "children",
      children: ({ row }) => {
        return (
          <a href={row?.small_video_en} target="_blank">
            {row?.small_video_en}
          </a>
        );
      },
    },
    {
      label: "Image File ( English )",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.image} alt={row.title} />;
      },
    },
    {
      label: "Image File ( Arabic ) ",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.image} alt={row.title} />;
      },
    },
    {
      label: "Small Image File ( English )",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.small_img} alt={row.title} />;
      },
    },
    {
      label: "Small Image ( Arabic )",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.small_img_en} alt={row.title} />;
      },
    },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button className="edit_btn" onClick={() => {
              setIsModalEditOpen(true);
              setRowData(row);
            }}>Edit</button>
            {row.hidden == 0 ? (
              <button className="show_btn" onClick={() => {
                setIsShowStatus(true);
                setStatus(row.id)
                setShowStatus(false);
              }}>Hide</button>

            ) : (
              <button className="show_btn" onClick={() => {
                setIsShowStatus(true);
                setStatus(row.id)
                setShowStatus(true);
              }}>Show</button>
            )}
          </div>
        );
      },
    },
  ];



  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send = {
      number: video_number
    }
    await axios.post(BASE_URL + `/banners/change_number/${video_id}`, data_send)
      .then((res) => {
        console.log(res)
        if (res.data.status == 'success') {
          toast.success(res.data.message)
          handleGetAllBanners();
        }
        else if (res.data.status == 'faild') {
          toast.error(res.data.message)
        }
        else {
          toast.error("something Went Error")
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setLoader(false);
      })
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };

  function handleGetAllBanners() {
    axios
      .get(BASE_URL + `/banners/get_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.result);
          setAllBannars(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      });
  }

  useEffect(() => {
    handleGetAllBanners();
    console.log(allBannars);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (bannerData.type == "image") {
      if (img == null) {
        toast.warn("ادخل صوره أولا");
        return;
      }
      if (smallBannerImg == null) {
        toast.warn('أدخل صوره للبانر صغيره')
        return;
      }
      const formData = new FormData();
      formData.append("image", img);
      setIsLoading(true);

      axios
        .post(BASE_URL + "/file_upload", formData)
        .then((res) => {
          if (res.data.status == "success") {
            const smallBanImg = new FormData();
            smallBanImg.append('image', smallBannerImg)
            axios.post(BASE_URL + "/file_upload", smallBanImg)
              .then((resSmall) => {
                if (resSmall.data.status == 'success') {
                  const data_send = {
                    ...bannerData,
                    title_ar: bannerData.title_ar,
                    image: res.data.result.image,
                    small_img: resSmall.data.result.image,
                  };
                  console.log(data_send);
                  if (data_send.title_en == "") {
                    toast.warn("ادخل العنوان بالانجليزي أولا");
                    return;
                  }
                  if (data_send.type == "") {
                    toast.warn("ادخل نوع البانر أولا");
                    return;
                  }

                  axios
                    .post(BASE_URL + `/banners/add_new?token=${token}`, data_send)
                    .then((res) => {
                      console.log(res);
                      if (res.data.status == "success") {
                        toast.success(res.data.message);
                        handleGetAllBanners();
                        setBannerData({
                          title_ar: "",
                          title_en: "",
                          type: "image",
                          image: "",
                          number: 1,
                          video_link: "",
                        });
                      } else if (res.data.status == "faild") {
                        toast.error(res.data.message);
                      } else {
                        toast.error(res.data.message);
                      }
                    })
                    .finally(() => {
                      setIsLoading(false);
                      setIsModalAddOpen(false);
                    })
                    .catch((e) => console.log(e));
                }
              })
              .catch(e => console.log(e))
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      if (video == null) {
        toast.warn("ادخل فيديو أولا");
        return;
      }
      if (smallBannerVideo == null) {
        toast.warn('أدخل فديو للشاشه الصغيره')
        return
      }
      const formData = new FormData();
      formData.append("image", video);
      setIsLoading(true);

      axios
        .post(BASE_URL + "/file_upload", formData)
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            const smallVidBan = new FormData();
            smallVidBan.append('image', smallBannerVideo)
            axios.post(BASE_URL + "/file_upload", smallVidBan)
              .then((resSmall) => {
                if (resSmall.data.status == 'success') {
                  const data_send = {
                    ...bannerData,
                    title_ar: bannerData.title_ar,
                    video_link: res.data.result.image,
                    small_video: resSmall.data.result.image,

                  };

                  if (data_send.title_ar == "") {
                    toast.warn("ادخل عنوان بالعربي أولا");
                    return;
                  }
                  if (data_send.title_en == "") {
                    toast.warn("ادخل العنوان بالانجليزي أولا");
                    return;
                  }
                  if (data_send.type == "") {
                    toast.warn("ادخل نوع البانر أولا");
                    return;
                  }
                  axios
                    .post(BASE_URL + `/banners/add_new?token=${token}`, data_send)
                    .then((res) => {
                      console.log(res);
                      if (res.data.status == "success") {
                        toast.success(res.data.message);
                        handleGetAllBanners();
                        setBannerData({
                          title_ar: "",
                          title_en: "",
                          type: "image",
                          image: "",
                          number: 1,
                          video_link: "",
                        });
                      } else if (res.data.status == "faild") {
                        toast.error(res.data.message);
                      } else {
                        toast.error(res.data.message);
                      }
                    })
                    .finally(() => {
                      setIsLoading(false);
                      setIsModalAddOpen(false);
                    })
                    .catch((e) => console.log(e));
                }
              })
              .catch(e => console.log(e))
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleEdit(e) {
    e.preventDefault();
    setIsLoading(true);

    if (rowData.type == "image") {
      if (img == null) {
        if (editImg == null) {
          const data_send = {
            ...rowData,
            title_en: rowData.title_en,
            image: rowData.image,
          };

          axios
            .post(BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`, data_send)
            .then((res) => {
              console.log(res);
              if (res.data.status == "success") {
                toast.success(res.data.message);
                handleGetAllBanners();
              } else if (res.data.status == "faild") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsModalEditOpen(false);
            })
            .catch((e) => console.log(e));
        }
        else {
          const imgEditSmallForm = new FormData();
          imgEditSmallForm.append('image', editImg)
          axios.post(BASE_URL + "/file_upload", imgEditSmallForm)
            .then((resEd) => {
              if (resEd.data.status == 'success') {
                const data_send = {
                  ...rowData,
                  title_en: rowData.title_en,
                  image: rowData.image,
                  small_img: resEd.data.result.image
                };

                axios
                  .post(BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`, data_send)
                  .then((res) => {
                    console.log(res);
                    if (res.data.status == "success") {
                      toast.success(res.data.message);
                      handleGetAllBanners();
                    } else if (res.data.status == "faild") {
                      toast.error(res.data.message);
                    } else {
                      toast.error("حدث خطأ ما");
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  })
                  .catch((e) => console.log(e));
              }
            })
            .catch(e => console.log(e))
        }
      } else {
        const formData = new FormData();
        formData.append("image", img);
        axios
          .post(BASE_URL + "/file_upload", formData)
          .then((res) => {
            if (res.data.status == "success") {
              if (editImg == null) {
                const data_send = {
                  ...rowData,
                  image: res.data.result.image,
                };
                axios
                  .post(
                    BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`,
                    data_send
                  )
                  .then((res) => {
                    if (res.data.status == "success") {
                      toast.success(res.data.message);
                      handleGetAllBanners();
                    } else if (res.data.status == "faild") {
                      toast.error(res.data.message);
                    } else {
                      toast.error("حدث خطأ ما");
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  })
                  .catch((e) => console.log(e));
              }
              else {
                const smImgEdForm = new FormData()
                smImgEdForm.append('image', editImg)
                axios.post(BASE_URL + "/file_upload", smImgEdForm)
                  .then((resEd) => {
                    if (resEd.data.status == 'success') {
                      const data_send = {
                        ...rowData,
                        image: res.data.result.image,
                        small_img: resEd.data.result.image
                      };
                      axios
                        .post(
                          BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`,
                          data_send
                        )
                        .then((res) => {
                          if (res.data.status == "success") {
                            toast.success(res.data.message);
                            handleGetAllBanners();
                          } else if (res.data.status == "faild") {
                            toast.error(res.data.message);
                          } else {
                            toast.error("حدث خطأ ما");
                          }
                        })
                        .finally(() => {
                          setIsLoading(false);
                          setIsModalEditOpen(false);
                        })
                        .catch((e) => console.log(e));
                    }
                  })
                  .catch(e => console.log(e))
              }
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          })
          .finally(() => {
            setIsLoading(false);
          })
          .catch((e) => console.log(e));
      }
    } else {
      if (video == null) {
        if (videoSmallEdit != null) {
          const smallVidForm = new FormData()
          smallVidForm.append('image', videoSmallEdit);
          axios.post(BASE_URL + "/file_upload", smallVidForm)
            .then((resSma) => {
              if (resSma.data.status == 'success') {
                const data_send = {
                  ...rowData,
                  video_link: rowData.video_link,
                  small_video: resSma.data.result.image
                };
                axios
                  .post(BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`, data_send)
                  .then((res) => {
                    console.log(res);
                    if (res.data.status == "success") {

                      toast.success(res.data.message);
                      handleGetAllBanners();
                    } else if (res.data.status == "faild") {
                      toast.error(res.data.message);
                    } else {
                      toast.error("حدث خطأ ما");
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  })
                  .catch((e) => console.log(e));
              }
            }).catch(e => console.log(e))
        }
        else {
          const data_send = {
            ...rowData,
            video_link: rowData.video_link,
          };
          axios
            .post(BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`, data_send)
            .then((res) => {
              console.log(res);
              if (res.data.status == "success") {

                toast.success(res.data.message);
                handleGetAllBanners();
              } else if (res.data.status == "faild") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsModalEditOpen(false);
            })
            .catch((e) => console.log(e));
        }
      } else {
        const formData = new FormData();
        formData.append("image", video);
        axios
          .post(BASE_URL + "/file_upload", formData)
          .then((res) => {
            if (res.data.status == "success") {
              if (videoSmallEdit != null) {
                const smallVidForm = new FormData()
                smallVidForm.append('image', videoSmallEdit);
                axios.post(BASE_URL + "/file_upload", smallVidForm)
                  .then((resSma) => {
                    if (resSma.data.status == 'success') {
                      const data_send = {
                        ...rowData,
                        video_link: res.data.result.image,
                        small_video: resSma.data.result.image
                      };
                      axios
                        .post(BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`, data_send)
                        .then((res) => {
                          console.log(res);
                          if (res.data.status == "success") {

                            toast.success(res.data.message);
                            handleGetAllBanners();
                          } else if (res.data.status == "faild") {
                            toast.error(res.data.message);
                          } else {
                            toast.error("حدث خطأ ما");
                          }
                        })
                        .finally(() => {
                          setIsLoading(false);
                          setIsModalEditOpen(false);
                        })
                        .catch((e) => console.log(e));
                    }
                  }).catch(e => console.log(e))
              }
              else {
                const data_send = {
                  ...rowData,
                  title_ar: rowData.title_ar,
                  video_link: res.data.result.image,
                };
                axios
                  .post(
                    BASE_URL + `/banners/update_one/${rowData.id}?token=${token}`,
                    data_send
                  )
                  .then((res) => {
                    if (res.data.status == "success") {
                      toast.success(res.data.message);
                      handleGetAllBanners();
                    } else if (res.data.status == "faild") {
                      toast.error(res.data.message);
                    } else {
                      toast.error("حدث خطأ ما");
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setIsModalEditOpen(false);
                  })
                  .catch((e) => console.log(e));
              }
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          })
          .finally(() => {
            setIsLoading(false);
          })
          .catch((e) => console.log(e));
      }
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/banners/update_status/${status}?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllBanners();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      }).finally(() => {
        setIsLoading(false);
        setIsShowStatus(false)
      })
      .catch((e) => console.log(e));
  }

  return (
    <DefaultLayout>
      <div
        className={`${styles.banners_home_container} banners_home_container`}
      >
        <div className="page_title_container">
          <ContentNav head="Banners" />
          <AddModal
            isLoading={isLoading}
            title="Add Banner"
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
            onOk={handleSubmit}
          >
            <form onSubmit={handleSubmit} className="modal_form">

              <div className="input_group">
                <label>Number</label>
                <input
                  type="text"
                  placeholder="Number"
                  value={bannerData.number}
                  onChange={(e) =>
                    setBannerData({ ...bannerData, number: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>English Title</label>
                <input
                  type="text"
                  placeholder="Eglish Title"
                  value={bannerData.title_en}
                  onChange={(e) =>
                    setBannerData({ ...bannerData, title_en: e.target.value })
                  }
                />
              </div>
              <div className="input_group">
                <label>Arabic Title</label>
                <input
                  type="text"
                  placeholder="Arabic Title"
                  value={bannerData.title_ar}
                  onChange={(e) =>
                    setBannerData({ ...bannerData, title_ar: e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Banner type</label>
                <select
                  value={bannerData.type}
                  onChange={(e) =>
                    setBannerData({ ...bannerData, type: e.target.value })
                  }
                >
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </select>
              </div>

              {bannerData.type == "video" &&
                <div className="input_group">
                  <label>Banner Video Link - Enlgish</label>
                  <input
                    accept="video/*"
                    type="file"
                    placeholder="Banner Video Link"
                    required
                    onChange={(e) =>
                      setVideo(e.target.files[0])
                    }
                  />
                </div>}
              {bannerData.type == "video" &&
                <div className="input_group">
                  <label>Banner Video Link - Arabic</label>
                  <input
                    accept="video/*"
                    type="file"
                    placeholder="Banner Video Link"
                    required
                    onChange={(e) =>
                      setVideoAr(e.target.files[0])
                    }
                  />
                </div>}
              {bannerData.type == "video" &&
                <div className="input_group">
                  <label>Banner Small Video Link - English</label>
                  <input
                    accept="video/*"
                    type="file"
                    placeholder="Banner Small Video Link"
                    required
                    onChange={(e) =>
                      setSmallBannerVideo(e.target.files[0])
                    }
                  />
                </div>}
              {bannerData.type == "video" &&
                <div className="input_group">
                  <label>Banner Small Video Link - Arabic</label>
                  <input
                    accept="video/*"
                    type="file"
                    placeholder="Banner Small Video Link"
                    required
                    onChange={(e) =>
                      setSmallBannerVideoAr(e.target.files[0])
                    }
                  />
                </div>}

              {bannerData.type == "image" && <div className="input_group">
                <label>Banner Image</label>
                <input
                  type="file"
                  required
                  placeholder="Banner Image"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>}
              {bannerData.type == "image" && <div className="input_group">
                <label>Small Banner Image</label>
                <input
                  type="file"
                  required
                  placeholder="Banner Image"
                  onChange={(e) => setSmallBannerImg(e.target.files[0])}
                />
              </div>}
            </form>
          </AddModal>
        </div>

        <EditModal
          isLoading={isLoading}
          title="Edit Banner"
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          onOk={handleEdit}
        >
          <form onSubmit={handleEdit} className="modal_form">
            <div className="input_group">
              <label>English Title</label>
              <input
                type="text"
                placeholder="Eglish Title"
                value={rowData.title_en}
                onChange={(e) =>
                  setRowData({ ...rowData, title_en: e.target.value })
                }
              />
            </div>
            <div className="input_group">
              <label>Arabic Title</label>
              <input
                type="text"
                placeholder="Arabic Title"
                value={rowData.title_ar}
                onChange={(e) =>
                  setRowData({ ...rowData, title_ar: e.target.value })
                }
              />
            </div>



            <div className="input_group">
              <label>Banner type</label>
              <select
                value={rowData.type}
                onChange={(e) =>
                  setRowData({ ...rowData, type: e.target.value })
                }
              >
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
            </div>

            {rowData.type == "video" && <div className="input_group">
              <label>Banner Video Link</label>
              <input
                type="file"
                placeholder="Banner Video Link"
                onChange={(e) =>
                  setVideo(e.target.files[0])
                }
              />
            </div>}
            {rowData.type == "video" && <div className="input_group">
              <label>Banner Small Video Link</label>
              <input
                type="file"
                placeholder="Banner Small Video Link"
                onChange={(e) =>
                  setVideoSmallEdit(e.target.files[0])
                }
              />
            </div>}

            {rowData.type == "image" && <div className="input_group">
              <label>Banner Image</label>
              <input
                type="file"
                placeholder="Banner Image"
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>}
            {rowData.type == "image" && <div className="input_group">
              <label>Banner Small Image</label>
              <input
                type="file"
                placeholder="Banner Small Image"
                onChange={(e) => setEditImg(e.target.files[0])}
              />
            </div>}
          </form>
        </EditModal>

        <ShowStatusModal isHidden={showStatus} isLoading={isLoading} onOk={handleUpdateStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus} />

        <Table headers={headers} body={allBannars} />
      </div>
    </DefaultLayout>
  );
}
