export const fakeCredintials = [
  {
    name: "مكتب الفرقان - لوحة التحكم",
    image:
      "https://res.cloudinary.com/duovxefh6/image/upload/v1693605788/man_lpskqh.png",
    email: "realEstate@admin.com",
    password: "7410",
    type: "admin",
  },
];

export let loggedInData = localStorage.getItem("realestateauthenticatedUser")
  ? JSON.parse(localStorage.getItem("realestateauthenticatedUser"))
  : null;

export const LoggedIn = fakeCredintials?.filter(
  (item) => item.email == loggedInData?.email
)?.length;

loggedInData = fakeCredintials?.filter(
  (item) => item.email == loggedInData?.email
)[0];
