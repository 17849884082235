import { useEffect, useState } from "react";
import Table from "../../components/table";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";

export default function Collaborates() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [allCollaborates , setAllCollaborates] = useState([]);
    
    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"First Name",
            dataIndex :"f_name",
        },
        {
            label:"Last Name",
            dataIndex:"l_name",
        },
        {
            label:"Email",
            dataIndex:"email",
        },
        {
            label:"Phone", 
            dataIndex:"phone"
        },
    ]

    function handleGetAllCollaborates() {
        setIsLoading(true);
        axios.get(BASE_URL + `/collaborates/get_for_admin?token=${token}`)
        .then(res => {
           if(res.data.status == "success") {
              setAllCollaborates(res.data.result);
           }else if(res.data.status == "faild") {
              toast.error(res.data.message);
           }else {
              toast.error("حدث خطأ ما")
           }
        } ).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllCollaborates();
    } , [])

  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Collaborates"/>
            </div>

            <Table  headers={headers} body={allCollaborates}/>
        </div>
    </DefaultLayout>
  )
}
