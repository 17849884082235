import { ToastContainer } from "react-toastify";
import "./App.css";
import RouteComponent from "./routes/index";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Login from "./pages/login";
import { LoggedIn, loggedInData } from "./data/fakeLoginData";
import ErrorBoundary from "./error";

export let userData = loggedInData;

function App() {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"))
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  window.onerror = function (message, source, lineno, colno, error) {
    // Handle the error (e.g., log it to a server, show a custom UI, etc.)
    // alert("kjhg")
    console.error('Global error:', message, source, lineno, colno, error);
  };
  
  window.addEventListener('error', function (event) {
    // Handle the error (e.g., log it to a server, show a custom UI, etc.)
    console.error('Global error event:', event);
  });
  
  return (
    <ErrorBoundary>
    <div className="App">
      {!token ? (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        <RouteComponent />
      )}
      <ToastContainer />
    </div>
    </ErrorBoundary>
  );
}

export default App;
