import { useEffect, useState } from "react";
import Table from "../../components/table";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";

export default function Joins() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [allJoins , setAllJoins] = useState([]);
    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"Name",
            dataIndex:"name",
        },
        {
            label:"Phone",
            dataIndex:"phone"
        },
        {
            label:"Email",
            dataIndex:"email",
            type:"children",
            children : ({row}) => {
              return (
                <a href={row.email}>{row.email}</a>
              )
            }
        },
        {
            label:"position",
            dataIndex:"position"
        }
    ]

    function handleGetAllJoins() {
        setIsLoading(true);
        axios.get(BASE_URL+`/joins/get_all_for_admin?token=${token}`)
        .then(res => {
            console.log(res);
            if(res.data.status == "success") {
                setAllJoins(res.data.result);
            }else if(res.data.status == "faild") {
              toast.error(res.data.message);
            }else {
               toast.error("حدث خطأ ما")
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }
     
    useEffect(() => {
        handleGetAllJoins();
    } , [])

  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                 <ContentNav head="Joins"/>
            </div>

            <Table headers={headers} body={allJoins}/>
        </div>
    </DefaultLayout>
  )
}
