import axios from "axios";
import { BASE_URL } from "../../data";
import styled from "styled-components";

export const truncateText = (text, maxWords = 20) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= maxWords) return text;
    return words.slice(0, maxWords).join(' ') + '...';
};


export const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("image",imageFile)
    const uploadImage = await axios.post(BASE_URL + "/file_upload",formData);
    return uploadImage?.data?.result?.image;
}

// ==============  Styled  ====================
export const FormContainer = styled.div`
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const FormTitle = styled.h2`
    margin-bottom: 20px;
    color: #333;
    text-align: center;
`;

export const FormGroup = styled.div`
    margin-bottom: 15px;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: bold;
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
`;

export const Textarea = styled.textarea`
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
`;

export const Button = styled.button`
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;