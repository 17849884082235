import ContentNav from "../../../datanavcontent";
import DefaultLayout from "../../../layout/defaultlayout";
import AddModal from '../../../components/AddModal/AddModal';
import EditModal from '../../../components/EditModal/EditModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../data";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/table";

export default function ProductsCategories() {
  const { productId } = useParams();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState(null);
  const [allProductsCategories, setAllProductsCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [categoriesData, setCategoriesData] = useState({
    category_id: null,
  });
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },

    {
      label: "Image",
      dataIndex: 'Image',
      type: "children",
      children: ({ row }) => {
        return <img src={row?.category?.image} alt={row?.category?.name_en} />;
      },
    },
    {
      label: "English Name",
      dataIndex: "name_en",
      type: "children",
      children: ({ row }) => {
        return <p>{row?.category?.name_en}</p>;
      },
    },
    {
      label: "Arabic Name",
      dataIndex: "name_ar",
      type: "children",
      children: ({ row }) => {
        return <p>{row?.category?.name_ar}</p>;
      },
    },

    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button
              className="edit_btn"
              onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
              }}
            >
              Edit
            </button>
            <button
              className="delete_btn"
              onClick={() => {
                setIsModalDeleteOpen(true);
                setDelItem(row.id);
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];


  function handleGetAllProductCategories() {
    setIsLoading(true);
    axios
      .get(
        BASE_URL +
          `/products/get_product_categories/${productId}?token=${token}`
      )
      .then((res) => {
        console.log(res?.data?.result);
        if (res?.data?.status == "success") {
          setAllProductsCategories(res?.data?.result);
        } else if (res?.data?.status == "faild") {
          toast.error(res?.data?.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllProductCategories();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const data_send = {
      ...categoriesData,
    };
    setIsLoading(true);
    axios
      .post(
        BASE_URL + `/products/add_product_category/${productId}?token=${token}`,
        data_send
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message);
          handleGetAllProductCategories();
        } else if (res?.data?.status == "faild") {
          toast.error(res?.data?.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalAddOpen(false);
      })
      .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    const data_send = {
      category_id: Number(rowData.category_id),
    };
    setIsLoading(true);
    axios
      .post(
        `${BASE_URL}/products/update_product_category/${rowData.id}?token=${token}`,
        data_send
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.status === "success") {
          toast.success(res?.data?.message);
          handleGetAllProductCategories();
        } else if (res?.data?.status === "faild") {
          toast.error(res?.data?.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalEditOpen(false);
      })
      .catch((e) => console.log(e));
  }

  function handleDelete() {
    setIsLoading(true);
    axios
      .get(
        BASE_URL + `/products/delete_product_category/${delItem}?token=${token}`
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message);
          handleGetAllProductCategories();
        } else if (res?.data?.status == "faild") {
          toast.error(res?.data?.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalDeleteOpen(false);
      })
      .catch((e) => console.log(e));
  }

  function handleGetAllCategories() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/categories/get_for_admin?token=${token}`)
      .then((res) => {
        console.log(res?.data?.result);
        if (res?.data?.status == "success") {
          setAllCategories(res?.data?.result.filter(it=>it.hidden==0));
        } else if (res?.data?.status == "faild") {
          toast.error(res?.data?.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllCategories();
  }, []);
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Products Categories" />
          <AddModal
            onOk={handleSubmit}
            title="Add Product Category"
            isLoading={isLoading}
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="input_group">
                <label>Choose Category</label>
                <select
                  onChange={(e) =>
                    setCategoriesData({
                      ...categoriesData,
                      category_id: e?.target?.value,
                    })
                  }
                >
                  {allCategories.map((category) => {
                    return (
                      <option key={category?.id} value={category?.id}>
                        {category?.name_en} -{" "}
                        <span style={{ color: "red", fontWeight: "900" }}>
                     ( {category?.brand?.name_en} )
                    </span>
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </AddModal>
        </div>
        <EditModal
          onOk={handleEdit}
          title="Edit Product Category"
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
        >
          <form onSubmit={handleEdit} className="modal_form">
            <div className="input_group">
              <label>Choose Category</label>
              <select
                value={rowData.category_id}
                onChange={(e) =>
                  setRowData({ ...rowData, category_id: e?.target?.value })
                }
              >
                {allCategories.map((category) => (
                  <option key={category?.id} value={category?.id}>
                    {category?.name_en} -{" "}
                    <span style={{ color: "red", fontWeight: "900" }}>
                    ( {category?.brand?.name_en} )
                    </span>
                  </option>
                ))}
              </select>
            </div>
          </form>
        </EditModal>
        <DeleteModal
          isLoading={isLoading}
          isModalOpen={isModalDeleteOpen}
          setIsModalOpen={setIsModalDeleteOpen}
          onOk={handleDelete}
        />
        <Table headers={headers} body={allProductsCategories} />
      </div>
    </DefaultLayout>
  );
}
