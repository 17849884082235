import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import AddModal from '../../components/AddModal/AddModal';
import EditModal from '../../components/EditModal/EditModal';
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../components/table";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../data";
import { useEffect } from "react";

export default function Types() {
  const { recipeId } = useParams();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [allproduct_types, setAllproduct_types] = useState([]);
  const [img, setImg] = useState(null);
  const [product_typeData, setproduct_typeData] = useState({
    name_ar: "",
    name_en: "",
    image: "",
    recipe_id: recipeId,
  });
  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "English Name",
      dataIndex: "name_en",
    },
    {
      label: "Arabic Name",
      dataIndex: "name_ar",
    },
    {
        label: "",
        type: "children",
        children: ({ row, headers }) => {
          return (
            <div className="icons-container">
              {row.hidden == 0 ? (
                <FaRegEye
                  style={{ color: "green" }}
                  onClick={() => {
                    setIsShowStatus(true);
                    setShowStatus(false);
                    setStatus(row.id);
                  }}
                />
              ) : (
                <FaRegEyeSlash
                  style={{ color: "green" }}
                  onClick={() => {
                    setIsShowStatus(true);
                    setShowStatus(true);
                    setStatus(row.id);
                  }}
                />
              )}
              <FaRegEdit
                style={{ color: "blue" }}
                onClick={() => {
                  setIsModalEditOpen(true);
                  setRowData(row);
                }}
              />
              {/* <button className="btn btn-primary" onClick={()=>navigate("/Recipes_Categories/"+row?.id)}>Sub Categories</button> */}

            </div>
          );
        },
      },
  ];
  function handleGetAllproduct_types() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/product_types/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllproduct_types(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllproduct_types();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", img);
    setIsLoading(true);
    const data_send = {
        ...product_typeData,
        recipe_id: recipeId,
      };
      axios
        .post(BASE_URL + `/product_types/add_new?token=${token}`, data_send)
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllproduct_types();
            setImg(null);
            setproduct_typeData({
              image: "",
              name_ar: "",
              name_en: "",
            });
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error('حدث خطأ ما');
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalAddOpen(false);
        })
        .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    if (img == null) {
      const data_send = {
        ...rowData,
      };
      axios
        .post(
          BASE_URL + `/product_types/update_one/${rowData.id}?token=${token}`,
          data_send
        )
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllproduct_types();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        })
        .catch((e) => console.log(e));
    } else {
      const formData = new FormData();
      formData.append("image", img);
      setIsLoading(true);
      axios
        .post(BASE_URL + '/file_upload', formData)
        .then((res) => {
          if (res.data.status == "success") {
            const data_send = {
              ...rowData,
              image: res.data.result.image,
            };
            axios
              .post(BASE_URL + `/product_types/update_one/${rowData?.id}?token=${token}`, data_send)
              .then((res) => {
                if (res.data.status == "success") {
                  toast.success(res.data.message);
                  handleGetAllproduct_types();
                } else if (res.data.status == "faild") {
                  toast.error(res.data.message);
                } else {
                  toast.error("حدث خطأ ما");
                }
              })
              .finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
              })
              .catch((e) => console.log(e));
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/product_types/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllproduct_types();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Types" />
          <AddModal
            onOk={handleSubmit}
            title="Add product_type"
            isLoading={isLoading}
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="inputs_container">
                <div className="input_group">
                  <label>English Name</label>
                  <input
                    type="text"
                    placeholder="English Name"
                    value={product_typeData.name_en}
                    onChange={(e) =>
                      setproduct_typeData({ ...product_typeData, name_en: e.target.value })
                    }
                  />
                </div>

                <div className="input_group">
                  <label>Arabic Name</label>
                  <input
                    type="text"
                    placeholder="Arabic Name"
                    value={product_typeData.name_ar}
                    onChange={(e) =>
                      setproduct_typeData({ ...product_typeData, name_ar: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="input_group">
                <label>Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </div>
            </form>
          </AddModal>
        </div>

        <EditModal
          onOk={handleEdit}
          title="Edit product_type"
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
        >
          <form onSubmit={handleEdit} className="modal_form">
            <div className="inputs_container">
              <div className="input_group">
                <label>English Name</label>
                <input
                  type="text"
                  placeholder="English Name"
                  value={rowData.name_en}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_en: e.target.value })
                  }
                />
              </div>

              <div className="input_group">
                <label>Arabic Name</label>
                <input
                  type="text"
                  placeholder="Arabic Name"
                  value={rowData.name_ar}
                  onChange={(e) =>
                    setRowData({ ...rowData, name_ar: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="input_group">
              <label>Image</label>
              <input
                type="file"
                accept="images/*"
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>
          </form>
        </EditModal>

        <ShowStatusModal
          onOk={handleUpdateStatus}
          isLoading={isLoading}
          isHidden={showStatus}
          isModalOpen={isShowStatus}
          setIsModalOpen={setIsShowStatus}
        />
        <Table headers={headers} body={allproduct_types} />
      </div>
    </DefaultLayout>
  );
}
