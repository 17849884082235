import { useEffect, useState } from "react";
import Table from "../../components/table";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";

export default function Contact() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const [isLoading , setIsLoading] = useState(false);
    const [allContacts , setAllContacts] = useState([]);

    const headers = [
        {
           label:"#",
           dataIndex:"id",   
        },
        {
           label:"Name",
           dataIndex:"name",
        },
        {
            label:"phone",
            dataIndex:"Phone"
        },
        {
            label:"email",
            dataIndex:"Email"
        }
    ]

    function handleGetAllContacts() {
        setIsLoading(true);
        axios.get(BASE_URL + `/contacts/get_for_admin?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
                console.log(res.data.result);
               setAllContacts(res.data.result);
            }else if(res.data.status == "faild") {
                toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما")
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        handleGetAllContacts();
    } , [])
  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div className="page_title_container">
                <ContentNav head="Contacts"/>
            </div>

            <Table headers={headers} body={allContacts} />
        </div>
    </DefaultLayout>
  )
}
