import ContentNav from "../../../datanavcontent";
import DefaultLayout from "../../../layout/defaultlayout";
import AddModal from '../../../components/AddModal/AddModal';
import EditModal from '../../../components/EditModal/EditModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../data";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/table";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";

export default function CookingCategories() {
    const token = JSON.parse(localStorage.getItem("oroubaUsers"));
    const {cookId} = useParams();
    const [isLoading , setIsLoading] = useState(false);
    const [isModalAddOpen , setIsModalAddOpen] = useState(false);
    const [isModalEditOpen , setIsModalEditOpen] = useState(false);
    const [isModalDeleteOpen ,setIsModalDeleteOpen] = useState(false);
    const [delItem , setDelItem] = useState(null);
    const [cookingCategories , setCookingCategories] = useState([]);
    const [allFoods , setAllFoods] = useState([]);
    const [cookingCategoriesData , setCookingCategoriesData] = useState({
        food_id:''
    });
    const [rowData , setRowData] = useState({});

    const headers = [
        {
            label:"#",
            dataIndex:"id",
        },
        {
            label:"Image",
            dataIndex :"image",
            type:"children",
            children : ({row}) => {
                // console.log(row);
                return (
                    <img src={row?.food?.image} alt={row?.food?.name_en}/>
                )
            }
        },
        {
            label:"English Name",
            dataIndex:"name_en",
            type:"children",
            children : ({row}) => {
                return (
                    <p>{row?.food?.name_en}</p>
                )
            }
        },
        {
            label:"Arabic Name",
            dataIndex:"name_ar",
            type:"children",
            children : ({row}) => {
                return (
                    <p>{row?.food?.name_ar}</p>
                )
            }
        },
        {
            label: "",
            type: "children",
            children: ({ row, headers }) => {
              return (
                <div className="icons-container">
                 <button className="edit_btn" onClick={() => {
                      setIsModalEditOpen(true);
                      setRowData(row);
                    }}>Edit</button>

                 <button className="delete_btn"  onClick={() => {
                    setIsModalDeleteOpen(true);
                    setDelItem(row.id)
                 }}>Delete</button>
                </div>
              );
            },
          },
    ]

    function handleGetAllCookingsCategories() {
        setIsLoading(true); 
        axios.get(BASE_URL + `/cooks/get_cook_categories/${cookId}?token=${token}`)
        .then(res => {
            if(res.data.status == "success") {
             console.log(res.data.result);
             setCookingCategories(res.data.result);
            }else if(res.data.status =="faild") {
            toast.error(res.data.message);
            }else {
            toast.error("حدث خطأ ما")
            }
        }).finally(() => {
          setIsLoading(false)
        }).catch(e => console.log(e))
    }

    function handleGetAllFoods() {
       setIsLoading(true);
       axios.get(BASE_URL + `/foods/get_for_admin?token=${token}`)
       .then(res => {
        console.log(res.data.result);
        if(res.data.status == "success") {
             setAllFoods(res.data.result);
        }else if(res.data.status == "faild") {
            toast.error(res.data.message);
        }else {
          toast.error("حدث خطأ ما");
        }
       }).finally(() => {
        setIsLoading(false);
       }).catch(e => console.log(e))
    }

    useEffect(() => {
      handleGetAllCookingsCategories();
      handleGetAllFoods();
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        axios.post(BASE_URL + `/cooks/add_cook_foods/${cookId}?token=${token}`,cookingCategoriesData)
        .then(res => {
            console.log(res);
            if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllCookingsCategories();
              setCookingCategories({
                food_id : null
              })
            }else if(res.data.status == "faild") {
               toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        })
        .finally(() => 
        {
            setIsLoading(false)
            setIsModalAddOpen(false);
        }).catch(e => console.log(e));
    }

    function handleEdit(e) {
      e.preventDefault();
      const data_send = {
        ...rowData,
        food_id : rowData.food_id
      }
      axios.post(BASE_URL + `/cooks/update_cook_foods/${rowData.id}?token=${token}`,data_send)
        .then(res => {
            console.log(res);
            if(res.data.status == "success") {
              toast.success(res.data.message);
              handleGetAllCookingsCategories();
            }else if(res.data.status == "faild") {
               toast.error(res.data.message);
            }else {
                toast.error("حدث خطأ ما");
            }
        })
        .finally(() => 
        {
            setIsLoading(false)
            setIsModalEditOpen(false);
        }).catch(e => console.log(e));
    }


    function handleDelete() {
        setIsLoading(true);
      axios.get(BASE_URL + `/cooks/del_cook_food/${delItem}?token=${token}`)
      .then(res => {
          if(res.data.status == "success") {
             toast.success(res.data.message);
             handleGetAllCookingsCategories();
          }else if(res.data.status =="faild") {
             toast.error(res.data.message);
          }else {
             toast.error("حدث خطأ ما")
          }
      }).finally(() => {
        setIsLoading(false);
        setIsModalDeleteOpen(false);
      }).catch(e => console.log(e))
      
    }

  return (
    <DefaultLayout>
        <div style={{margin:"20px"}}>
            <div  className="page_title_container">
                <ContentNav head="Cooking Categories"/>
                <AddModal onOk={handleSubmit} isLoading={isLoading} isModalOpen={isModalAddOpen} setIsModalOpen={setIsModalAddOpen} title="Add Cooking Categories">
                     <form onSubmit={handleSubmit} className="modal_form">
                       <div className="input_group">
                          <label>Choose Food</label>
                          <select onChange={(e) => setCookingCategoriesData({...cookingCategoriesData , food_id : e.target.value})}>
                            {allFoods.map(food => (
                              <option key={food.id} value={food.id}>{food.name_en}</option>
                            ))}
                          </select>
                       </div>
                     </form>
                </AddModal>
            </div>

            <EditModal onOk={handleEdit} isLoading={isLoading} isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen} title="Edit Cooking Categories">
            <form onSubmit={handleEdit} className="modal_form">
                       <div className="input_group">
                          <label>Choose Food</label>
                          <select value={rowData.food_id} onChange={(e) => setRowData({...rowData , food_id : e.target.value})}>
                            {allFoods.map(food => (
                              <option key={food.id} value={food.id}>{food.name_en}</option>
                            ))}
                          </select>
                       </div>
                     </form>
            </EditModal>
            <DeleteModal isLoading={isLoading} isModalOpen={isModalDeleteOpen} setIsModalOpen={setIsModalDeleteOpen} onOk={handleDelete}/>
            <Table headers={headers} body={cookingCategories}/>
        </div>
    </DefaultLayout>
  )
}
