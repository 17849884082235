import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout/index";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from '../../components/ShowStatusModal/ShowStatusModal';
import { useEffect, useState } from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../data";
import { toast } from "react-toastify";
import Table from "../../components/table";
import ReactQuill from "react-quill";

export default function ProductionSteps() {
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatus , setShowStatus] = useState(false);
  const [isShowStatus , setIsShowStatus] = useState(false);
  const [status , setStatus] = useState(null)
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newProdStep,setNewProdStep]=useState({
    text_ar:'',
    text_en:'',
    number:'',
  })
  const [allStandards, setAllStandards] = useState([]);
  const [img, setImg] = useState(null);

  const [editImg,setEditImg]=useState(null)
  const [editImgUrl,setEditImgUrl]=useState('');

  const [rowData, setRowData] = useState({});
  const [imgUrl,setImgUrl]=useState('');
  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Arabic Text",
      dataIndex: "text_ar",
      type:'children',
      children:({row,headers})=>{
        return(
          <>
            <div dangerouslySetInnerHTML={{ __html:row?.text_ar }}></div>
          </>
        )
      }
    },
    {
      label: "English Text",
      dataIndex: "text_en",
      type:'children',
      children:({row,headers})=>{
        return(
          <>
            <div dangerouslySetInnerHTML={{ __html:row?.text_en }}></div>
          </>
        )
      }
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "Image",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return <img src={row.image} alt="standards image" />;
      },
    },
    // {
    //   label: "English Description",
    //   dataIndex: "description_en",
    // },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            {row.hidden == 0 ? (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(false);
              setStatus(row.id);
            }}>Hide</button>
            ) : (
             <button className="show_btn" onClick={() => {
              console.log(row.hidden);
              setIsShowStatus(true);
              setShowStatus(true);
              setStatus(row.id);
            }}>Show</button>
            )}
            {console.log(row)}
            <button className="edit_btn" onClick={() => {
                setIsModalEditOpen(true);
                setRowData(row);
                setEditImgUrl(row.image)
              }}>Edit</button>
          </div>
        );
      },
    },
  ];

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send={
      number:video_number
    }
    await axios.post(BASE_URL+`/production_steps/change_number/${video_id}`,data_send)
    .then((res)=>{
      console.log(res)
      if(res.data.status=='success'){
        toast.success(res.data.message)
        handleGetAllStandards();
      }
      else if(res.data.status=='faild'){
        toast.error(res.data.message)
      }
      else {
        toast.error("something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setLoader(false);
    })
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };

  function handleGetAllStandards() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/production_steps/get_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllStandards(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllStandards();
    console.log(allStandards);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (img == null) {
      toast.warn("ادخل الصورة أولا");
      return;
    }
    const formData = new FormData();
    formData.append("image", img);
    setIsLoading(true);

    axios
      .post(BASE_URL + "/file_upload", formData)
      .then((res) => {
        if (res.data.status == "success") {
          const data_send = {
            ...newProdStep,
            image: res.data.result.image,
          };
          axios
            .post(BASE_URL + `/production_steps/add_new?token=${token}`, data_send)
            .then((res) => {
              console.log(res);
              if (res.data.status == "success") {
                toast.success(res.data.message);
                handleGetAllStandards();
                setNewProdStep({
                  text_ar:'',
                  text_en:'',
                  number:'',
                })
                setImgUrl('')
                setImg(null)
              } else if (res.data.status == "faild") {
                toast.error(res.data.message);
              } else {
                toast.error("حدث خطأ ما");
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsModalAddOpen(false);
            })
            .catch((e) => console.log(e));
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  function handleEdit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (editImg == null) {
      const data_send = {
        ...rowData,
        image: rowData.image,
        // description_ar : rowData.description_en,
      };

      axios
        .post(
          BASE_URL + `/production_steps/update_one/${rowData.id}?token=${token}`,
          data_send
        )
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(res.data.message);
            handleGetAllStandards();
          } else if (res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalEditOpen(false);
        })
        .catch((e) => console.log(e));
    } else {
      const formData = new FormData();
      formData.append("image", editImg);
      axios
        .post(BASE_URL + "/file_upload", formData)
        .then((res) => {
          if (res.data.status == "success") {
            const data_send = {
              ...rowData,
              image: res.data.result.image,
            };
            axios
              .post(
                BASE_URL + `/production_steps/update_one/${token}?token=${token}`,
                data_send
              )
              .then((res) => {
                if (res.data.status == "success") {
                  toast.success(res.data.message);
                  handleGetAllStandards();
                } else if (res.data.status == "faild") {
                  toast.error(res.data.message);
                } else {
                  toast.error("حدث خطأ ما");
                }
              })
              .finally(() => {
                setIsLoading(false);
                setIsModalEditOpen(false);
              });
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/production_steps/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllStandards();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      }).finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }
  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Production Steps" />
          <AddModal
            isLoading={isLoading}
            setIsModalOpen={setIsModalAddOpen}
            isModalOpen={isModalAddOpen}
            title="Add Production Step"
            onOk={handleSubmit}
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="input_group">
                <label>Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => {
                    setImg(e.target.files[0])
                    setImgUrl(URL.createObjectURL(e.target.files[0]))
                  }}
                />
              </div>
              {
                imgUrl!=''&&<div style={{marginTop:'10px'}}>
                  <img style={{width:'200px',maxWidth:'100%'}} src={imgUrl} alt="" />
                </div>
              }
              <div className="input_group">
                <label>Number</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProdStep({...newProdStep,number:e.target.value})
                  }}
                />
              </div>
              <div className="input_group">
                <label>Arabic Text</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={newProdStep?.text_ar}
                  onChange={(e) => {
                    return setNewProdStep({ ...newProdStep, text_ar: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
            </div>
              <div className="input_group">
                <label>English Text</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={newProdStep?.text_en}
                  onChange={(e) => {
                   return setNewProdStep({ ...newProdStep, text_en: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </form>
          </AddModal>
        </div>

        <EditModal
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          title="Edit Production Step"
          onOk={handleEdit}
        >
          <form onSubmit={handleEdit} className="modal_form">
          <div className="input_group">
                <label>Image</label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={(e) => {
                    setEditImg(e.target.files[0])
                    setEditImgUrl(URL.createObjectURL(e.target.files[0]))
                  }}
                />
              </div>
              {
                editImgUrl!=''&&<div style={{marginTop:'10px'}}>
                  <img style={{width:'200px',maxWidth:'100%'}} src={editImgUrl} alt="" />
                </div>
              }
              {/* <div className="input_group">
                <label>Number</label>
                <input
                  type="text"
                  value={rowData?.number}
                  onChange={(e) => {
                    return setRowData({ ...rowData, number: e.target.value });
                  }}
                />
              </div> */}
              <div className="input_group">
                <label>Arabic Text</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.text_ar}
                  onChange={(e) => {
                    return setRowData({ ...rowData, text_ar: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
            </div>
              <div className="input_group">
                <label>English Text</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.text_en}
                  onChange={(e) => {
                   return setRowData({ ...rowData, text_en: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
          </form>
        </EditModal>
        <ShowStatusModal onOk={handleUpdateStatus} isLoading={isLoading} isHidden={showStatus} isModalOpen={isShowStatus} setIsModalOpen={setIsShowStatus}/>
        <Table headers={headers} body={allStandards} />
      </div>
    </DefaultLayout>
  );
}
