import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import { BASE_URL } from "../../data";
import {
  Button,
  FormContainer,
  FormGroup,
  FormTitle,
  Input,
  Label,
  Textarea,
  truncateText,
  uploadImage,
} from "./utils";
import { Modal } from "antd";
import ReactQuill from "react-quill";

import ImageInput from "../../components/imageInput/ImageInput";
import { CiCirclePlus } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
const SiteInfo = () => {
  const [images, setImages] = useState({
    aboutImage: { id: null, imageURL: null, imageFile: null },
    catalogeImage: { id: null, imageURL: null, imageFile: null },
    mapImage: { id: null, imageURL: null, imageFile: null },
  });
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [editChild,setEdi]
  const [allInfo, setAllInfo] = useState([]);
  const [siteInfoData, setSiteInfoData] = useState({
    site_name_ar: "",
    site_name_en: "",
    location_ar: "",
    email: "",
    stander_ar: "",
    stander_en: "",
    purposal_en: "",
    purposal_ar: "",
    location_en: "",
    catalog_file: "",
    logo: "",
    service_phone: "",
    phone: "",
    product_type_text_en: "",
    product_type_text_ar: "",
    map_url: "",
    product_type_img: "",
    map: "",
    copy_right_en: "",
    copy_right_ar: "",
    export_world_ar: "",
    export_world_en: "",
    how_we_are_ar: "",
    how_we_are_en: "",
    certification_text_ar: "",
    certification_text_en: "",
    catalog_en: "",
    catalog_ar: "",
    why_choose_en: "",
    why_choose_ar: "",
    catalog_image: "",
    about_image: "",
    vision_en: "",
    vision_ar: "",
    world_text: "",
    world_text_ar: "",
    vision_image: "",
    production_steps_ar: "",
    production_steps_en: "",
    quotation_en: "",
    quotation_ar: "",
    why_orouba_img: "",
    why_orouba_en: "",
    why_orouba_ar: "",
  });
  const [delTypeLoading, setDelTypeLoading] = useState(false);
  const [editChildLoading, setEditChildLoading] = useState(false);
  const [catTypeImgId, setCatTypeImgId] = useState("");
  const [newCatType, setNewCatType] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    number: 1,
  });
  const [disAddNew, setDisAddNew] = useState(false);
  const [newChild, setNewChild] = useState({
    category_id: "",
    number: 1,
    category_type_id: "",
  });

  const [addChildLoading, setAddChildLoading] = useState(false);

  const [imgChild, setImgChild] = useState(null);
  const [newCatLoading, setNewCatLoading] = useState(false);
  const [newCatTypeImg, setNewCatImgType] = useState(null);
  const [newCatTypeImgUrl, setnewCatImgTypeUrl] = useState("");
  const [disAdd, setDisAdd] = useState(false);
  const [heroImg, setHeroImg] = useState(null);
  const [heroImgUrl, setHeroImgUrl] = useState("");

  const [mapImg, setMapImg] = useState(null);
  const [mapImgUrl, setMapImgUrl] = useState("");

  const [aboutImg, setAboutImg] = useState(null);
  const [aboutSmallImg, setAboutSmallImg] = useState(null);
  const [aboutImgUrl, setAboutImgUrl] = useState("");
  const [aboutSmallImgUrl, setAboutSmallImgUrl] = useState("");

  const [catalogImg, setCatalogImg] = useState(null);
  const [catalogImgUrl, setCatalogImgUrl] = useState("");

  const [vissionImg, setVissionImg] = useState(null);
  const [vissionImgUrl, setVissionImgUrl] = useState("");

  const [logoImg, setImgLogo] = useState(null);
  const [logoImgUrl, setLogoImgUrl] = useState("");

  const [categoriesTypes, setCategoriesTypes] = useState([]);
  const [modelTypes, setModelTypes] = useState(false);

  const [catTypeImg, setCatTypeImg] = useState(null);
  const [catTypeImgUrl, setCatTypeImgUrl] = useState("");

  const [prodTypeImg, setProdTypeImg] = useState(null);

  const [catalogFile, setCatalogFile] = useState(null);

  const [imgChilds, setImgChilds] = useState([]);

  const [editCatTypeLoading, setEditCatTypeLoading] = useState(false);

  const [whyOrImg, setwhyOrImg] = useState(null);
  const [whyOrImgUrl, setwhyOrImgUrl] = useState("");

  const headers = [
    {
      label: "Site Name(arabic)",
      dataIndex: "site_name_ar",
      type: "children",
      children: ({ row }) => <span>{row.site_name_ar}</span>,
    },
    {
      label: "Site Name(english)",
      dataIndex: "site_name_en",
      type: "children",
      children: ({ row }) => <span>{row.site_name_en}</span>,
    },
    {
      label: "Logo",
      dataIndex: "logo",
      type: "children",
      children: ({ row }) => <img src={row.logo} alt="" />,
    },
    {
      label: "Map",
      dataIndex: "map",
      type: "children",
      children: ({ row }) => <img src={row.map} alt="" />,
    },
    {
      label: "vision_image",
      dataIndex: "vision_image",
      type: "children",
      children: ({ row }) => <img src={row.vision_image} alt="" />,
    },
    {
      label: "About Image",
      dataIndex: "about_image",
      type: "children",
      children: ({ row }) => <img src={row.about_image} alt="" />,
    },
    { label: "English Catalog", dataIndex: "catalog_en" },
    { label: "Arabic Catalog", dataIndex: "catalog_ar" },
    {
      label: "Catalog Image",
      dataIndex: "catalog_image",
      type: "children",
      children: ({ row }) => (
        <img src={row.catalog_image} alt={row.catalog_en} />
      ),
    },
    {
      label: "English Certification Text",
      dataIndex: "certification_text_en",
      children: ({ row }) => (
        <span>{truncateText(row.certification_text_en)}</span>
      ),
    },
    {
      label: "Arabic Certification Text",
      dataIndex: "certification_text_ar",
      children: ({ row }) => (
        <span>{truncateText(row.certification_text_ar)}</span>
      ),
    },
    {
      label: "English Copyright",
      dataIndex: "copy_right_en",
      children: ({ row }) => <span>{truncateText(row.copy_right_en)}</span>,
    },
    {
      label: "Arabic Copyright",
      dataIndex: "copy_right_ar",
      children: ({ row }) => <span>{truncateText(row.copy_right_ar)}</span>,
    },
    {
      label: "English Export World",
      dataIndex: "export_world_en",
      children: ({ row }) => <span>{truncateText(row.export_world_en)}</span>,
    },
    {
      label: "Arabic Export World",
      dataIndex: "export_world_ar",
      children: ({ row }) => <span>{truncateText(row.export_world_ar)}</span>,
    },
    {
      label: "English How we are",
      dataIndex: "how_we_are_en",
      children: ({ row }) => <span>{truncateText(row.how_we_are_en)}</span>,
    },
    {
      label: "Arabic How we are",
      dataIndex: "how_we_are_ar",
      children: ({ row }) => <span>{truncateText(row.how_we_are_ar)}</span>,
    },
    {
      label: "English Location",
      dataIndex: "location_en",
      children: ({ row }) => <span>{truncateText(row.location_en)}</span>,
    },
    {
      label: "Arabic Location",
      dataIndex: "location_ar",
      children: ({ row }) => <span>{truncateText(row.location_ar)}</span>,
    },
    {
      label: "Logo",
      dataIndex: "logo",
      type: "children",
      children: ({ row }) => <img src={row.logo} alt="" />,
    },
    {
      label: "Map",
      dataIndex: "map",
      type: "children",
      children: ({ row }) => <img src={row.map} alt="" />,
    },
    { label: "Phone", dataIndex: "phone" },
    { label: "Service Phone", dataIndex: "service_phone" },
    // {
    //   label: "English Site Name",
    //   dataIndex: "site_name_en",
    //   children: ({ row }) => <span>{truncateText(row.site_name_en)}</span>,
    // },
    {
      label: "English Vision",
      dataIndex: "vision_en",
      children: ({ row }) => <span>{truncateText(row.vision_en)}</span>,
    },
    {
      label: "Arabic Vision",
      dataIndex: "vision_ar",
      children: ({ row }) => <span>{truncateText(row.vision_ar)}</span>,
    },
    {
      label: "English Why Choose",
      dataIndex: "why_choose_en",
      children: ({ row }) => <span>{truncateText(row.why_choose_en)}</span>,
    },
    {
      label: "Arabic Why Choose",
      dataIndex: "why_choose_ar",
      children: ({ row }) => <span>{truncateText(row.why_choose_ar)}</span>,
    },
  ];

  // const handleUpAb=()=>{

  // }
  const [allCategories, setAllCategories] = useState([]);

  const getAllCategories = () => {
    axios
      .get(`${BASE_URL}/categories/get_for_admin?token=${token}`)
      .then((res) => {
        if (Array.isArray(res.data.result)) {
          setAllCategories(res.data.result.filter((it) => it.hidden == 0));
          // console.log(res.data.result)
          if (res.data.result.length > 0) {
            setNewChild({ ...newChild, category_id: res.data.result[0].id });
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getTypesCategories = () => {
    axios
      .get(BASE_URL + `/category_types/get_all`)
      .then((res) => {
        if (Array.isArray(res.data.result)) {
          setCategoriesTypes(res.data.result);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getTypesCategories();
    handleGetAllSiteInfo();
    getAllCategories();
  }, []);

  const handleGetAllSiteInfo = () => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL}/site_info/get_all_for_admin?token=${token}`)
      .then((res) => {
        // console.log(res,"ewkwlkwe")
        if (res.data.status === "success") {
          setAllInfo([res.data.result]);
          setSiteInfoData(res.data.result);
        } else {
          toast.error(res.data.message || "حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteInfoData({
      ...siteInfoData,
      [name]: value,
    });
  };

  const handleSubmit = async (type) => {
    // e.preventDefault();
    setIsLoading(true);
    if (type == "home") {
      if (heroImg != null) {
        const formData = new FormData();
        formData.append("image", heroImg);
        axios
          .post(`${BASE_URL}/file_upload`, formData)
          .then((res1) => {
            if (res1.data.status == "success") {
              if (mapImg != null) {
                const formData2 = new FormData();
                formData2.append("image", mapImg);
                axios
                  .post(`${BASE_URL}/file_upload`, formData2)
                  .then((res2) => {
                    if (res2.data.status == "success") {
                      if (whyOrImg != null) {
                        const formOrData = new FormData();
                        formOrData.append("image", whyOrImg);
                        axios
                          .post(`${BASE_URL}/file_upload`, formOrData)
                          .then((res4) => {
                            const data_send = {
                              ...siteInfoData,
                              hero_img: res1.data.result.image,
                              map: res2.data.result.image,
                              why_orouba_img: res4.data.result.image,
                            };
                            axios
                              .post(
                                `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                                data_send
                              )
                              .then((res3) => {
                                if (res3.data.status == "success") {
                                  toast.success(res3.data.message);
                                  handleGetAllSiteInfo();
                                  setIsModalOpen(false);
                                } else if (res3.data.status == "faild") {
                                  toast.error(res3.data.message);
                                } else {
                                  toast.error("Something Went Error");
                                }
                              })
                              .catch((e) => console.log(e))
                              .finally(() => {
                                setIsLoading(false);
                              });
                          })
                          .catch((e) => console.log(e));
                      } else {
                        const data_send = {
                          ...siteInfoData,
                          hero_img: res1.data.result.image,
                          map: res2.data.result.image,
                        };
                        axios
                          .post(
                            `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                            data_send
                          )
                          .then((res3) => {
                            if (res3.data.status == "success") {
                              toast.success(res3.data.message);
                              handleGetAllSiteInfo();
                              setIsModalOpen(false);
                            } else if (res3.data.status == "faild") {
                              toast.error(res3.data.message);
                            } else {
                              toast.error("Something Went Error");
                            }
                          })
                          .catch((e) => console.log(e))
                          .finally(() => {
                            setIsLoading(false);
                          });
                      }
                    } else {
                      toast.error("Image Not Uploaded");
                    }
                  })
                  .catch((e) => console.log(e));
              } else {
                if (whyOrImg != null) {
                  const formOrData = new FormData();
                  formOrData.append("image", whyOrImg);
                  axios
                    .post(`${BASE_URL}/file_upload`, formOrData)
                    .then((res4) => {
                      const data_send = {
                        ...siteInfoData,
                        hero_img: res1.data.result.image,
                        // map: res2.data.result.image,
                        why_orouba_img: res4.data.result.image,
                      };
                      axios
                        .post(
                          `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                          data_send
                        )
                        .then((res3) => {
                          if (res3.data.status == "success") {
                            toast.success(res3.data.message);
                            handleGetAllSiteInfo();
                            setIsModalOpen(false);
                          } else if (res3.data.status == "faild") {
                            toast.error(res3.data.message);
                          } else {
                            toast.error("Something Went Error");
                          }
                        })
                        .catch((e) => console.log(e))
                        .finally(() => {
                          setIsLoading(false);
                        });
                    })
                    .catch((e) => console.log(e));
                } else {
                  const data_send = {
                    ...siteInfoData,
                    hero_img: res1.data.result.image,
                  };
                  axios
                    .post(
                      `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                      data_send
                    )
                    .then((res3) => {
                      if (res3.data.status == "success") {
                        handleGetAllSiteInfo();
                        toast.success(res3.data.message);
                        setIsModalOpen(false);
                      } else if (res3.data.status == "faild") {
                        toast.error(res3.data.message);
                      } else {
                        toast.error("Something Went Error");
                      }
                    })
                    .catch((e) => console.log(e))
                    .finally(() => {
                      setIsLoading(false);
                    });
                }
              }
            }
          })
          .catch((e) => console.log(e));
      } else {
        if (mapImg != null) {
          const formData2 = new FormData();
          formData2.append("image", mapImg);
          axios
            .post(`${BASE_URL}/file_upload`, formData2)
            .then((res2) => {
              if (res2.data.status == "success") {
                if (whyOrImg != null) {
                  const formOrData = new FormData();
                  formOrData.append("image", whyOrImg);
                  axios
                    .post(`${BASE_URL}/file_upload`, formOrData)
                    .then((res4) => {
                      const data_send = {
                        ...siteInfoData,
                        // hero_img: res1.data.result.image,
                        map: res2.data.result.image,
                        why_orouba_img: res4.data.result.image,
                      };
                      axios
                        .post(
                          `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                          data_send
                        )
                        .then((res3) => {
                          if (res3.data.status == "success") {
                            toast.success(res3.data.message);
                            handleGetAllSiteInfo();
                            setIsModalOpen(false);
                          } else if (res3.data.status == "faild") {
                            toast.error(res3.data.message);
                          } else {
                            toast.error("Something Went Error");
                          }
                        })
                        .catch((e) => console.log(e))
                        .finally(() => {
                          setIsLoading(false);
                        });
                    })
                    .catch((e) => console.log(e));
                } else {
                  const data_send = {
                    ...siteInfoData,
                    map: res2.data.result.image,
                  };
                  axios
                    .post(
                      `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                      data_send
                    )
                    .then((res3) => {
                      if (res3.data.status == "success") {
                        toast.success(res3.data.message);
                        handleGetAllSiteInfo();
                        setIsModalOpen(false);
                      } else if (res3.data.status == "faild") {
                        toast.error(res3.data.message);
                      } else {
                        toast.error("Something Went Error");
                      }
                    })
                    .catch((e) => console.log(e))
                    .finally(() => {
                      setIsLoading(false);
                    });
                }
              } else {
                toast.error("Image Not Uploaded");
              }
            })
            .catch((e) => console.log(e));
        } else {
          if (whyOrImg != null) {
            const formOrData = new FormData();
            formOrData.append("image", whyOrImg);
            axios
              .post(`${BASE_URL}/file_upload`, formOrData)
              .then((res4) => {
                const data_send = {
                  ...siteInfoData,
                  why_orouba_img: res4.data.result.image,
                };
                axios
                  .post(
                    `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                    data_send
                  )
                  .then((res3) => {
                    if (res3.data.status == "success") {
                      toast.success(res3.data.message);
                      handleGetAllSiteInfo();
                      setIsModalOpen(false);
                    } else if (res3.data.status == "faild") {
                      toast.error(res3.data.message);
                    } else {
                      toast.error("Something Went Error");
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    setIsLoading(false);
                  });
              })
              .catch((e) => console.log(e));
          } else {
            const data_send = {
              ...siteInfoData,
            };
            axios
              .post(
                `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                data_send
              )
              .then((res3) => {
                if (res3.data.status == "success") {
                  toast.success(res3.data.message);
                  handleGetAllSiteInfo();
                  setIsModalOpen(false);
                } else if (res3.data.status == "faild") {
                  toast.error(res3.data.message);
                } else {
                  toast.error("Something Went Error");
                }
              })
              .catch((e) => console.log(e))
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      }
    } else if (type == "about") {
      if (aboutImg != null) {
        const formData = new FormData();
        formData.append("image", aboutImg);
        axios
          .post(`${BASE_URL}/file_upload`, formData)
          .then((res1) => {
            if (res1.data.status == "success") {
              if (aboutSmallImg != null) {
                const formDataSmallAbout = new FormData();
                formDataSmallAbout.append("image", aboutSmallImg);
                axios
                  .post(`${BASE_URL}/file_upload`, formDataSmallAbout)
                  .then((resSmall) => {
                    if (resSmall.data.status == "success") {
                      const data_send = {
                        ...siteInfoData,
                        about_image: res1.data.result.image,
                        small_about_img: resSmall?.data?.result?.image,
                      };
                      axios
                        .post(
                          `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                          data_send
                        )
                        .then((res3) => {
                          if (res3.data.status == "success") {
                            handleGetAllSiteInfo();
                            toast.success(res3.data.message);
                            setIsModalOpen(false);
                          } else if (res3.data.status == "faild") {
                            toast.error(res3.data.message);
                          } else {
                            toast.error("Something Went Error");
                          }
                        })
                        .catch((e) => console.log(e))
                        .finally(() => {
                          setIsLoading(false);
                        });
                    }
                  });
              } else {
                const data_send = {
                  ...siteInfoData,
                  about_image: res1.data.result.image,
                };
                axios
                  .post(
                    `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                    data_send
                  )
                  .then((res3) => {
                    if (res3.data.status == "success") {
                      handleGetAllSiteInfo();
                      toast.success(res3.data.message);
                      setIsModalOpen(false);
                    } else if (res3.data.status == "faild") {
                      toast.error(res3.data.message);
                    } else {
                      toast.error("Something Went Error");
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    setIsLoading(false);
                  });
              }
            }
          })
          .catch((e) => console.log(e));
      } else {
        if (aboutSmallImg != null) {
          const formDataSmallAbout = new FormData();
          formDataSmallAbout.append("image", aboutSmallImg);
          axios
            .post(`${BASE_URL}/file_upload`, formDataSmallAbout)
            .then((resSmall) => {
              if (resSmall.data.status == "success") {
                const data_send = {
                  ...siteInfoData,
                  small_about_img: resSmall?.data?.result?.image,
                };
                axios
                  .post(
                    `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                    data_send
                  )
                  .then((res3) => {
                    if (res3.data.status == "success") {
                      handleGetAllSiteInfo();
                      toast.success(res3.data.message);
                      setIsModalOpen(false);
                    } else if (res3.data.status == "faild") {
                      toast.error(res3.data.message);
                    } else {
                      toast.error("Something Went Error");
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    setIsLoading(false);
                  });
              }
            });
        } else {
          const data_send = {
            ...siteInfoData,
          };
          axios
            .post(
              `${BASE_URL}/site_info/edit_site_info?token=${token}`,
              data_send
            )
            .then((res3) => {
              if (res3.data.status == "success") {
                handleGetAllSiteInfo();
                toast.success(res3.data.message);
                setIsModalOpen(false);
              } else if (res3.data.status == "faild") {
                toast.error(res3.data.message);
              } else {
                toast.error("Something Went Error");
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    } else if (type == "certi") {
      const data_send = {
        ...siteInfoData,
      };
      axios
        .post(`${BASE_URL}/site_info/edit_site_info?token=${token}`, data_send)
        .then((res3) => {
          if (res3.data.status == "success") {
            handleGetAllSiteInfo();
            toast.success(res3.data.message);
            setIsModalOpen(false);
          } else if (res3.data.status == "faild") {
            toast.error(res3.data.message);
          } else {
            toast.error("Something Went Error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsLoading(false);
        });
    } else if (type == "cata") {
      if (catalogImg != null) {
        const formData = new FormData();
        formData.append("image", catalogImg);
        axios
          .post(`${BASE_URL}/file_upload`, formData)
          .then((res1) => {
            if (res1.data.status == "success") {
              if (catalogFile != null) {
                const formData2 = new FormData();
                formData2.append("image", catalogFile);
                axios
                  .post(BASE_URL + `/file_upload`, formData2)
                  .then((res2) => {
                    if (res2.data.status == "success") {
                      const data_send = {
                        ...siteInfoData,
                        catalog_image: res1.data.result.image,
                        catalog_file: res2.data.result.image,
                      };
                      axios
                        .post(
                          `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                          data_send
                        )
                        .then((res3) => {
                          if (res3.data.status == "success") {
                            handleGetAllSiteInfo();
                            toast.success(res3.data.message);
                            setIsModalOpen(false);
                          } else if (res3.data.status == "faild") {
                            toast.error(res3.data.message);
                          } else {
                            toast.error("Something Went Error");
                          }
                        })
                        .catch((e) => console.log(e))
                        .finally(() => {
                          setIsLoading(false);
                        });
                    } else if (res2.data.status == "faild") {
                      toast.error(res2.data.message);
                    } else {
                      toast.error("Catalog File Not Uploaded");
                    }
                  })
                  .catch((e) => console.log(e));
              } else {
                const data_send = {
                  ...siteInfoData,
                  catalog_image: res1.data.result.image,
                };
                axios
                  .post(
                    `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                    data_send
                  )
                  .then((res3) => {
                    if (res3.data.status == "success") {
                      handleGetAllSiteInfo();
                      toast.success(res3.data.message);
                      setIsModalOpen(false);
                      setCatalogImg(null);
                      setCatalogFile(null);
                    } else if (res3.data.status == "faild") {
                      toast.error(res3.data.message);
                    } else {
                      toast.error("Something Went Error");
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    setIsLoading(false);
                  });
              }
            }
          })
          .catch((e) => console.log(e));
      } else {
        if (catalogFile != null) {
          const formData2 = new FormData();
          formData2.append("image", catalogFile);
          axios
            .post(BASE_URL + `/file_upload`, formData2)
            .then((res2) => {
              if (res2.data.status == "success") {
                const data_send = {
                  ...siteInfoData,
                  catalog_file: res2.data.result.image,
                };
                axios
                  .post(
                    `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                    data_send
                  )
                  .then((res3) => {
                    if (res3.data.status == "success") {
                      handleGetAllSiteInfo();
                      toast.success(res3.data.message);
                      setIsModalOpen(false);
                      setCatalogImg(null);
                      setCatalogFile(null);
                    } else if (res3.data.status == "faild") {
                      toast.error(res3.data.message);
                    } else {
                      toast.error("Something Went Error");
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    setIsLoading(false);
                  });
              } else if (res2.data.status == "faild") {
                toast.error(res2.data.message);
              } else {
                toast.error("Catalog File Not Uploaded");
              }
            })
            .catch((e) => console.log(e));
        } else {
          const data_send = {
            ...siteInfoData,
          };
          axios
            .post(
              `${BASE_URL}/site_info/edit_site_info?token=${token}`,
              data_send
            )
            .then((res3) => {
              if (res3.data.status == "success") {
                handleGetAllSiteInfo();
                toast.success(res3.data.message);
                setIsModalOpen(false);
                setCatalogImg(null);
                setCatalogFile(null);
              } else if (res3.data.status == "faild") {
                toast.error(res3.data.message);
              } else {
                toast.error("Something Went Error");
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    } else if (type == "vission") {
      if (vissionImg != null) {
        const formData = new FormData();
        formData.append("image", vissionImg);
        axios
          .post(`${BASE_URL}/file_upload`, formData)
          .then((res1) => {
            if (res1.data.status == "success") {
              const data_send = {
                ...siteInfoData,
                about_image: res1.data.result.image,
              };
              axios
                .post(
                  `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                  data_send
                )
                .then((res3) => {
                  if (res3.data.status == "success") {
                    handleGetAllSiteInfo();
                    toast.success(res3.data.message);
                    setIsModalOpen(false);
                  } else if (res3.data.status == "faild") {
                    toast.error(res3.data.message);
                  } else {
                    toast.error("Something Went Error");
                  }
                })
                .catch((e) => console.log(e))
                .finally(() => {
                  setIsLoading(false);
                });
            }
          })
          .catch((e) => console.log(e));
      } else {
        const data_send = {
          ...siteInfoData,
        };
        axios
          .post(
            `${BASE_URL}/site_info/edit_site_info?token=${token}`,
            data_send
          )
          .then((res3) => {
            if (res3.data.status == "success") {
              handleGetAllSiteInfo();
              toast.success(res3.data.message);
              setIsModalOpen(false);
            } else if (res3.data.status == "faild") {
              toast.error(res3.data.message);
            } else {
              toast.error("Something Went Error");
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else if (type == "siteData") {
      if (logoImg != null) {
        const formData = new FormData();
        formData.append("image", logoImg);
        axios
          .post(`${BASE_URL}/file_upload`, formData)
          .then((res1) => {
            if (res1.data.status == "success") {
              const data_send = {
                ...siteInfoData,
                about_image: res1.data.result.image,
              };
              axios
                .post(
                  `${BASE_URL}/site_info/edit_site_info?token=${token}`,
                  data_send
                )
                .then((res3) => {
                  if (res3.data.status == "success") {
                    handleGetAllSiteInfo();
                    toast.success(res3.data.message);
                    setIsModalOpen(false);
                  } else if (res3.data.status == "faild") {
                    toast.error(res3.data.message);
                  } else {
                    toast.error("Something Went Error");
                  }
                })
                .catch((e) => console.log(e))
                .finally(() => {
                  setIsLoading(false);
                });
            }
          })
          .catch((e) => console.log(e));
      } else {
        const data_send = {
          ...siteInfoData,
        };
        axios
          .post(
            `${BASE_URL}/site_info/edit_site_info?token=${token}`,
            data_send
          )
          .then((res3) => {
            if (res3.data.status == "success") {
              handleGetAllSiteInfo();
              toast.success(res3.data.message);
              setIsModalOpen(false);
            } else if (res3.data.status == "faild") {
              toast.error(res3.data.message);
            } else {
              toast.error("Something Went Error");
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else if (type == "prod_type_img") {
      if (prodTypeImg != null) {
        const formData = new FormData();
        formData.append("image", prodTypeImg);
        axios
          .post(BASE_URL + `/file_upload`, formData)
          .then((res) => {
            if (res.data.status == "success") {
              const data_send = {
                ...siteInfoData,
                product_type_img: res.data.result.image,
              };
              axios
                .post(
                  BASE_URL + `/site_info/edit_site_info?token=${token}`,
                  data_send
                )
                .then((res2) => {
                  if (res2.data.status == "success") {
                    toast.success(res2.data.message);
                    handleGetAllSiteInfo();
                    setIsModalOpen(false);
                  } else if (res2.data.status == "faild") {
                    toast.error(res2.data.message);
                  } else {
                    toast.error("Something Went Wrong");
                  }
                })
                .catch((e) => console.log(e))
                .finally(() => {
                  setIsLoading(false);
                });
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("Image Not Uploaded");
            }
          })
          .catch((e) => console.log(e));
        // toast.warn('Enter New Image')
        // return
      } else {
        const data_send = {
          ...siteInfoData,
        };
        axios
          .post(
            BASE_URL + `/site_info/edit_site_info?token=${token}`,
            data_send
          )
          .then((res2) => {
            if (res2.data.status == "success") {
              toast.success(res2.data.message);
              handleGetAllSiteInfo();
              setIsModalOpen(false);
            } else if (res2.data.status == "faild") {
              toast.error(res2.data.message);
            } else {
              toast.error("Something Went Wrong");
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
    // else if(type=='new_cat_ed'){
    //   if()
    // }

    // try {
    //     const uploadedImages = { ...images };

    //     // Upload images if new images are selected
    //     for (const key in images) {
    //         if (images[key].imageFile) {
    //             const imageUrl = await uploadImage(images[key].imageFile);
    //             uploadedImages[key] = { ...uploadedImages[key], imageURL: imageUrl };
    //         }
    //     }

    //     // Update siteInfoData with uploaded image URLs
    //     const updatedSiteInfoData = {
    //         ...siteInfoData,
    //         about_image: uploadedImages.aboutImage.imageURL || siteInfoData.about_image,
    //         catalog_image: uploadedImages.catalogeImage.imageURL || siteInfoData.catalog_image,
    //         map: uploadedImages.mapImage.imageURL || siteInfoData.map,
    //         vision_image: uploadedImages.vision_image.imageURL || siteInfoData.vision_image,
    //     };

    //     // Make the API call to update site information
    //     const response = await axios.post(`${BASE_URL}/site_info/edit_site_info?token=${token}`, {
    //         ...updatedSiteInfoData,
    //         token
    //     });

    //     if (response.data.status === "success") {
    //         toast.success("Site information updated successfully");
    //         handleGetAllSiteInfo();
    //         setImages({
    //             aboutImage: { id: null, imageURL: null, imageFile: null },
    //             catalogeImage: { id: null, imageURL: null, imageFile: null },
    //             mapImage: { id: null, imageURL: null, imageFile: null },
    //           })
    //     } else {
    //         toast.error(response.data.message || "Failed to update site information");
    //     }
    // } catch (error) {
    //     console.error(error);
    //     toast.error("An error occurred while updating site information");
    // } finally {
    //     setIsLoading(false);
    // }
  };

  const handleDelParent = (id) => {
    setDelTypeLoading(true);
    axios
      .get(BASE_URL + `/category_types/del_one_parent/${id}`)
      .then((res) => {
        if (res.data.status == "success") {
          // setModelTypes(false)
          getTypesCategories();
          toast.success(res.data.message);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDelTypeLoading(false);
      });
  };

  const handleEditCategoryType = (item) => {
    setEditCatTypeLoading(true);
    if (catTypeImg != null) {
      const formData = new FormData();
      formData.append("image", catTypeImg);
      axios
        .post(BASE_URL + `/file_upload`, formData)
        .then((res1) => {
          if (res1.data.status == "success") {
            const data_send = {
              ...item,
              image: res1.data.result.image,
            };
            axios
              .post(
                `${BASE_URL}/category_types/update_one/${item.id}?token=${token}`,
                data_send
              )
              .then((res2) => {
                if (res2.data.status == "success") {
                  // setModelTypes(false)
                  getTypesCategories();
                  toast.success(res2.data.message);
                } else if (res2.data.status == "faild") {
                  toast.error(res2.data.message);
                } else {
                  toast.error("حدث خطأ ما");
                }
              })
              .catch((e) => console.log(e))
              .finally(() => {
                setEditCatTypeLoading(false);
              });
          }
        })
        .catch((e) => console.log(e));
    } else {
      const data_send = {
        ...item,
      };
      axios
        .post(
          `${BASE_URL}/category_types/update_one/${item.id}?token=${token}`,
          data_send
        )
        .then((res2) => {
          if (res2.data.status == "success") {
            // setModelTypes(false)
            getTypesCategories();
            toast.success(res2.data.message);
          } else if (res2.data.status == "faild") {
            toast.error(res2.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setEditCatTypeLoading(false);
        });
    }
  };

  const handleAddNewCatType = () => {
    if (newCatTypeImg == null) {
      toast.warn("Enter Category Type Image");
      return;
    }
    setNewCatLoading(true);
    const formData = new FormData();
    formData.append("image", newCatTypeImg);
    axios
      .post(BASE_URL + `/file_upload`, formData)
      .then((res1) => {
        if (res1.data.status == "success") {
          const data_send = {
            image: res1.data.result.image,
            ...newCatType,
          };
          axios
            .post(
              `${BASE_URL}/category_types/add_new?token=${token}`,
              data_send
            )
            .then((res2) => {
              if (res2.data.status == "success") {
                // setModelTypes(false)
                getTypesCategories();
                toast.success(res2.data.message);
                setDisAdd(false);
                setNewCatImgType(null);
                setnewCatImgTypeUrl("");
                setNewCatType({
                  title_ar: "",
                  title_en: "",
                  description_ar: "",
                  description_en: "",
                  number: 1,
                });
              } else if (res2.data.status == "faild") {
                toast.error(res2.data.message);
              } else {
                toast.error("Data Not Uploadded");
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              setNewCatLoading(false);
            });
        } else if (res1.data.status == "faild") {
          toast.error(res1.data.message);
        } else {
          toast.error("image Did Not Uploaded ");
        }
      })
      .catch((e) => console.log(e));
  };

  const handleChange2 = (e, i) => {
    const { name, value } = e.target;
    let list = [...categoriesTypes];
    list[i][name] = value;
    // console.log(list)
    setCategoriesTypes(list);
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setNewCatType({ ...newCatType, [name]: value });
  };

  const handleEditChildes = (item) => {
    // console.log(item)
    setEditChildLoading(true);
    let childs = [...item.cattype];
    console.log(imgChilds);
    if (imgChilds.length > 0) {
      console.log("ee");
      for (let i = 0; i < imgChilds.length; i++) {
        const formData = new FormData();
        formData.append("image", imgChilds[i]["img"]);
        axios
          .post(`${BASE_URL}/file_upload`, formData)
          .then((res) => {
            if (res.data.status == "success") {
              childs[imgChilds[i]["index"]]["image"] = res.data.result.image;
              if (i == imgChilds.length - 1) {
                let assigned_cats = "";
                for (let k = 0; k < childs.length; k++) {
                  if (k == 0) {
                    assigned_cats +=
                      childs[k].category_id +
                      "**" +
                      childs[k].image +
                      "**" +
                      childs[k].number;
                  } else {
                    assigned_cats +=
                      "*oroba*" +
                      childs[k].category_id +
                      "**" +
                      childs[k].image +
                      "**" +
                      childs[k].number;
                  }
                }
                const data_send = {
                  assigned_cats,
                };
                axios
                  .post(
                    `${BASE_URL}/category_types/edit_assign/${item.id}`,
                    data_send
                  )
                  .then((res2) => {
                    console.log(res2);
                    if (res2.data.status == "success") {
                      getTypesCategories();
                      toast.success(res2.data.message);
                      // setModelTypes(false)
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    setEditChildLoading(false);
                  });
              }
            } else if (res.data.status == "faild") {
              toast.error(res.data.message);
            } else {
              toast.error("Image Not Uploaded");
            }
          })
          .catch((e) => console.log(e));
      }
    } else {
      let childs = [...item.cattype];
      console.log(childs);
      // setEditChildLoading(false)
      let assigned_cats = "";
      for (let k = 0; k < childs.length; k++) {
        if (k == 0) {
          assigned_cats +=
            childs[k].category_id +
            "**" +
            childs[k].image +
            "**" +
            childs[k].number;
        } else {
          assigned_cats +=
            "*oroba*" +
            childs[k].category_id +
            "**" +
            childs[k].image +
            "**" +
            childs[k].number;
        }
      }
      // console.log(assigned_cats)
      // return
      const data_send = {
        assigned_cats,
      };
      axios
        .post(`${BASE_URL}/category_types/edit_assign/${item.id}`, data_send)
        .then((res2) => {
          console.log(res2);
          if (res2.data.status == "success") {
            getTypesCategories();
            toast.success(res2.data.message);
            // setModelTypes(false)
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setEditChildLoading(false);
        });
    }
  };

  const handleAddNewChild = () => {
    if (imgChild == null) {
      toast.warn("Enter Image");
      return;
    }
    setAddChildLoading(true);
    const formData = new FormData();
    formData.append("image", imgChild);
    axios
      .post(`${BASE_URL}/file_upload`, formData)
      .then((res1) => {
        if (res1.data.status == "success") {
          const data_send = {
            image: res1.data.result.image,
            ...newChild,
          };
          axios
            .post(
              `${BASE_URL}/category_types/add_new_assign/${newChild?.category_type_id}`,
              data_send
            )
            .then((res) => {
              console.log(res, "weew");
              if (res.data.status == "success") {
                toast.success(res.data.message);
                getTypesCategories();
                setNewChild({
                  category_id: "",
                  number: 1,
                  category_type_id: "",
                });
                setDisAddNew(false);
                setImgChild(null);
                // setModelTypes(false)
              } else if (res.data.status == "error") {
                toast.error(res.data.message);
              } else {
                toast.error("Something Went Error");
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              setAddChildLoading(false);
            });
        } else if (res1.data.status == "faild") {
          toast.error(res1.data.message);
        } else {
          toast.error("Image Not Uploaded");
        }
      })
      .catch((e) => console.log(e));
  };
  const handleDelChild = (id) => {
    setEditChildLoading(true);
    axios
      .get(BASE_URL + `/category_types/del_one/${id}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          getTypesCategories();
          // setModelTypes(false)
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditChildLoading(false);
      });
  };

  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Pages" />
          <div className="d-flex gap-2">
            <button
              disabled={isLoading}
              className="btn btn-primary"
              onClick={() => {
                setIsModalOpen(true);
                setHeroImgUrl(siteInfoData.hero_img);
                setMapImgUrl(siteInfoData.map);
                setAboutImgUrl(siteInfoData.about_image);
                setAboutImgUrl(siteInfoData.small_about_img);
                setCatalogImgUrl(siteInfoData.catalog_image);
                setVissionImgUrl(siteInfoData.vision_image);
                setLogoImgUrl(siteInfoData.logo);
                // setCatalogFile(siteInfoData.catalog_file)
              }}
            >
              {" "}
              Edit{" "}
            </button>
            <button
              onClick={() => {
                setModelTypes(true);
              }}
              className="btn btn-success"
            >
              Types
            </button>
          </div>
        </div>

        <Table headers={headers} body={allInfo} />
      </div>
      <Modal
        title="Update Data"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[]}
      >
        <FormContainer>
          <FormTitle>Update Site Information</FormTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div>
              <h5>*Home Page</h5>
            </div>
            <FormGroup>
              <Label>English Vision</Label>
              <Textarea
                name="vision_en"
                value={siteInfoData.vision_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Vision</Label>
              <Textarea
                name="vision_ar"
                value={siteInfoData.vision_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Hero Image</Label>
              <ImageInput
                value={heroImgUrl || siteInfoData.hero_img}
                id="heroImg"
                name="HeroImg"
                getImage={(imgFile) => {
                  setHeroImg(imgFile);
                  // setHeroImgUrl(URL.createObjectURL(imgFile));
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Why Orouba Image</Label>
              <Input
                type="file"
                name="why_orouba_img"
                // value={siteInfoData.why_orouba_img}
                onChange={(e) => setwhyOrImg(e.target.files[0])}
              />
            </FormGroup>
            <FormGroup>
              <Label>English Why Orouba ?</Label>
              <Textarea
                name="why_orouba_en"
                value={siteInfoData.why_orouba_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Why Orouba ?</Label>
              <Textarea
                name="why_orouba_ar"
                value={siteInfoData.why_orouba_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>English World Text</Label>
              <Textarea
                name="world_text"
                value={siteInfoData.world_text}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Arabic World Text</Label>
              <Textarea
                name="world_text_ar"
                value={siteInfoData.world_text_ar}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Map Image</Label>
              <ImageInput
                value={mapImgUrl || siteInfoData.map}
                id="mapImage"
                name="mapImage"
                getImage={(imgFile) => {
                  setMapImg(imgFile);
                  // setHeroImgUrl(URL.createObjectURL(imgFile));
                }}
              />
            </FormGroup>
            <Button
              onClick={() => {
                handleSubmit("home");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*About Page</h4>
            </div>

            <FormGroup>
              <Label>English Who We Are</Label>
              <Textarea
                name="how_we_are_en"
                value={siteInfoData.how_we_are_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Who We Are</Label>
              <Textarea
                name="how_we_are_ar"
                value={siteInfoData.how_we_are_ar}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Arabic Quotation</Label>
              <Textarea
                name="quotation_ar"
                value={siteInfoData.quotation_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>English Quotation</Label>
              <Textarea
                name="quotation_en"
                value={siteInfoData.quotation_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic purposal</Label>
              <Textarea
                name="purposal_ar"
                value={siteInfoData.purposal_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>English purposal</Label>
              <Textarea
                name="purposal_en"
                value={siteInfoData.purposal_en}
                onChange={handleChange}
              />
            </FormGroup>

            {/* <FormGroup style={{ marginBottom:'30px' }}>
                            <Label>Arabic Production Steps</Label>
                            <ReactQuill
                              theme="snow"
                              modules={{
                                toolbar: [
                                  ["bold", "italic", "underline", "strike"],
                                  ["blockquote", "code-block"],
                                  [{ header: 1 }, { header: 2 }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  [{ script: "sub" }, { script: "super" }],
                                  [{ indent: "-1" }, { indent: "+1" }],
                                  [{ direction: "rtl" }],
                                  [{ size: ["small", true, "large", "huge"] }],
                                  ["link", "image"],
                                  [{ color: [] }, { background: [] }],
                                  [{ font: [] }],
                                  [{ align: [] }],
                                ],
                              }}
                              value={siteInfoData?.production_steps_ar}
                              onChange={(e) =>
                                setSiteInfoData({ ...siteInfoData, production_steps_ar: e })
                              }
                              style={{
                                minHeight: "100px",
                                color: "black",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                            />
                        </FormGroup>
                        <FormGroup style={{ marginBottom:'30px' }}>
                            <Label>English Production Steps</Label>
                            <ReactQuill
                              theme="snow"
                              modules={{
                                toolbar: [
                                  ["bold", "italic", "underline", "strike"],
                                  ["blockquote", "code-block"],
                                  [{ header: 1 }, { header: 2 }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  [{ script: "sub" }, { script: "super" }],
                                  [{ indent: "-1" }, { indent: "+1" }],
                                  [{ direction: "rtl" }],
                                  [{ size: ["small", true, "large", "huge"] }],
                                  ["link", "image"],
                                  [{ color: [] }, { background: [] }],
                                  [{ font: [] }],
                                  [{ align: [] }],
                                ],
                              }}
                              value={siteInfoData?.production_steps_en}
                              onChange={(e) =>
                                setSiteInfoData({ ...siteInfoData, production_steps_en: e })
                              }
                              style={{
                                minHeight: "100px",
                                color: "black",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                            />
                        </FormGroup> */}
            <div className="d-flex align-items-center gap-3">
              <FormGroup>
                <Label>About Image</Label>
                <ImageInput
                  value={aboutImgUrl || siteInfoData.about_image}
                  id="about_image"
                  name="about_image"
                  getImage={(imgFile) => {
                    setAboutImg(imgFile);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Small About Image</Label>
                <ImageInput
                  value={aboutSmallImgUrl || siteInfoData.small_about_img}
                  id="small_about_img"
                  name="small_about_img"
                  getImage={(imgFile) => {
                    setAboutSmallImg(imgFile);
                  }}
                />
              </FormGroup>
              {/* <button onClick={()=>{
                            handleUpAb()
                          }} disabled={aboutImg==null} className="btn btn-success" style={{maxWidth:'100px'}}>
                            upload
                          </button> */}
            </div>
            <Button
              onClick={() => {
                handleSubmit("about");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*Certification Page</h4>
            </div>
            <FormGroup>
              <Label>English Certification Text</Label>
              <Textarea
                name="certification_text_en"
                value={siteInfoData.certification_text_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Certification Text</Label>
              <Textarea
                name="certification_text_ar"
                value={siteInfoData.certification_text_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <Button
              onClick={() => {
                handleSubmit("certi");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*Copy Right</h4>
            </div>
            <FormGroup>
              <Label>English Copy Right</Label>
              <Textarea
                name="copy_right_en"
                value={siteInfoData.copy_right_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Copy Right</Label>
              <Textarea
                name="copy_right_ar"
                value={siteInfoData.copy_right_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <Button
              onClick={() => {
                handleSubmit("certi");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*Catalog Page</h4>
            </div>
            <FormGroup>
              <Label>Catalog (English)</Label>
              <Textarea
                name="catalog_en"
                value={siteInfoData.catalog_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Catalog (Arabic)</Label>
              <Textarea
                name="catalog_ar"
                value={siteInfoData.catalog_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Cataloge Image</Label>
              <ImageInput
                value={catalogImgUrl || siteInfoData.catalog_image}
                id="catalogeImage"
                name="catalogeImage"
                getImage={(imgFile) => {
                  setCatalogImg(imgFile);
                  // setCatalogImgUrl(URL.createObjectURL(imgFile));
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label>Cataloge File</Label>
              <input
                type="file"
                onChange={(e) => {
                  setCatalogFile(e.target.files[0]);
                }}
              />
              <a target="_blank" href={siteInfoData.catalog_file}>
                <img
                  style={{ width: "40px" }}
                  src={require("../../assets/images/file.png")}
                  alt=""
                />
              </a>
            </FormGroup>

            <Button
              onClick={() => {
                handleSubmit("cata");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*Contact Us Page</h4>
            </div>
            <FormGroup>
              <Label>Location (English)</Label>
              <Input
                type="text"
                name="location_en"
                value={siteInfoData.location_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Location (Arabic)</Label>
              <Input
                type="text"
                name="location_ar"
                value={siteInfoData.location_ar}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Service Phone</Label>
              <Input
                type="text"
                name="service_phone"
                value={siteInfoData.service_phone}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                type="text"
                name="phone"
                value={siteInfoData.phone}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="text"
                name="email"
                value={siteInfoData.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Map URL</Label>
              <Input
                type="text"
                name="map_url"
                value={siteInfoData.map_url}
                onChange={handleChange}
              />
            </FormGroup>

            <Button
              onClick={() => {
                handleSubmit("certi");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*Export Page</h4>
            </div>

            <FormGroup>
              <Label>English Export World</Label>
              {/* <Textarea
                name="export_world_en"
                value={siteInfoData.export_world_en}
                onChange={handleChange}
              /> */}

              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={siteInfoData.export_world_en}
                onChange={(e) => {
                  return setSiteInfoData({
                    ...siteInfoData,
                    export_world_en: e,
                  });
                }}
                style={{
                  minHeight: "100px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Export World</Label>
              {/* <Textarea
                name="export_world_ar"
                value={siteInfoData.export_world_ar}
                onChange={handleChange}
              /> */}

              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={siteInfoData.export_world_ar}
                onChange={(e) => {
                  return setSiteInfoData({
                    ...siteInfoData,
                    export_world_ar: e,
                  });
                }}
                style={{
                  minHeight: "100px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </FormGroup>

            <Button
              onClick={() => {
                handleSubmit("certi");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>

            <div className="my-2">
              <h4>*Career Page</h4>
            </div>

            <FormGroup>
              <Label>English Why Choose Us</Label>
              <Textarea
                name="why_choose_en"
                value={siteInfoData.why_choose_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Arabic Why Choose Us</Label>
              <Textarea
                name="why_choose_ar"
                value={siteInfoData.why_choose_ar}
                onChange={handleChange}
              />
            </FormGroup>

            <div>
              <h4>*Vision Content</h4>
            </div>

            <FormGroup>
              <Label>Arabic Vision</Label>
              <Textarea
                name="vision_ar"
                value={siteInfoData.vision_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>English Vision</Label>
              <Textarea
                name="vision_en"
                value={siteInfoData.vision_en}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Vision Image</Label>
              <ImageInput
                value={vissionImgUrl || siteInfoData.vision_image}
                id="visionImg"
                name="visionImg"
                getImage={(imgFile) => {
                  setVissionImg(imgFile);
                  // setCatalogImgUrl(URL.createObjectURL(imgFile));
                }}
              />
            </FormGroup>
            <Button
              onClick={() => {
                handleSubmit("vission");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>
            <div className="my-2">
              <h4>*Site Data</h4>
            </div>
            <FormGroup>
              <Label>Site Name (Arabic)</Label>
              <Input
                type="text"
                name="site_name_ar"
                value={siteInfoData.site_name_ar}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Site Name (English)</Label>
              <Input
                type="text"
                name="site_name_en"
                value={siteInfoData.site_name_en}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Logo URL</Label>
              <ImageInput
                value={logoImg || siteInfoData.logo}
                id="logoImg"
                name="logoImg"
                getImage={(imgFile) => {
                  setImgLogo(imgFile);
                  // setCatalogImgUrl(URL.createObjectURL(imgFile));
                }}
              />
            </FormGroup>

            <Button
              onClick={() => {
                handleSubmit("siteData");
              }}
              type="submit"
              disabled={isLoading}
            >
              Update
            </Button>
            <div className="my-3">
              <h5>**Product Type page</h5>
              <FormGroup>
                <Label>English product Type Text</Label>

                <Textarea
                  name="product_type_text_en"
                  value={siteInfoData.product_type_text_en}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Arabic product Type Text</Label>

                <Textarea
                  name="product_type_text_ar"
                  value={siteInfoData.product_type_text_ar}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Image</Label>
                <ImageInput
                  value={logoImg || siteInfoData.product_type_img}
                  id="product_type_img"
                  name="product_type_img"
                  getImage={(imgFile) => {
                    setProdTypeImg(imgFile);
                    // setCatalogImgUrl(URL.createObjectURL(imgFile));
                  }}
                />
              </FormGroup>
              <Button
                onClick={() => {
                  handleSubmit("prod_type_img");
                }}
                type="submit"
                disabled={isLoading}
              >
                Update
              </Button>
            </div>
            <div>
              <h5>**Stander Page</h5>
              <FormGroup>
                <Label>Arabic Stander Text</Label>

                <Textarea
                  name="stander_ar"
                  value={siteInfoData.stander_ar}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>English Stander Text</Label>

                <Textarea
                  name="stander_en"
                  value={siteInfoData.stander_en}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button
                onClick={() => {
                  handleSubmit("certi");
                }}
                type="submit"
                disabled={isLoading}
              >
                Update
              </Button>
            </div>
          </form>
        </FormContainer>
      </Modal>

      <Modal
        title="Types Category Add Edit"
        open={modelTypes}
        onCancel={() => setModelTypes(false)}
        footer={[]}
      >
        <FormContainer>
          <FormTitle>Types Categories</FormTitle>
          {true && (
            <div className="d-flex justify-content-between my-2">
              <h5>Add New Type Category</h5>
              <button
                disabled={disAdd}
                onClick={() => {
                  setDisAdd(true);
                }}
                className="btn btn-success"
              >
                add
              </button>
            </div>
          )}
          {disAdd && (
            <div className="my-2">
              <FormGroup>
                <Label>New Arabic Category Type Title</Label>
                <Input
                  type="text"
                  name="title_ar"
                  value={newCatType.title_ar}
                  onChange={(e) => {
                    handleChange3(e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>New English Category Type Title</Label>
                <Input
                  type="text"
                  name="title_en"
                  value={newCatType.title_en}
                  onChange={(e) => {
                    handleChange3(e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>New Arabic Category Type Description</Label>
                <Textarea
                  name="description_ar"
                  value={newCatType.description_ar}
                  onChange={(e) => {
                    handleChange3(e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>New English Category Type Description</Label>
                <Textarea
                  name="description_en"
                  value={newCatType.description_en}
                  onChange={(e) => {
                    handleChange3(e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Number of Category Type</Label>
                <Input
                  type="text"
                  name="number"
                  value={newCatType.number}
                  onChange={(e) => {
                    handleChange3(e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Category Type Image</Label>
                <ImageInput
                  value={newCatTypeImgUrl}
                  id="new_ca_img"
                  name="new_ca_img"
                  getImage={(imgFile) => {
                    setNewCatImgType(imgFile);
                  }}
                />
              </FormGroup>
              <button
                disabled={newCatLoading}
                onClick={() => {
                  handleAddNewCatType();
                }}
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          )}
          <div>
            <h1 className="text-center text-success">All Types Categories</h1>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {categoriesTypes &&
              categoriesTypes.map((item, index) => {
                return (
                  <div>
                    <h5 className="d-flex  align-items-center justify-content-between">
                      <span>Type Number {index + 1}</span>
                      <span>
                        <button
                          disabled={delTypeLoading}
                          onClick={() => {
                            // console.log(item.id)
                            handleDelParent(item.id);
                          }}
                          className="btn btn-danger d-flex  align-items-center justify-content-center"
                        >
                          <IoMdClose />
                        </button>
                      </span>
                    </h5>
                    <FormGroup>
                      <Label>Arabic Category Type Title</Label>
                      <Input
                        type="text"
                        name="title_ar"
                        value={item.title_ar}
                        onChange={(e) => {
                          handleChange2(e, index);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>English Category Type Title</Label>
                      <Input
                        type="text"
                        name="title_en"
                        value={item.title_en}
                        onChange={(e) => {
                          handleChange2(e, index);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Arabic Category Type Description</Label>
                      <Textarea
                        name="description_ar"
                        value={item.description_ar}
                        onChange={(e) => {
                          handleChange2(e, index);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>English Category Type Description</Label>
                      <Textarea
                        name="description_en"
                        value={item.description_en}
                        onChange={(e) => {
                          handleChange2(e, index);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Category Type Number</Label>
                      <Input
                        type="text"
                        name="number"
                        value={item.number}
                        onChange={(e) => {
                          handleChange2(e, index);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Category Type Image</Label>
                      <ImageInput
                        value={item.image}
                        id={`catimg${item.id}`}
                        name={`catimg${item.id}`}
                        getImage={(imgFile) => {
                          setCatTypeImg(imgFile);
                          if (imgFile != null) {
                            setCatTypeImgId(item.id);
                          }
                        }}
                      />
                    </FormGroup>
                    <div className="my-3 d-flex align-items-center justify-content-between">
                      <h5 className="m-0" style={{ fontSize: "23px" }}>
                        Assign New Category To This Type
                      </h5>
                      <CiCirclePlus
                        onClick={() => {
                          if (disAddNew) {
                            toast.warn("لقد ضغطت للإضافه من قبل على عنصر ما");
                            return;
                          }
                          setNewChild({
                            ...newChild,
                            category_type_id: item.id,
                          });
                          setDisAddNew(true);
                        }}
                        style={{
                          fontSize: "32px",
                          color: "green",
                          cursor: "pointer",
                          fontWeight: "bolder",
                        }}
                      />
                    </div>
                    {disAddNew && item.id == newChild.category_type_id && (
                      <div className="my-2">
                        <FormGroup>
                          <Label>Category Image</Label>
                          <ImageInput
                            id={`child_imd`}
                            name={`child_imd`}
                            getImage={(imgFile) => {
                              setImgChild(imgFile);
                            }}
                          />
                        </FormGroup>
                        <label htmlFor="">Assigned Category</label>
                        <select
                          onChange={(e) => {
                            setNewChild({
                              ...newChild,
                              category_id: e.target.value,
                            });
                          }}
                          value={newChild.category_id}
                          className="form-control"
                        >
                          {allCategories &&
                            allCategories.map((itCategory, indCategory) => {
                              return (
                                <option value={itCategory.id}>
                                  {itCategory.name_en} (
                                  {itCategory?.brand?.name_en})
                                </option>
                              );
                            })}
                        </select>
                        <FormGroup>
                          <Label>number</Label>
                          <Input
                            type="text"
                            name="number"
                            value={newChild.number}
                            onChange={(e) => {
                              setNewChild({
                                ...newChild,
                                number: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                        <button
                          disabled={addChildLoading}
                          onClick={() => {
                            handleAddNewChild();
                          }}
                          className="btn btn-primary"
                        >
                          Assign New Category
                        </button>
                      </div>
                    )}
                    {item.cattype &&
                      item.cattype.map((itCat, indCat) => {
                        return (
                          <>
                            <h4 className="my-2">Child {indCat + 1}</h4>
                            <div className="my-2 d-flex gap-1 justify-content-between">
                              <FormGroup>
                                <Label>Category Image</Label>
                                <ImageInput
                                  value={itCat.image}
                                  id={`new_ca_img${itCat.id}`}
                                  name={`new_ca_img${itCat.id}`}
                                  getImage={(imgFile) => {
                                    if (imgFile != null) {
                                      let checkExist = imgChilds.find(
                                        (itFind) => itFind?.id == itCat.id
                                      );
                                      // console.log(checkExist)
                                      let list = [...imgChilds];
                                      if (
                                        checkExist != null &&
                                        checkExist != undefined &&
                                        checkExist != "undefined"
                                      ) {
                                        list[checkExist["index"]]["img"] =
                                          imgFile;
                                      } else {
                                        let obj = {
                                          index: indCat,
                                          img: imgFile,
                                          id: itCat.id,
                                        };
                                        console.log(obj);
                                        list.push(obj);
                                      }
                                      setImgChilds(list);
                                    }
                                    // setNewCatImgType(imgFile);
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                {/* {console.log(itCat)} */}
                                <Label>Category</Label>
                                <select
                                  onChange={(e) => {
                                    // console.log(e.target.value)
                                    // console.log("eer");
                                    let list = [...categoriesTypes];
                                    list[index]["cattype"][indCat][
                                      "category_id"
                                    ] = e.target.value;
                                    // console.log(list[index]['cattype'][indCat]['category_id'])
                                    setCategoriesTypes(list);
                                  }}
                                  value={itCat.category_id}
                                  className="form-control"
                                >
                                  {allCategories &&
                                    allCategories.map(
                                      (itCategory, indCategory) => {
                                        return (
                                          <option value={itCategory.id}>
                                            {itCategory.name_en} (
                                            {itCategory?.brand?.name_en})
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </FormGroup>
                            </div>
                            <div className="d-flex gap-3  align-items-center">
                              <FormGroup>
                                <Label>Category Number</Label>
                                <Input
                                  type="text"
                                  name="number"
                                  value={itCat.number}
                                  onChange={(e) => {
                                    let list = [...categoriesTypes];
                                    list[index]["cattype"][indCat]["number"] =
                                      e.target.value;
                                    setCategoriesTypes(list);
                                    // handleChange2(e,index);
                                  }}
                                />
                              </FormGroup>
                              <button
                                disabled={editChildLoading}
                                onClick={() => {
                                  // console.log(itCat.id)
                                  // return
                                  handleDelChild(itCat.id);
                                }}
                                className="btn btn-danger"
                              >
                                <IoMdClose />
                              </button>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    <button
                      disabled={editChildLoading}
                      onClick={() => {
                        handleEditChildes(item);
                      }}
                      className="btn btn-warning"
                    >
                      Edit Childs{" "}
                    </button>
                    <button
                      disabled={
                        editCatTypeLoading ||
                        (catTypeImgId != item.id && catTypeImgId != "")
                      }
                      onClick={() => {
                        handleEditCategoryType(item);
                      }}
                      className="btn btn-primary my-2"
                    >
                      Edit Type Category
                    </button>
                    <hr />
                  </div>
                );
              })}
          </form>
        </FormContainer>
      </Modal>
    </DefaultLayout>
  );
};

export default SiteInfo;
