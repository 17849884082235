import React, { useState } from "react";
import "./style.css";
import { Icon } from "@iconify/react";
import { logOut } from "./functions";
import { useNavigate } from "react-router-dom";
function ProfileMenu({ userData, active, setActive }) {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate()
  function handleLogout() {
    localStorage.removeItem("oroubaUsers");
    navigate("/login")
  }
  return (
    <div className="profileMenu">
      <div className="menu-down">
        <button className="btn btn-success" onClick={handleLogout}>
          Logout
        </button>

        <div className="logo-ic menu-responsive" style={{ width: "25px", height: "25px" }} onClick={() => setActive(!active)}>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default ProfileMenu;
