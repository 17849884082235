import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddModal from "../../components/AddModal/AddModal";
import EditModal from "../../components/EditModal/EditModal";
import ShowStatusModal from "../../components/ShowStatusModal/ShowStatusModal";
import { BASE_URL } from "../../data";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";

import { toast } from "react-toastify";
import ImageInput from "../../components/imageInput/ImageInput";
import Table from "../../components/table";
import { FormGroup, Label, uploadImage } from "../SiteInfo/utils";
import Select from 'react-select';
import ReactQuill from "react-quill";
import Modal from "../../components/modal";

export default function Cooking() {
  const { cooking } = useParams();
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("oroubaUsers"));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [img, setImg] = useState(null);
  const [video , setVideo] = useState(null);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [allCookings, setAllCookings] = useState([]);
  const [cookingData, setCookingData] = useState({
    name_ar: "",
    name_en: "",
    description_en: "",
    description_ar: "",
    number:1,
  });

  const [rowData, setRowData] = useState({});

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: "English Name",
      dataIndex: "name_en",
    },
    {
      label: "Arabic Name",
      dataIndex: "name_ar",
    },

    {
      label: "Image",
      dataIndex: "images",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <img
            src={row?.images && row?.images?.length && row?.images[0]?.url}
            style={{ width: "40px" }}
          />
        );
      },
    },
    {
      label: "Internal Image",
      dataIndex: "internal_image",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <img
            src={row?.internal_image && row?.internal_image}
            style={{ width: "40px" }}
          />
        );
      },
    },
    {
      label: "Video",
      dataIndex: "video_link",
      type: "children",
      children: ({ row, headers }) => {
        return <video src={row?.video_link} style={{ width: "40px" }}></video>;
      },
    },
    {
      label: "",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="icons-container">
            <button
              className="edit_btn"
              onClick={() => {
                setIsModalEditOpen(true);
                setRowData({
                 ...row,
                  foodsData: row?.foodcook?.map((item) => ({
                    value: item?.food?.id,
                    label: item?.food?.name_en,
                  })),
                });
              }}
            >
              Edit
            </button>

            {row.hidden == 0 ? (
              <button
                className="show_btn"
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(false);
                  setStatus(row.id);
                }}
              >
                Hide
              </button>
            ) : (
              <button
                className="show_btn"
                onClick={() => {
                  setIsShowStatus(true);
                  setShowStatus(true);
                  setStatus(row.id);
                }}
              >
                Show
              </button>
            )}

            {/* <button
              style={{
                backgroundColor: "blue",
                padding: "5px",
                borderRadius: "8px",
                color: "white",
              }}
              onClick={() => {
                navigate(`/cookingCategories/${row.id}`);
              }}
            >
              Categories
            </button> */}

            <button
              style={{
                backgroundColor: "blue",
                padding: "5px",
                borderRadius: "8px",
                color: "white",
              }}
              onClick={() => {
                navigate(`/cookingProps/${row.id}`);
              }}
            >
              Props
            </button>

            <button
              style={{
                backgroundColor: "blue",
                padding: "5px",
                borderRadius: "8px",
                color: "white",
              }}
              onClick={() => {
                navigate(`/cookingSteps/${row.id}`);
              }}
            >
              Steps
            </button>
          </div>
        );
      },
    },
  ];

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    const data_send={
      number:video_number
    }
    await axios.post(BASE_URL+`/cooks/change_number/${video_id}`,data_send)
    .then((res)=>{
      console.log(res)
      if(res.data.status=='success'){
        toast.success(res.data.message)
        handleGetAllCookings();
      }
      else if(res.data.status=='faild'){
        toast.error(res.data.message)
      }
      else {
        toast.error("something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setLoader(false);
    })
    // await Axios({
    //   method: "POST",
    //   url: "banners/change_number/" + video_id,
    //   data: { number: video_number },
    // })
    //   .then(async (response) => {
    //     console.log(response);
    //     toast.success(response?.message);
    //     handleGetAllBanners();
    //   })
    //   .catch((error) => console.log(error))
    //   .finally(() => {
    //     setLoader(false);
    //   });
  };

  function handleGetAllCookings() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/cooks/get_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.result);
          setAllCookings(res.data.result);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllCookings();
  }, []);

  function handleUpdateStatus() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/cooks/update_status/${status}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllCookings();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowStatus(false);
      })
      .catch((e) => console.log(e));
  }
  const [images, setImages] = useState({
    aboutImage: { id: null, imageURL: null, imageFile: null },
    catalogeImage: { id: null, imageURL: null, imageFile: null },
    mapImage: { id: null, imageURL: null, imageFile: null },
  });

  async function handleSubmit(e) {
    const uploadedImages = { ...images };
    e.preventDefault();
    setIsLoading(true);

    // Upload images if new images are selected
    for (const key in images) {
      if (images[key].imageFile) {
        const imageUrl = await uploadImage(images[key].imageFile);
        uploadedImages[key] = { ...uploadedImages[key], imageURL: imageUrl };
      }
    }

    // Update siteInfoData with uploaded image URLs
    const updatedSiteInfoData = {
      ...cookingData,
      images: uploadedImages.vision_image.imageURL,
      video_link: uploadedImages.video_link.imageURL,
      internal_image: uploadedImages.internal_image.imageURL,
    };
    const data_send = {
      ...updatedSiteInfoData,
      foods: cookingData?.foodsData?.map((item) => item?.value)?.join("*food*"),
    };
    delete data_send?.foodsData;
    axios
      .post(BASE_URL + `/cooks/add_new?token=${token}`, data_send)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllCookings();
          setCookingData({
            description_ar: "",
            description_en: "",
            number:1,
            name_ar: "",
            name_en: "",
          });
          setImages({
            aboutImage: { id: null, imageURL: null, imageFile: null },
            catalogeImage: { id: null, imageURL: null, imageFile: null },
            mapImage: { id: null, imageURL: null, imageFile: null },
          });
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalAddOpen(false);
      })
      .catch((e) => console.log(e));
  }
  useEffect(() => {
    if (!isModalEditOpen) {
      setRowData({});
    }
  }, [isModalEditOpen]);
  async function handleEdit(e) {
    e.preventDefault();

    const uploadedImages = { ...images };

    // Upload images if new images are selected
    for (const key in images) {
      if (images[key].imageFile) {
        const imageUrl = await uploadImage(images[key].imageFile);
        uploadedImages[key] = { ...uploadedImages[key], imageURL: imageUrl };
      }
    }

    // Update siteInfoData with uploaded image URLs
    const updatedSiteInfoData = {
      ...rowData,
      images:
        uploadedImages?.vision_image?.imageURL ||
        (rowData.images && rowData.images[0]?.url),
      video_link: uploadedImages?.video_link?.imageURL || rowData?.video_link,
      internal_image:
        uploadedImages?.internal_image?.imageURL || rowData?.internal_image,
      foods:
        rowData?.foodsData && rowData?.foodsData?.length
          ? rowData?.foodsData?.map((item) => item?.value)?.join("*food*")
          : rowData?.foodcook?.map((item) => item?.id)?.join("*food*"),
    };
    const data_send = {
      ...updatedSiteInfoData,
    };
    delete data_send?.foodsData;
    delete data_send?.foodcook;
    setIsLoading(true);
    axios
      .post(
        BASE_URL + `/cooks/update_one/${rowData.id}?token=${token}`,
        data_send
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          handleGetAllCookings();
          setImages({
            aboutImage: { id: null, imageURL: null, imageFile: null },
            catalogeImage: { id: null, imageURL: null, imageFile: null },
            mapImage: { id: null, imageURL: null, imageFile: null },
          });
          setRowData({});
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalEditOpen(false);
      })
      .catch((e) => console.log(e));
  }

  const [allFoods, setAllFoods] = useState([]);
  function handleGetAllFoods() {
    setIsLoading(true);
    axios
      .get(BASE_URL + `/foods/get_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllFoods(res.data.result?.filter((item) => item?.hidden == 0));
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    handleGetAllFoods();
  }, []);

  return (
    <DefaultLayout>
      <div style={{ margin: "20px" }}>
        <div className="page_title_container">
          <ContentNav head="Cooking" />
          <AddModal
            onOk={handleSubmit}
            isLoading={isLoading}
            isModalOpen={isModalAddOpen}
            setIsModalOpen={setIsModalAddOpen}
            title="Add Cooking"
          >
            <form onSubmit={handleSubmit} className="modal_form">
              <div className="inputs_container">
                <FormGroup>
                  <Label>image</Label>
                  <ImageInput
                    id="vision_image"
                    name="vision_image"
                    getImage={(imgFile) =>
                      setImages({
                        ...images,
                        vision_image: {
                          ...images.vision_image,
                          imageFile: imgFile,
                          imageUrl: null,
                        },
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>internal_image</Label>
                  <ImageInput
                    id="internal_image"
                    name="internal_image"
                    getImage={(imgFile) =>
                      setImages({
                        ...images,
                        internal_image: {
                          ...images.internal_image,
                          imageFile: imgFile,
                          imageUrl: null,
                        },
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>video_link</Label>
                  <ImageInput
                    id="video_link"
                    name="video_link"
                    getImage={(imgFile) =>
                      setImages({
                        ...images,
                        video_link: {
                          ...images.video_link,
                          imageFile: imgFile,
                          imageUrl: null,
                        },
                      })
                    }
                  />
                </FormGroup>
              </div>
              <div className="input_group">
                <label>English Name</label>
                <input
                  type="text"
                  placeholder="English Name"
                  onChange={(e) =>
                    setCookingData({
                      ...cookingData,
                      name_en: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input_group">
                <label>Number</label>
                <input
                  type="text"
                  placeholder="Number"
                  onChange={(e) =>
                    setCookingData({
                      ...cookingData,
                      number: e.target.value,
                    })
                  }
                />
              </div>

              <div className="input_group">
                <label>Arabic Name</label>
                <input
                  type="text"
                  placeholder="English Name"
                  onChange={(e) =>
                    setCookingData({
                      ...cookingData,
                      name_ar: e.target.value,
                    })
                  }
                />
              </div>
              <div className="inputs_container">
            <div className="input_group">
                <label>English Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={cookingData?.description_en}
                  onChange={(e) => {
                   return setCookingData({ ...cookingData, description_en: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
            </div>

            </div>

            <div className="input_group">
                <label>Arabic Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={cookingData?.description_ar}
                  onChange={(e) => {
                   return setCookingData({ ...cookingData, description_ar: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="inputs_container">
                <div className="input_group">
                  <label>Foods</label>

                  <Select
                    isMulti
                    name="colors"
                    options={allFoods?.map((item) => ({
                      label: item?.name_en,
                      value: item?.id,
                    }))}
                    onChange={(e) => {
                      setCookingData({ ...cookingData, foodsData: e });
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
            </form>
          </AddModal>
        </div>

        <EditModal
          onOk={handleEdit}
          isLoading={isLoading}
          isModalOpen={isModalEditOpen}
          setIsModalOpen={setIsModalEditOpen}
          title="Edit Cooking"
        >
        {/* <Modal open={isModalEditOpen} toggle={setIsModalEditOpen}> */}
          <form onSubmit={handleEdit} className="modal_form">
            <div className="inputs_container">
              <FormGroup>
                <Label>image</Label>
                <ImageInput
                  id="vision_image"
                  name="vision_image"
                  getImage={(imgFile) =>
                    setImages({
                      ...images,
                      vision_image: {
                        ...images.vision_image,
                        imageFile: imgFile,
                        imageUrl: null,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>internal_image</Label>
                <ImageInput
                  id="internal_image"
                  name="internal_image"
                  getImage={(imgFile) =>
                    setImages({
                      ...images,
                      internal_image: {
                        ...images.internal_image,
                        imageFile: imgFile,
                        imageUrl: null,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>video_link</Label>
                <ImageInput
                  id="video_link"
                  name="video_link"
                  getImage={(imgFile) =>
                    setImages({
                      ...images,
                      video_link: {
                        ...images.video_link,
                        imageFile: imgFile,
                        imageUrl: null,
                      },
                    })
                  }
                />
              </FormGroup>
            </div>
            <div className="input_group">
              <label>English Name</label>
              <input
                type="text"
                placeholder="English Name"
                value={rowData.name_en}
                onChange={(e) =>
                  setRowData({ ...rowData, name_en: e.target.value })
                }
              />
            </div>

            <div className="input_group">
              <label>Arabic Name</label>
              <input
                type="text"
                placeholder="English Name"
                value={rowData.name_ar}
                onChange={(e) =>
                  setRowData({ ...rowData, name_ar: e.target.value })
                }
              />
            </div>
            <div className="inputs_container">
            <div className="input_group">
                <label>English Description</label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.description_en}
                  onChange={(e) => {
                   return setRowData({ ...rowData, description_en: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>

            </div>

            <div className="input_group">
                <label>Arabic Description </label>

                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={rowData?.description_ar}
                  onChange={(e) => {
                   return setRowData({ ...rowData, description_ar: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: "black",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                />
              </div>
            <div className="inputs_container">
              <div className="input_group">
                <label>Foods</label>

                <Select
                  value={rowData?.foodsData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                  isMulti
                  name="colors"
                  onChange={(e) => {
                    setRowData({ ...rowData, foodsData: e });
                  }}
                  options={allFoods?.map((item) => ({
                    label: item?.name_en,
                    value: item?.id,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
          </form>
          </EditModal>

        <ShowStatusModal
          onOk={handleUpdateStatus}
          isHidden={showStatus}
          isLoading={isLoading}
          isModalOpen={isShowStatus}
          setIsModalOpen={setIsShowStatus}
        />
        <Table headers={headers} body={allCookings} />
      </div>
    </DefaultLayout>
  );
}
